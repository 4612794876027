import React from 'react'
import { Link } from 'react-router-dom';
// Import Components


//  Import Img
import BannerImage from '../../assets/img/about/1.png'


const ProductBanner=()=> {
return (
  <>
  {/* Product Banner Start */}
    <section id="service" className="bg-color">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="product-banner">
                        <h2 className="mb-5">Why track time with eClock.Work</h2> 
                        <div className="btn-holder mb-4">
                            <Link to="/#" className="tag-btn">Productivity Boost</Link>
                            <Link to="/#" className="tag-btn">Client Billing</Link>
                            <Link to="/#" className="tag-btn">Team Management</Link>
                            <Link to="/#" className="tag-btn">Business Intelligence</Link>
                        </div>
                        <img alt="" src={BannerImage}  />
                    </div>
                </div>
            </div>
        </div>
    </section>
      {/* Product Banner End */} 
  </> 
 )
}

export default ProductBanner
