import React from "react";
//  Import Img
import img3 from "../../assets/img/coming-soon.jpg";
import img2 from "../../assets/img/coming-soon.jpg";
import img4 from "../../assets/img/testing_image/image11.png";

import { Link } from "react-router-dom";

const ManagmentInvoicing = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="">
        <div className="container border-bottom pb-5">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
            <div className="two-columns-holder text-center  pb-3">
              <h2 className="mt-2 paragraph-color fw600"> Tracking</h2>
              <p>
                Monitor the status of all your invoices, including those that
                are paid, partially paid, overdue, sent, unsent, and void.
              </p>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600"> Invoice Status</h5>
                <p>
                  Mark invoices as dispatched, pending, settled, or canceled.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4 ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color fw600"> Outstanding</h5>
                <p>
                  View all invoices that remain unpaid beyond their due date.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600"> Payment Log</h5>
                <p>Track invoices with outstanding balances.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4 ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <img alt="" src={img4} />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color fw600"> Export Invoices</h5>
                <p>
                  Customize the data for export and save invoices as CSV or
                  Excel files.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600">
                  {" "}
                  Invoice Customization
                </h5>
                <p>
                  Customize your invoices with your company name, address, and
                  logo. Choose which fields to display and modify the text for
                  invoice labels.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4 ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color fw600">
                  {" "}
                  Default Configuration
                </h5>
                <p>
                  Define default settings for invoice subject and notes.
                  Customize "Sender" contacts. Establish unique currency for
                  each invoice.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600"> Multiple Senders</h5>
                <p>Build a roster of several companies to invoice from.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManagmentInvoicing;
