import React from 'react'
import {AiOutlineCheckCircle} from "react-icons/ai";
import img1 from "../../assets/img/project-details/1.jpg"
import img2 from "../../assets/img/project-details/2.png"
import { Link } from "react-router-dom";

const Aboutone = () => {
    return (
        <>
            <section id="project-details-area" className="py100">
                <div className="container">
                        <div className="mt-5">
                            <span className="step-font">STEP 1</span>
                            <h3 className="step-heading">Create an account</h3>
                            <p>
                            <Link to="#">
                                <span className="span-color">Sign up for free to access eClock.Work's comprehensive time management features.</span>
                            </Link>
                            </p>
                        </div>
                        <div className="mt-5">
                            <span className="step-font">STEP 2</span>
                            <h3 className="step-heading">Set up projects</h3>
                            <p>
                            Create projects and categorize them by clients. Define tasks within each project. Employees can easily select a project/task and start tracking time with a brief description.
                            </p>
                            <div className="service-dec-img animation-img-two">
                                <img alt="" src={img1}  className="mt-3"/>
                            </div>
                        </div>
                        <div className="mt-5">
                            <span className="step-font">STEP 3</span>
                            <h3 className="step-heading">Invite your team</h3>
                            <p>
                            Add an unlimited number of users for free, including developers, designers, managers, and clients.
                            </p>
                            <div className="service-dec-img animation-img-two">
                                <img alt="" src={img1}  className="mt-3"/>
                            </div>
                        </div>
                        <div className="mt-5">
                                <span className="step-font">STEP 4</span>
                                <h3 className="step-heading">Set hourly Rates</h3>
                                <p>
                                Define hourly rates for each employee and project. Employees can track time using a timer or enter hours manually via the browser, desktop, or mobile apps. Desktop apps offer additional features like reminders and idle detection.
                                </p>
                            {/* <div className="service-dec-img animation-img-two">
                                <img
                                    alt=""
                                    src={require("../../assets/img/testing_image/image01.png")} className="mt-3"
                                />
                            </div> */}
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 5</span>
                                <h3 className="step-heading">Employee log work hours</h3>
                                <p>
                                Employees can track time using a timer or manually enter hours via desktop and mobile apps. Time tracking options include using a timer or manual entry.
                                </p>
                                
                                {/* <div className="service-dec-img animation-img-two">
                                    <img
                                        alt=""
                                        src={require("../../assets/img/testing_image/image02.jpg")} className="mt-3"
                                    />
                                </div> */}
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 6</span>
                                <h3 className="step-heading">Track Employee overtime</h3>
                                <p>
                                Monitor employee overtime by tracking time via the timer or manual entry. Desktop apps provide convenient features like reminders and idle detection.
                                </p>
                            {/* <div className="service-dec-img animation-img-two">
                                <img
                                    alt=""
                                    src={require("../../assets/img/testing_image/image02.jpg")} className="mt-3"
                                />
                            </div> */}
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 7</span>
                                <h3 className="step-heading">See and analyze hours in reports</h3>
                                <p>
                                Analyze employee hours and productivity using comprehensive reports. Gain insights into time allocation and identify areas for improvement.
                                </p>
                            {/* <div className="service-dec-img animation-img-two">
                                <img
                                    alt=""
                                    src={require("../../assets/img/testing_image/image03.png")} className="mt-3"
                                />
                            </div> */}
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 8</span>
                                <h3 className="step-heading">Export Payroll hours report</h3>
                                <p>
                                Generate payroll reports to streamline payroll processing. Export reports in various formats such as PDF, Excel, or CSV for easy integration with payroll systems.
                                </p>
                            {/* <div className="service-dec-img animation-img-two">
                                <img
                                    alt=""
                                    src={require("../../assets/img/testing_image/image05.jpg")} className="mt-3"
                                />
                            </div> */}
                        </div>
                    </div>
            </section>
        </>
    )
}

export default Aboutone
