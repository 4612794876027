import React, { useState } from "react";
import { Link } from "react-router-dom";
import check from "../../assets/img/about/tick.png";
import AboutImg from "../../assets/img/about/1.png";

import img from "../../assets/img/home-two-testimonial/veido-test.png";
import { AiOutlinePlayCircle } from "react-icons/ai";
// Modal
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.min.css";
import img1 from "../../../src/assets/img/about/3.png";

const ProConsVideo = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py50">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <div className="left-side-title ">
                <h2 className="heading-section-color text-center">
                  How does construction timesheet software work?
                </h2>
              </div>
              <div className="about-details pt10">
                <p className="text-center">
                  eClock.Work is extremely intuitive and there's no way you'll
                  stumble along the way when you're using it. Here's a rundown
                  of how to get started with eClock.Work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}

      <section
        id="testimonial-area"
        className="py100 bg-color testimonial_padding"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="testmonial-veido text-center">
                <img src={img1} alt="img" />
                <div className="veido-play">
                  <ModalVideo
                    channel="youtube"
                    api={false}
                    autoplay
                    isOpen={isOpen}
                    videoId="MLpWrANjFbI"
                    onClose={() => setOpen(false)}
                  />
                  <a href="#!" onClick={() => setOpen(true)}>
                    <i>
                      <AiOutlinePlayCircle />
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProConsVideo;
