import React from 'react'
import PlanningBanner from '../component/Planning/PlanningBanner'
import Partner from '../component/Home/Partner/Partner'
import PlanningVideo from '../component/Planning/PlanningVideo'
import PlanningContent from '../component/Planning/PlanningContent'
import ProductContent from '../component/ProductPage/ProductContent'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'

const Planning = () => {
    return (
        <>
            <Header/>
    
            <PlanningBanner/>
            {/* <Partner/> */}
            <PlanningVideo/>
            <PlanningContent/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default Planning