import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import TrackerSeeFeatures from '../component/FeaturesSeeAll/TrackerSeeFeatures'             
import TimeSheetFeatures from '../component/FeaturesSeeAll/TimeSheetFeatures'             
import FirstSeeFeatures from '../component/FeaturesSeeAll/FirstSeeFeatures'             
import KisokSeeFeatures from '../component/FeaturesSeeAll/KisokSeeFeatures'             
import CalendarSeeFeatures from '../component/FeaturesSeeAll/CalendarSeeFeatures'             
import AppsSeeFeatures from '../component/FeaturesSeeAll/AppsSeeFeatures'             
import IntegrationsSeeFeat from '../component/FeaturesSeeAll/IntegrationsSeeFeat'             
import DashboardSeeFeat from '../component/FeaturesSeeAll/DashboardSeeFeat'             
import AllBelowSeePage from '../component/FeaturesSeeAll/AllBelowSeePage'             
import VideoSeeTutorial from '../component/FeaturesSeeAll/VideoSeeTutorial'             

const FeaturesSeeAllPage = () => {
    return (
        <>
            <Header/>
            <FirstSeeFeatures/>
            <TrackerSeeFeatures/>
            <TimeSheetFeatures/>
            <KisokSeeFeatures/>
            <CalendarSeeFeatures/>
            <AppsSeeFeatures/>
            <IntegrationsSeeFeat/>
            <DashboardSeeFeat/>
            <AllBelowSeePage/>
            <VideoSeeTutorial/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default FeaturesSeeAllPage;
