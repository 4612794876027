import React from 'react'
// import CommonBanner from '../component/Common/Banner'
import ContactDetails from '../component/ContactUs'
import Footer from '../layout/Footer'
import HeaderHelp from '../layout/HeaderHelp'
import RatingArea from '../component/ContactUs/RatingArea'


const ContactUs = () => {
    return (
        <>
            <HeaderHelp/>
            <RatingArea />  
            {/* <CommonBanner heading="Contact" menu1="Home" menu2="Contact"/> */}
            <ContactDetails/>
            <Footer />
        </>
    )
}

export default ContactUs
