import React from "react";

import { Link } from "react-router-dom";

const TwoColumnExpenses = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Expense Categorization</h5>
                  <p className="mt-2">
                    Classify expenses by category and project.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Annotation</h5>
                  <p className="mt-2">
                    Include supplementary information for the expense
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image10.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Billability</h5>
                  <p className="mt-2">
                    Designate expenses as billable (for invoicing purposes).
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Expense Logging on Mobile</h5>
                  <p className="mt-2">
                    Record and submit expenses conveniently via mobile devices.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image10.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Receipt Management</h5>
                  <p className="mt-2">
                    Attach receipts as images or PDFs, or capture them using the
                    mobile app.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Expense Classification</h5>
                  <p className="mt-2">
                    Arrange expenses by category and project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
      {/* Two Columns Area End */}
    </>
  );
};

export default TwoColumnExpenses;
