import React from "react";
import SectionHeading from "../Home/SectionHeading/SectionHeading";

const VissionMission = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <SectionHeading title="VISION, MISSION & VALUES" />
          <div className="row mb-4">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 d-flex">
              <h4 className="heading-section-color">VISION: </h4>
              <p>&nbsp;&nbsp;Efficiently Leading Clockwork Operations for Companies.</p>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 d-flex ">
              <h4 className="heading-section-color">
                MISSION:
              </h4>
              <p>&nbsp;&nbsp;Empowering Time Management and Compliance.</p>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12  d-flex">
              <h4 className="heading-section-color">VALUES:</h4>
              <p>&nbsp;&nbsp;
                At eClock, we empower businesses with precise and efficient
                digital time tracking solutions. Our platform ensures accurate
                recording of work hours, enhancing productivity and compliance.
                By providing real-time insights and seamless integration, eClock
                simplifies workforce management, reduces administrative burdens,
                and fosters transparency. We are dedicated to helping companies
                optimize their operations, improve accountability, and achieve
                better resource allocation for sustained growth and success.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}
    </>
  );
};

export default VissionMission;
