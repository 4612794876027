import React from 'react'
import TimekeeperBanner from '../component/Timekeeper/TimekeeperBanner'
import TimekeeperVideoPartner from '../component/Timekeeper/TimekeeperVideoPartner'
import Partner from '../component/Home/Partner/Partner'
import TimekeeperContent from '../component/Timekeeper/TimekeeperContent'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'

const Timekeeper = () => {
    return (
        <>
            <Header/>
            <TimekeeperBanner/>
            {/* <Partner/> */}
            <TimekeeperVideoPartner/>
            <TimekeeperContent/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default Timekeeper