import React from 'react'
import Options from '../component/Help/Helpus/Options'
import Searchbar from '../component/Help/Helpus/searchbar'
import HeaderHelp from '../layout/HeaderHelp';
import Footer from '../layout/Footer';
import About from '../component/Help/ContactusHelp/AboutArea'

const Helpus = () => {
    return (
        <>
            <HeaderHelp/>
            <Searchbar />
            <Options/>
            <About />
            <Footer />
        </>
    )
}

export default Helpus
