import React from "react";
import { Link } from "react-router-dom";
import { GiGraduateCap } from "react-icons/gi";
import img1 from "../../assets/img/project-details/2.png";

const AccountingSecVideo = () => {
  return (
    <>
      <section id="" className="">
        <div className="container">
          <div className="row mb-4">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12  ">
              <div className="mt-5">
                <span className="step-font">STEP 1</span>
                <h3 className="step-heading">Create an Account</h3>
                <p>
                  <Link to="#">
                    <span className="span-color">Sign up for free on eClock.Work to get started.</span>
                  </Link>
                </p>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 2</span>
                <h3 className="step-heading">Set Up Projects</h3>
                <p>
                Create projects, categorize them by clients, and define tasks. Employees can easily select a project/task, provide brief descriptions, and start tracking time.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img alt="" src={img1} className="mt-3" />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 3</span>
                <h3 className="step-heading">Set Hourly Rates</h3>
                <p>
                Customize hourly rates for employees, projects, or tasks. Specify billable and non-billable rates to ensure accurate billing.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image01.png")}
                    className="mt-3"
                  />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 4</span>
                <h3 className="step-heading">
                Track Billable Hours and Fill Timesheets
                </h3>
                <p>
                Utilize the timer or manually log hours via web, mobile, or desktop app. eClock.Work automatically tracks billable time, simplifying the process for you and your team.
                </p>
                <div className="d-flex justify-content-center ">
                  <img
                    alt=""
                    src="/static/media/1.984011c2ff425a179994.png"
                    className="pb-5 mt-3"
                  />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 5</span>
                <h3 className="step-heading">
                View and Analyze Time in Reports
                </h3>
                <p>
                Download comprehensive reports in PDF, CSV, or Excel formats. Share reports with clients using eClock.Work's shareable link feature for real-time cost tracking.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image02.jpg")}
                    className="mt-3"
                  />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 6</span>
                <h3 className="step-heading"> Create Invoices</h3>
                <p>
                Generate invoices effortlessly based on tracked billable time. Download invoices in PDF format for easy sharing with clients.
                </p>
                <div className="d-flex justify-content-center ">
                  <img
                    alt=""
                    src="/static/media/1.984011c2ff425a179994.png"
                    className="pb-5 mt-3"
                  />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 7</span>
                <h3 className="step-heading">Send Time to QuickBooks</h3>
                <p>
                Review and approve tracked time, then seamlessly send it to your QuickBooks account for payroll and accounting purposes via integration. 
                </p>
              </div>
              <div className="service-dec-img animation-img-two">
                <img
                  alt=""
                  src={require("../../assets/img/testing_image/image02.jpg")}
                  className="mt-3 text-left"
                />
                <p className="py-3 text-left">
                  <Link to="#" className="span-color">
                    Send time to QuickBooks for payroll and accounting
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountingSecVideo;
