import React from 'react'
//  Import Img
import check from '../../../assets/img/about/tick.png'
import AboutImg from '../../../assets/img/about/1.png'

import { Link } from 'react-router-dom';

const   AboutThree=()=> {
 return (
  <>
  {/* About One Area Start */}
    <section id="about" className="py100">
       <div className="container">
           <div className="row">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                   <div className="left-side-title">
                       <h3>Management</h3>
                   </div>
                   <div className="about-details pt10">
                       <p>
                       In the modern business landscape, time is incredibly valuable. Managers often face the ongoing challenge of monitoring employee hours, project deadlines, and team productivity. This is where time tracking software proves indispensable.</p>
                       <ul className="pt20">
                           <li className="wow zoomIn" data-wow-duration="2.0s">
                               <img alt="" src={check}  /><strong>Scheduling</strong> - Schedule work, assignments, and shifts.
                           </li>
                           <li className="wow zoomIn" data-wow-duration="2.0s">
                               <img alt="" src={check}  /><strong>Time off </strong> - Manage leaves and holidays.
                           </li>
                           <li className="wow zoomIn" data-wow-duration="2.0s">
                               <img alt="" src={check}  /><strong>Approval</strong> - Submit and approve timesheets.
                           </li>
                           <li className="wow zoomIn" data-wow-duration="2.0s">
                               <img alt="" src={check}  /><strong>Invoicing</strong> - Create invoices from billable time.
                           </li>
                           <li className="wow zoomIn" data-wow-duration="2.0s">
                               <img alt="" src={check}  /><strong>Expenses</strong> - Record project expenses and fees.
                           </li>
                           <li className="wow zoomIn" data-wow-duration="2.0s">
                               <img alt="" src={check}  /><strong>Team</strong> - Manage team members and roles.
                           </li>
                       </ul>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                   <div className="animation-img animation-img-one">
                       <img alt="" src={AboutImg}  />
                   </div>
               </div>
           </div>
           <div className="row">  
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                    <Link to="/features_seeall_features" className="btn btn-theme mt30">See all features</Link>
                </div>
           </div>
       </div>
   </section>
      {/* About One Area End */}
  </>

 )
}

export default AboutThree
