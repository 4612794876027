import React from 'react'
import SectionHeading from '../../Home/SectionHeading/SectionHeading' 
//  Import Img
import img2 from '../../../assets/img/main/2.png'
import img3 from '../../../assets/img/testing_image/image03.png'
import img4 from '../../../assets/img/testing_image/1.png'
import img5 from '../../../assets/img/main/15.png'
import img6 from '../../../assets/img/main/6.png'
import img7 from '../../../assets/img/testing_image/image01.png'
import img16 from '../../../assets/img/main/16.png'
import img18 from '../../../assets/img/coming-soon.jpg'
import img19 from '../../../assets/img/coming-soon.jpg'


import { Link } from 'react-router-dom';

const AboutTwo=()=> {
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="about" className="py100">
       <div className="container">
            <SectionHeading title="Approve timesheets" heading={"Formally approve timesheets before they are forwarded to payroll processing."}/>
            <div className="row mb-4">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Submit for approval</h5>
                        <p>Submit your timesheet for manager review and approval to prevent any further modifications.</p>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img3}  />
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img4}  />
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Unsubmitted timesheets</h5>
                        <p>Identify individuals who have logged time but have not yet submitted it for approval.</p>
                   </div>
               </div>
            </div>
            <div className="row mb-2">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Automatic reminders</h5>
                        <ul>
							<li className="mt-2">Log time</li>
							<li className="mt-2">Submit time</li>
							<li className="mt-2">Approve time</li>
						</ul>
                        
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img7}  />
                   </div>
               </div>
            </div>
       </div>
   </section>
      {/* Two Columns Area End */}

      {/* Offline mode */}
    <section id="about" className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <div className="logo-area-heading pb-3">
              <h3>Audit trail</h3>
              <h4>Maintain a written record of all changes made to timesheets, including details such as who made the change, when it was made, and what was modified.</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Two Columns Area End */}
    
    {/* Testimonial Area */}
    <section id="testimonial-area" className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6 text-center">
            <div className="">
              {/* <img src={img18} alt="" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>

      {/* Offline mode */}
    <section id="about" className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <div className="logo-area-heading pb-3">
              <h3>Offline mode</h3>
              <h4>Record time using the mobile app, even without an internet connection. Data will synchronize automatically once you are back online.</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Two Columns Area End */}
    
    {/* Testimonial Area */}
    <section id="testimonial-area" className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6 text-center">
            <div className="">
              {/* <img src={img19} alt="" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="" className="product-section">
        <div className="container">
            <div className="feature-area-bg">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="section-title-center text-center pb30">
							<h3 className="pb10">More features</h3>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Hourly Rates</h3>
									<p>Set hourly rates for tasks and projects, and track earnings based on these rates.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Projects</h3>
									<p>Divide projects into individual tasks and organize them by client for clarity and organization.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Reports</h3>
									<p>View a detailed breakdown of all tracked time based on specific custom dates and dimensions.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Export</h3>
									<p>Tailor your reports by customizing and exporting data into formats such as Excel, PDF, or CSV files.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Invoicing</h3>
									<p>Generate invoices automatically based on tracked billable hours.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Rounding</h3>
									<p>Round time to the nearest specified increment (up, down, or to the nearest X minutes).</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Costs & Profit</h3>
									<p>Conduct a comparison between what you bill clients and the compensation paid to your team.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Estimates</h3>
									<p>Establish time estimates for projects and tasks, and monitor their progress accordingly.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Expenses</h3>
									<p>Record fixed-fee expenses for projects and team members, and generate invoices accordingly.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
        </div>
    </section>

    <section id="" className="product-section">
        <div className="container">
            <div className="feature-area-bg">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="section-title-center text-center pb30">
							<h3 className="pb10">Related</h3>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-6">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Kiosk</h3>
									<p>Establish hourly rates for tasks and projects, and track earnings based on these rates.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Reporting</h3>
									<p>Divide projects into tasks and categorize them according to the respective clients.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
        </div>
    </section>
  </>

 )
}

export default AboutTwo
