import React from "react";
//  Import Img
import img1 from "../../../assets/img/coming-soon.jpg";
import img2 from "../../../assets/img/coming-soon.jpg";
import img3 from "../../../assets/img/coming-soon.jpg";
import img4 from "../../../assets/img/coming-soon.jpg";
import img5 from "../../../assets/img/coming-soon.jpg";
import img33 from "../../../assets/img/coming-soon.jpg";
import img34 from "../../../assets/img/coming-soon.jpg";
import img35 from "../../../assets/img/coming-soon.jpg";

import { Link } from "react-router-dom";

const AboutTwo = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <h5 className="mt-2">Default Billability Settings</h5>
                <p>
                  Manage which projects and tasks are automatically set as
                  billable.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img33} /> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img34} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
              <div className="two-columns-holder">
                <h5 className="mt-2">Access Control</h5>
                <p>
                  Regulate visibility and modification rights for rates and
                  status changes among users.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
              <div className="two-columns-holder">
                <h5 className="mt-2">Overview Dashboard</h5>
                <p>
                  Monitor the distinction between billable and non-billable time
                  across different date ranges.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img35} /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}

      {/* Offline mode */}
      <section id="about" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h3>Variable Hourly Rates</h3>
                <h4>
                  Establish different rates for projects, tasks, and employees.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}

      {/* Testimonial Area */}
      <section id="testimonial-area" className="py-5">
        <div className="container">
          <div className="row  mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
              <div className="two-columns-holder">
                <h5 className="mt-2">1. Standard Rate</h5>
                <p>
                  Default charge applied to all time entries across the
                  workspace.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img1} /> */}
              </div>
            </div>
          </div>

          <div className="row  mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
              <div className="two-columns-holder">
                <h5 className="mt-2">2. Individual Member Rate</h5>
                <p>Customized rate assigned to each user individually.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
              </div>
            </div>
          </div>

          <div className="row  mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
              <div className="two-columns-holder">
                <h5 className="mt-2">3. Project-Specific Rate</h5>
                <p>Tailored rate designated for each project.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <div className="row  mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
              <div className="two-columns-holder">
                <h5 className="mt-2">4. Task-Specific Rate</h5>
                <p>Customized rate assigned to each task within a project.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img4} /> */}
              </div>
            </div>
          </div>
          <div className="row  mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
              <div className="two-columns-holder">
                <h5 className="mt-2">5. Project Member Rate</h5>
                <p>
                  Customized rate for an individual assigned to a specific
                  project.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img5} /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="product-section">
        <div className="container">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">More features</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Expense Monitoring</h3>
                      <p>
                        Analyze the hours allocated to each task and compute
                        labor expenses accordingly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Billing Automation</h3>
                      <p>
                        Rapidly generate invoices based on tracked time entries.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Seamless QuickBooks Integration</h3>
                      <p>
                        Automatically export timesheet reports to QuickBooks for
                        streamlined processing and reporting.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Activity Approval</h3>
                      <p>
                        Submit, review, and approve tracked activities on
                        timesheets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Task and Assignment</h3>
                      <p>
                        Coordinate and organize work assignments and tasks among
                        teams efficiently.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Billing Options </h3>
                      <p>
                        Select which activities are billable and which are not.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="product-section">
        <div className="container">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">Related</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Time and Earnings Summary</h3>
                      <p>Comprehensive summary of tracked time and earnings.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Geolocation Tracking</h3>
                      <p>
                        Monitor and view clock-in locations for accurate
                        attendance tracking.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutTwo;
