import React from "react";
//  Import Img
import img3 from "../../assets/img/coming-soon.jpg";
import img2 from "../../assets/img/coming-soon.jpg";

import { Link } from "react-router-dom";

const MobileTracking = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="pt-5">
  <div className="container pb-5">
    <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
      <div className="text-center">
        <h2>Mobile Location Monitoring</h2>
        <p>Monitor GPS coordinates upon clocking in and out.






</p>
        <div className="service-dec-img animation-img-two">
          <a href="#" class="image-zoom">
            <img
              alt=""
              src={require("../../assets/img/testing_image/android.png")}
              className="mt-3 pb-5 px-2"
            />
          </a>
          <a href="#" class="image-zoom">
            <img
              alt=""
              src={require("../../assets/img/testing_image/ios.png")}
              className="mt-3 pb-5 px-2"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

      <section id="about" className="">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Time Tracker</h5>
                  <p className="mt-2">
                  Initiate and halt timer for individual tasks.







                  </p>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Calendar View</h5>
                  <p className="mt-2">Visualize all your tasks and appointments in a calendar format.






</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image10.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">GPS Location Logging

s</h5>
                  <p className="mt-2">
                  Capture location data during active work hours.







                  </p>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Offline Capability</h5>
                  <p className="mt-2">Monitor activities without internet and sync when connected.






</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image07.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileTracking;
