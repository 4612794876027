import React from "react";
import { Link } from "react-router-dom";
import { FaRegArrowAltCircleRight } from "react-icons/fa";

const BelowAllPage = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py-5 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Analysis and Summaries</h2>
                <p className="mb-4">
                  Access breakdowns summarized by individual or detailed
                  activity logs for comprehensive reporting.
                </p>
                <p>
                  <span>
                    <a href="#">More Deatails</a> <FaRegArrowAltCircleRight />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    {/* <img
                      alt=""
                      src={require("../../assets/img/coming-soon.jpg")}
                      className="mt-3 pb-5"
                    /> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Resume Tracking</h5>
                      <p className="mt-2 ">
                        Easily continue tracking with a single click.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Timer</h5>
                      <p className="mt-2 ">
                        Track time effortlessly with precision
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Manual Entry</h5>
                      <p className="mt-2 ">Input and adjust hours manually.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Billable Hours</h5>
                      <p className="mt-2 ">
                        Assign billable status to tracked hours.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Screen Capture</h2>
                <p className="mb-4">
                  Review and recall tasks based on captured screenshots.
                </p>
                <p>
                  <span>
                    <a href="#">MORE DETAILS</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image02.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Add Users</h5>
                      <p className="mt-2 ">
                        Include kiosk participants by name (no email needed).
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Manual Entry</h5>
                      <p className="mt-2 ">Input and adjust hours directly.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Resume</h5>
                      <p className="mt-2 ">
                        resume tracking with a single click.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Billable Status</h5>
                      <p className="mt-2 ">
                        Assign users as billable by name (no email required).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="py-5 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Geolocation Tracking</h2>
                <p className="mb-4">
                  Automatically record your location upon clocking in.
                </p>
                <p>
                  <span>
                    <a href="#">More Deatails</a> <FaRegArrowAltCircleRight />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    {/* <img
                      alt=""
                      src={require("../../assets/img/coming-soon.jpg")}
                      className="mt-3 pb-5"
                    /> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Location Tracking</h5>
                      <p className="mt-2 ">
                        Automatically capture your whereabouts when clocking in.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">GPS Logging</h5>
                      <p className="mt-2 ">
                        Log your precise location upon clock-in for accurate
                        records.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Location Recording</h5>
                      <p className="mt-2 ">
                        Record your GPS coordinates when you start your session.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Geo-Tagging</h5>
                      <p className="mt-2 ">
                        Tag your location when you clock in for detailed
                        tracking.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Change Log</h2>
                <p className="mb-4">
                  Monitor and review all modifications made within your account.
                </p>
                <p>
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4 pb-2">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image02.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BelowAllPage;
