import React from 'react'
import RegisterForm from '../component/Register'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'

const Register = () => {
    return (
        <>
            <Header/>
            <RegisterForm/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default Register
