import React from 'react'
import {AiOutlineCheckCircle} from "react-icons/ai";
import img1 from "../../assets/img/project-details/1.jpg"
import img7 from "../../assets/img/coming-soon.jpg"
import img8 from "../../assets/img/coming-soon.jpg"
import img9 from "../../assets/img/coming-soon.jpg"
import img3 from "../../assets/img/testing_image/1.png"
import img4 from "../../assets/img/coming-soon.jpg"


import { Link } from "react-router-dom";

const AboutSteps = () => {
    return (
        <>
            <section id="project-details-area" className="py100">
                <div className="container">
                        <div className="mt-5">
                            <span className="step-font">STEP 1</span>
                            <h3 className="step-heading">Create an account</h3>
                            <ol>
                                <li>Visit eClock.Work's website and sign up for free using your Google account. It only takes seconds to get started.</li>
                            </ol>
                        </div>
                        <div className="mt-5">
                            <span className="step-font">STEP 2</span>
                            <h3 className="step-heading"> Invite Your Team</h3>
                            <p>
                            With eClock.Work's financial plans, such as Pro and Enterprise packages, you can invite an unlimited number of team members to your workspace via email.
                            </p>
                            <div className="service-dec-img animation-img-two">
                                {/* <img alt="" src={img7}  className="mt-3"/> */}
                            </div>
                        </div>
                        <div className="mt-5">
                            <span className="step-font">STEP 3</span>
                            <h3 className="step-heading">Track Time</h3>
                            <p>
                            Your team can easily track time using a timer or by manual entry. Whether in the browser, desktop, or mobile app, eClock.Work offers convenient features like reminders and idle detection. Integration with other apps like Trello, Asana, and Todoist further enhances tracking efficiency.
                            </p>
                        </div>
                        <div className="mt-5">
                                <span className="step-font">STEP 4</span>
                                <h3 className="step-heading">Set Up Projects</h3>
                                <p>
                                Utilize eClock.Work as a project management tool by creating projects, categorizing them by clients, and defining tasks. Employees can then select a project/task, provide brief descriptions, and start the timer.
                                </p>
                            <div className="service-dec-img animation-img-two">
                                {/* <img
                                    alt=""
                                    src={img8} className="mt-3"
                                /> */}
                            </div>
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 5</span>
                                <h3 className="step-heading"> Track Time Inside Other Apps</h3>
                                <p>
                                Install web extensions to seamlessly track time within various web apps. eClock.Work integrates with over 80 project and task management tools, facilitating tracking across different platforms.
                                </p>
                                <div className="service-dec-img animation-img-two">
                                {/* <img
                                    alt=""
                                    src={img9} className="mt-3"
                                /> */}
                            </div>  
                        </div>

                        <div className="mt-5">
                            <span className="step-font">STEP 6</span>
                            <h3 className="step-heading">Set hourly rates</h3>
                            <p>
                            Define hourly rates for each project. By marking time entries as billable, eClock.Work automatically calculates the billable amount, streamlining invoicing processes.
                            </p>
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 7</span>
                                <h3 className="step-heading">Export reports</h3>
                                <p>
                                Generate online reports tailored to your needs and export them in PDF, Excel, or CSV formats. Share report links with others for easy collaboration and communication.  
                                </p>
                            <div className="service-dec-img animation-img-two">
                                {/* <img
                                    alt=""
                                    src={img4} className="mt-3"
                                /> */}
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

export default AboutSteps
