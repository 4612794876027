import React from 'react'
//  Import Img
import img1 from '../../../assets/img/main/36.png'
import img2 from '../../../assets/img/main/37.png'
import img3 from '../../../assets/img/main/38.png'
import img4 from '../../../assets/img/main/39.png'
import img5 from '../../../assets/img/main/40.png'
import img33 from '../../../assets/img/main/33.png'
import img34 from '../../../assets/img/main/34.png'
import img35 from '../../../assets/img/main/35.png'
import img49 from '../../../assets/img/coming-soon.jpg'
import img50 from '../../../assets/img/coming-soon.jpg'
import img51 from '../../../assets/img/coming-soon.jpg'
import img52 from '../../../assets/img/main/52.png'
import img53 from '../../../assets/img/coming-soon.jpg'
import img54 from '../../../assets/img/coming-soon.jpg'
import img55 from '../../../assets/img/coming-soon.jpg'
import img56 from '../../../assets/img/coming-soon.jpg'



import { Link } from 'react-router-dom';

const AboutTwo=()=> {
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="about" className="py100">
       <div className="container">
            <div className="row">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Project Access</h5>
                        <ul>
							<li><strong>Public</strong><br />Allow everyone to track time on the project.</li>
							<li><strong>Private</strong><br />Allow everyone to track time on the project.</li>
						</ul>
                   </div>
               </div>
			   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img49}  />
                   </div>
               </div>
            </div>
            
			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="two-columns-holder">
						<img alt="" src={img50} />
					</div>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
					<div className="two-columns-holder">
						<h5 className="mt-2">Task Assignee</h5>
						<p>Only assignees can track time on their tasks.</p>
					</div>
				</div>
			</div>


			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
					<div className="two-columns-holder">
						<h5 className="mt-2">Permissions</h5>
						<p>Control who can create new projects and tasks.</p>
					</div>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="two-columns-holder">
						<img alt="" src={img51} />
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="two-columns-holder">
						<img alt="" src={img53} />
					</div>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
					<div className="two-columns-holder">
						<h5 className="mt-2">Project Manager</h5>
						<p>Allow certain people to manage their projects and see all time tracked on them.</p>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
					<div className="two-columns-holder">
						<h5 className="mt-2">Archive & Complete</h5>
						<p>Archive finished projects and mark tasks as completed so people don’t track time on them.</p>
					</div>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="two-columns-holder">
						<img alt="" src={img54} />
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="two-columns-holder">
						<img alt="" src={img55} />
					</div>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
					<div className="two-columns-holder">
						<h5 className="mt-2">Templates</h5>
						<p>Quickly create new projects with the same structure as existing ones.</p>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
					<div className="two-columns-holder">
						<h5 className="mt-2">Project export</h5>
						<p>Export projects with all their data or customize what you want to see.</p>
					</div>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="two-columns-holder">
						<img alt="" src={img56} />
					</div>
				</div>
			</div>
       </div>
   </section>
      {/* Two Columns Area End */}

  </>

 )
}

export default AboutTwo
