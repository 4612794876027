import React from "react";
import { Link } from "react-router-dom";
import { FcGraduationCap } from "react-icons/fc";
import img1 from "../../assets/img/project-details/2.png";
import img2 from "../../../src/assets/img/about/3.png";

const ProductBudgeting = () => {
  return (
    <>
      <section id="about" className="py100">
        <div className="container">
          <div className="row mb-4">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <h2 className="heading-section-color">
              A Modern Time Tracker for Projects
              </h2>
              <p className="py-3">
              <strong> eClock.Work</strong> is a completely free project time tracker that enables your employees to monitor the time they spend working on various project tasks. This tool helps you streamline time management and improve project efficiency by providing detailed insights into time allocation and productivity.
              </p>
              <h2 className=" py-3  ">Project time tracking benefits</h2>
              <ul className="custom-list margin-custom">
                <li className="pb-2">
                Maximize Time Spent on High-Value Clients and Projects: Focus resources on the most profitable and valuable clients and projects.

                Minimize Time Spent on Non-Billable Work: Reduce time spent on tasks that don’t generate revenue, increasing overall efficiency.
                Schedule Who Works on What and When: Plan and assign tasks effectively to optimize team productivity and project timelines.
                </li>
                
              </ul>

              <h2 className="py-3 ">Who uses eClock.Work and why?</h2>
              <ul className="custom-list margin-custom">
                <li className="pb-2">
                  <strong>Freelancers :</strong>to keep track of their billable hours and
                  productivity
                </li>
                <li className="pb-2">
                  <strong>Teams in agencies :</strong>so they can{" "}
                  <Link to="#" className="span-color">
                    plan projects
                  </Link>{" "}
                  and know how much they need to bill their clients
                </li>
                <li className="pb-2">
                  <strong>Large companies:</strong> so they can track project profitability,
                  productivity, and payroll
                </li>
                <li className="pb-2">
                  <strong>Governmental and non-governmental:</strong> organizations for legal
                  reasons
                </li>
                <li>
                  <strong>Remote companies and entrepreneurs:</strong> so they can know what each
                  contractor worked on
                </li>
              </ul>
              <p className="py-4 mb-5">
              <FcGraduationCap className="icon-large" /> See how to use eClock.Work for{" "}
                <Link to="#" className="span-color ">
                  better project management here
                </Link>
              </p>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12">
              <h2 className="heading-section-color mt-5">
              Project Time Tracking Benefits
              </h2>
              
              <p> <strong> Track Billable and Non-Billable Time:</strong> eClock.Work allows employees to monitor time spent on   various tasks within projects, distinguishing between billable and non-billable hours.
                eClock.Work is a completely free project time tracker that helps you and your team efficiently manage time and improve project management. See how to use eClock.Work for better project management here.
              </p>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 border-bottom ">
              <h3 className="my-3 pt-5">
                Manage projects and monitor progress
              </h3>
              <p className="py-3">
              <strong> eClock.Work</strong> allows you to effectively manage your projects and monitor progress through the following features:
              </p>
              <ol>
                <li>Create Tasks and Assign People</li>
                <li>Estimate Task Duration</li>
                <li>Visualize Progress in the Dashboard</li>
              </ol>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12">
              <h3 className="my-3 pt-5">
              Forecast Project Progress
              </h3>
              <p className="py-3">
              <strong> eClock.Work</strong> helps you visualize and predict project performance through:
              </p>
              <ol>
                <li>Progress Visualization</li>
                <li>Predict Project Performance</li>
                <li>Informed Decision-Making</li>
              </ol>
            </div>
            
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 border-bottom ">
              <h3 className="my-3 pt-5">Track project profitability</h3>
              <p className="py-3">
              <strong>eClock.Work</strong> enables you to track project profitability with detailed reporting features:
              </p>
              <p>
                <FcGraduationCap /> Learn more about:{" "}
                <Link to="#" className="span-color">
                  Calculating payroll
                </Link>{" "}
                |{" "}
                <Link to="#" className="span-color">
                  Calculating billable hours
                </Link>
              </p>
              <ol>
                <li>Run Detailed Reports: Generate reports that break down all tracked time, grouped by project and client. This allows you to see a clear overview of how time is being spent.</li>
                <li>Analyze Billable vs. Non-Billable Hours: Identify how much of the tracked time is billable and calculate earnings based on your hourly rates.</li>
                <li>Monitor Earnings: Review your weekly or monthly earnings by comparing billable hours against recorded expenses. This helps you understand the financial health of your projects.</li>
              </ol>
              <p>These features provide valuable insights into project profitability, helping you make informed decisions to optimize revenue and manage expenses.</p>
              <div className="d-flex justify-content-center">
                <div className="mx-auto">
                  <img
                    src="/static/media/2.cbf079bf62b977b1a92b.png"
                    alt="img"
                    className="pb-5"
                  />
                </div>
              </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12  ">
              <h2 className="heading-section-color mt-5">
                How to track project hours
              </h2>
              <div className="d-flex justify-content-center text-center">
                <div className="d-flex justify-content-center my-3 testmonial-veido ">
                  <div class=" text-center">
                    <img
                      src={img2}
                      alt="img"
                    />
                    <div class="veido-play">
                      <a href="#!">
                        <i>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 1024 1024"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                            <path d="M719.4 499.1l-296.1-215A15.9 15.9 0 0 0 398 297v430c0 13.1 14.8 20.5 25.3 12.9l296.1-215a15.9 15.9 0 0 0 0-25.8zm-257.6 134V390.9L628.5 512 461.8 633.1z"></path>
                          </svg>
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 1</span>
                <h3 className="step-heading">Create an account</h3>
                <p>
                  <Link to="#">
                    <span className="span-color">Sign up for free to start using eClock.Work.</span>
                  </Link>
                </p>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 2</span>
                <h3 className="step-heading">Set up projects</h3>
                <p>
                Create projects, categorize them by clients, and create tasks.
                </p>
                <p>
                Employees can select a project/task, provide a brief description, and start the timer.                </p>
                <div className="service-dec-img animation-img-two">
                  <img alt="" src={img1}  className="mt-3"/>
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 3</span>
                <h3 className="step-heading">Invite your team</h3>
                <p>
                Add an unlimited number of users for free, including developers, designers, managers, and clients
                </p>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 4</span>
                <h3 className="step-heading">Track time</h3>
                <p>
                Employees can track time using a timer or manually enter their hours.
                </p>
                <p>Time can be tracked via browser, desktop, or mobile apps.</p>
                <p>Desktop apps include features like reminders and idle detection.</p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image01.png")} className="mt-3"
                  />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 5</span>
                <h3 className="step-heading">Track time inside other apps</h3>
                <p>
                Install Chrome, Firefox, and Edge extensions to add a timer button in web apps like Trello, Asana, and Todoist for easier time tracking.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image02.jpg")} className="mt-3"
                  />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 6</span>
                <h3 className="step-heading">Set hourly rates</h3>
                <p>
                Assign hourly rates for each employee and project.
                </p>
                <p>Ensure time entries are marked as billable; eClock.Work will automatically calculate billable amounts in reports.</p>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 7</span>
                <h3 className="step-heading">Schedule work</h3>
                <p>Add projects and define milestones on a timeline.</p>
              </div>
              <div className="d-flex justify-content-center ">
                <img
                  alt=""
                  src="/static/media/1.984011c2ff425a179994.png" 
                  className="pb-5 mt-3"
                />
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 8</span>
                <h3 className="step-heading">Track progress on projects</h3>
                <p>
                Set task and project estimates, compare estimated vs. tracked time, and receive notifications if a project exceeds its budget.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img alt="" src={img1} className="mt-3" />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 9</span>
                <h3 className="step-heading">Record expenses</h3>
                <p>
                Create expense entries for fixed fees and other costs, attaching receipts as needed.
                </p>
                <p>Use eClock.Work to track time and expenses, simplifying business expense management.</p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image01.png")} className="mt-3"
                  />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 10</span>
                <h3 className="step-heading">Download Report Samples</h3>
                <p>
                Download report samples in different formats (PDF, CSV, Excel) to understand the reporting capabilities.
                </p>

                
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image02.jpg")} className="mt-3"
                  />
                </div>
              </div>
              <div className="mt-5 border-bottom">
                <span className="step-font">STEP 11</span>
                <h3 className="step-heading">Set hourly rates</h3>
                <p>
                  Set hourly rates for each employee and project. All your team
                  has to do is track time and make sure time entries are marked
                  as billable — eClock.Work will automatically calculate the
                  billable amounts in reports and do the heavy-lifting itself.
                </p>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image05.jpg")} className="mt-3 pb-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductBudgeting;
