import React from 'react'
import SectionHeading from '../SectionHeading/SectionHeading' 
//  Import Img
import check from '../../../assets/img/about/tick.png'
import AboutImg from '../../../assets/img/about/1.png'
import AboutImgTow from '../../../assets/img/about/2.png'

import { Link } from 'react-router-dom';

const TwoColumns=()=> {
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="about" className="py100">
       <div className="container">
            <SectionHeading title="Time tracking apps" heading={"eClock.Work works across devices. Track time from anywhere — all data is synced online."}/>
            <div className="row mb-5">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={AboutImg}  />
                        <h5 className="mt-2">Desktop app</h5>
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Windows</Link>
                            <Link to="/#" className="tag-btn">Mac</Link>
                            <Link to="/#" className="tag-btn">Linux</Link>
                        </div>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={AboutImgTow}  />
                        <h5 className="mt-2">Web app</h5>
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Edge</Link>
                            <Link to="/#" className="tag-btn">Chrome</Link>
                            <Link to="/#" className="tag-btn">Firefox</Link>
                        </div>
                   </div>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={AboutImg}  />
                        <h5 className="mt-2">Mobile app</h5>
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Android</Link>
                            <Link to="/#" className="tag-btn">iOS</Link>
                        </div>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={AboutImgTow}  />
                        <h5 className="mt-2">Kiosk app</h5>
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Any device</Link>
                        </div>
                   </div>
               </div>
            </div>
       </div>
   </section>
      {/* Two Columns Area End */}
  </>

 )
}

export default TwoColumns
