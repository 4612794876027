import React from "react";
import { Link } from "react-router-dom";
import { FcGraduationCap } from "react-icons/fc";
import img1 from "../../assets/img/project-details/2.png";

const ProductConstruction = () => {
  return (
    <>
      <section id="" className="section-padding">
        <div className="container">
          <div className="row ">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h2 className=" py-3  ">
                Why eClock.Work is the Best Choice for Construction Timesheets
              </h2>
              <p>
                eClock.Work stands out from other construction timesheet tools
                for several compelling reasons:
              </p>
              <h4>Flexible Time Tracking</h4>
              <p>
                <strong>Automatic Timer and Manual Entry: </strong> Workers can
                track time using an automatic timer or manually input missing
                hours in an online timesheet, ensuring accurate timekeeping.
              </p>
              <h4>User-Friendly</h4>
              <p>
                <strong>Simplicity and Accessibility: </strong> Designed to be
                extremely simple and easy to use, eClock.Work is available on
                multiple platforms including Windows, Mac, Linux, Android, and
                iOS.
              </p>
              <h4>Automated Billing Calculations</h4>
              <p>
                <strong>Automatic Billable Hours Calculation: </strong> The
                software calculates billable hours based on your hourly rates
                automatically, eliminating the need for manual calculations in
                Excel spreadsheets.
              </p>
              <h4>Perfect for Construction Companies</h4>
              <p>
                <strong>Adapted to Field Dynamics: </strong>
                In the fast-paced and dynamic construction environment,
                eClock.Work simplifies time tracking with a single click at the
                start of the day to capture all necessary data.
              </p>
              <h4>Perfect for Construction Companies</h4>
              <p>
                <strong>Easy Work Hour Tracking: </strong>
                Multiple Tracking Methods: Employees can either use a timer app
                or enter their worked hours in an online timesheet, making it
                adaptable to different working styles.
              </p>
              <h4>Perfect for Construction Companies</h4>
              <p>
                <strong>Detailed Reporting: </strong>
                Insightful Reports: Reports break down tracked time by project
                and employee, which can be exported in PDF, CSV, and Excel
                formats for easy sharing and analysis.
              </p>
              <h4>Progress Monitoring: </h4>
              <p>
                <strong>Task Assignment and Progress Tracking:</strong> Create
                tasks, assign them to team members, and track time against them.
                eClock.Work allows you to estimate task durations and visually
                monitor progress.
              </p>
              <p>
                <strong>Budget Alerts:</strong> Set budget limits in
                eClock.Work’s settings to receive alerts if a project goes over
                budget, helping you manage costs effectively.
              </p>

              <p>
                <p>
                  With these features, eClock.Work provides a comprehensive,
                  user-friendly, and cost-effective solution for managing
                  construction timesheets, ensuring efficient time tracking and
                  project management.
                </p>
              </p>
            </div>

            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h2 className="py-3 pt-5">
                Time tracking software for construction companies
              </h2>
              <p>
                With all the dynamics, confusion, and constant action that
                happens on the field, it's practically a constant that a worker
                will forget to{" "}
                <Link to="#" className="span-color">
                  input his daily hours
                </Link>{" "}
                in the timesheet. Well, eClock.Work takes care of that problem —
                a single click at the start of the day is sufficient to get all
                the data you need.
              </p>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h3 className="py-3  pt-5 ">
                Super simple way to{" "}
                <Link to="#" className="span-color">
                  track work hours
                </Link>{" "}
              </h3>
              <p>
                <Link to="#" className="span-color">
                  Your employees can track time
                </Link>{" "}
                using a{" "}
                <Link to="#" className="span-color">
                  timer app,
                </Link>{" "}
                or enter how much they've worked in an{" "}
                <Link to="#" className="span-color">
                  online timesheet.
                </Link>{" "}
                in the timesheet. Well, eClock.Work takes care of that problem —
                a single click at the start of the day is sufficient to get all
                the data you need.
              </p>
              <div className="service-dec-img animation-img-two pt-4">
                <img
                  alt=""
                  src={require("../../assets/img/testing_image/image02.jpg")}
                  className="my-3"
                />
              </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h2 className="py-3 pt-5">See who works on what</h2>
              <p>
                Reports break down all the tracked time and group it by project
                and employee. You can also export data in PDF, CSV, and Excel.
              </p>
              <div className="service-dec-img animation-img-two">
                <img
                  alt=""
                  src={require("../../assets/img/testing_image/image06.jpg")}
                  className="mt-3"
                />
              </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h2 className="py-3 pt-5">Monitor progress</h2>
              <p>
                Create tasks and assign people to them so they can track time
                against them. Estimate how long each task should take and
                visually check progress. If a project goes over budget,
                eClock.Work can send you an alert.
              </p>
              <p>
                If you're working on a project and it goes over budget, you can
                specify the limits in eClock.Work's settings and let it alert
                you.
              </p>
              <div className="service-dec-img animation-img-two">
                <img
                  alt=""
                  src={require("../../assets/img/testing_image/image05.jpg")}
                  className="mt-3 pb-5"
                />
              </div>
              <div className=" d-flex justify-content-center">
                {" "}
                {/* <a href="#!" className="btn btn-theme pt30 ">
                  SET ALL FEATURES
                </a> */}
                <Link to="/features_seeall_features" className="btn btn-theme pt30">SET ALL FEATURES</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductConstruction;
