import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import FirstVideo from '../component/ProductAttendance/FirstVideo'
import  Secondsection from '../component/ProductAttendance/Secondsection'
import AboutArea from '../component/ProductAttendance/AboutArea'
import AboutSteps from '../component/ProductAttendance/AboutSteps'
import RatingArea from '../component/ProductAttendance/RatingArea'

const ProductAttendance = () => {
    return (
        <>
            <Header/>
            <FirstVideo />
            <Secondsection />
            <AboutArea />
            <AboutSteps />
            <RatingArea />
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ProductAttendance
