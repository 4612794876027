import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import check from '../../assets/img/about/tick.png'
import AboutImg from '../../assets/img/about/1.png'

import img from "../../assets/img/home-two-testimonial/veido-test.png";
import { AiOutlinePlayCircle } from 'react-icons/ai';
// Modal 
import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.min.css"

const AboutArea=()=> {
    const [isOpen, setOpen] = useState(false)
 return (
  <>
  <section id="about" className="py100">
                <div className="container">
                <h2 className="">Benefits of eClock.Work timekeeping software</h2>
                <p>Here is why you should start keeping record of your time in eClock.Work:</p>
                    <div className="mt-5">
                        <span className="step-font"></span>
                        <h3 className="mb-4">Maximize Productive Work Hours</h3>
                        <p>eClock.Work offers an intuitive online interface, simplifying time tracking for employees. This boosts productivity by allowing them to efficiently manage their time and focus on tasks</p>
                        <div className="service-dec-img animation-img-two">
                            <img alt=""  className="mt-3"/>
                        </div>
                    </div> 
                    <div className="">
                        <span className="step-font"></span>
                        <h3 className="mb-4">Improve Project Profitability</h3>
                        <p>Estimate project durations and let your team track time effortlessly. eClock.Work calculates project earnings based on billable rates and provides insights into estimated versus actual time spent, enhancing project profitability.</p>
                        <div className="service-dec-img animation-img-two">
                            <img alt=""  className="mt-3"/>
                        </div>
                    </div> 
                    <div className="">
                        <span className="step-font"></span>
                        <h3 className="mb-4">Insanely Simple to Use</h3>
                        <p>Unlike complex timekeeping systems, eClock.Work is user-friendly, encouraging widespread adoption. Its simplicity ensures that everyone can start tracking time instantly with just a click.</p>
                        <div className="service-dec-img animation-img-two">
                            <img alt=""  className="mt-3"/>
                        </div>
                    </div> 
                    <div className="">
                        <span className="step-font"></span>
                        <h3 className="mb-4">Utilize Timekeeping Data to Enhance Work Quality</h3>
                        <p>Leverage data from the timekeeping software to enhance the overall value of employee work. Identify high-value tasks to maximize time allocation and minimize low-value tasks, thereby optimizing productivity and efficiency.</p>
                        <div className="service-dec-img animation-img-two">
                            <img alt=""  className="mt-3"/>
                        </div>
                    </div> 
                    
                    
                    
                </div>
            </section>
  </>

 )
}

export default AboutArea

