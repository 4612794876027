import React from "react";
// import Banner from "../component/Home/Banner"; // Corrected import path
import Header from "../layout/Header"; // Corrected import path
import Footer from "../layout/Footer"; // Corrected import path
import CtaNew from "../layout/CtaNew"; // Corrected import path
import CopyRight from "../layout/CopyRight"; // Corrected import path
import PaidFeatures from '../component/ProductPlansPro/PaidFeatures'




const ProductPlansProPage = () => {
  return (
    <>
      <Header />
      <PaidFeatures/>
      <CtaNew />
      <Footer />
      <CopyRight />
    </>
  );
};

export default ProductPlansProPage;
