import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import FirstInvoicing from '../component/FeaturesInvoicing/FirstInvoicing'             
import TwoColumnInvoicing from '../component/FeaturesInvoicing/TwoColumnInvoicing'             
import DownloadInvoicing from '../component/FeaturesInvoicing/DownloadInvoicing'             
import ManagmentInvoicing from '../component/FeaturesInvoicing/ManagmentInvoicing'             
import MoreFeaturesInvoicing from '../component/FeaturesInvoicing/MoreFeaturesInvoicing'             

const FeaturesInvoicingPage = () => {
    return (
        <>
            <Header/>
            <FirstInvoicing/>
            <TwoColumnInvoicing/>
            <DownloadInvoicing/>
            <ManagmentInvoicing/>
            <MoreFeaturesInvoicing/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default FeaturesInvoicingPage;
