import React from "react";
import Banner from "../component/Home/Banner"; // Corrected import path
import Header from "../layout/Header"; // Corrected import path
import Footer from "../layout/Footer"; // Corrected import path
import CtaNew from "../layout/CtaNew"; // Corrected import path
import CopyRight from "../layout/CopyRight"; // Corrected import path
import TwoImage from "../component/ProductMoreInd/TwoImage"; // Corrected import path
import ProductMoreInd from "../component/ProductMoreInd/ProductMoreInd"; // Corrected import path
import RatingSoftware from "../component/ProductMoreInd/RatingSoftware"; // Corrected import path

const ProductMoreIndPage = () => {
  return (
    <>
      <Header />
      <Banner />
      <TwoImage />
      <ProductMoreInd/>
      <RatingSoftware/>
      <CtaNew />
      <Footer />
      <CopyRight />
    </>
  );
};

export default ProductMoreIndPage;
