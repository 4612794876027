import React from "react";
import SectionHeading from "../../Home/SectionHeading/SectionHeading";
//  Import Img
import img2 from "../../../assets/img/main/2.png";
import img3 from "../../../assets/img/coming-soon.jpg";
import img4 from "../../../assets/img/main/13.png";
import img5 from "../../../assets/img/main/15.png";
import img6 from "../../../assets/img/main/6.png";
import img7 from "../../../assets/img/coming-soon.jpg";
import img16 from "../../../assets/img/main/16.png";
import img18 from "../../../assets/img/main/18.png";
import img19 from "../../../assets/img/coming-soon.jpg";
import img23 from "../../../assets/img/coming-soon.jpg";
import img24 from "../../../assets/img/coming-soon.jpg";

import { Link } from "react-router-dom";

const AboutTwo = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <SectionHeading
            title="Timeline"
            heading={
              "See how your day looked like and find gaps when you were inactive."
            }
          />
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img23} /> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <h5 className="mt-2">Day Overview</h5>
                <p>
                  Review your daily timeline to identify periods of inactivity.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <h5 className="mt-2">Application Activity Monitoring</h5>
                <p>
                  Observe the level of activity during app usage to assess
                  productivity.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img7} /> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img24} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <h5 className="mt-2">Preferences</h5>
                <p>Exclude brief app usage from tracking settings.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}

      {/* Offline mode */}
      <section id="about" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h3>Automated Activation</h3>
                <h4>
                  Initiate activity recording upon computer startup
                  automatically.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}

      {/* Testimonial Area */}
      <section id="testimonial-area" className="py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-6 text-center">
              <div className="">
                {/* <img src={img19} alt="" className="img-fluid" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="product-section">
        <div className="container">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">More features</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Manual Timer Control</h3>
                      <p>
                        Initiate and halt task timers manually as you work on
                        assignments.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Periodic Screenshots</h3>
                      <p>
                        Capture screenshots every five minutes to track your
                        work activities visually.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Inactivity Alert</h3>
                      <p>
                        Receive notifications when idle while a timer is active.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Productivity Analysis</h3>
                      <p>
                        Access detailed reports to understand your productivity
                        and track time allocation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Team Activity Dashboard</h3>
                      <p>
                        View collective and individual work activities for you
                        and your team.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Pomodoro Technique Timer</h3>
                      <p>
                        Configure work and break intervals to enhance your
                        productivity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Privacy Settings</h3>
                      <p>
                        Customize privacy preferences to control data recording
                        and access permissions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Activity Insights</h3>
                      <p>
                        Gain valuable insights into your daily routines and
                        optimize productivity based on detailed activity
                        reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Performance Tracking</h3>
                      <p>
                        Monitor performance metrics to analyze task completion
                        rates and enhance efficiency in daily workflows.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="product-section">
        <div className="container">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">Related</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Task Timer</h3>
                      <p>
                        Utilize a timer to accurately track the time dedicated
                        to individual tasks and projects.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Daily Schedule Overview</h3>
                      <p>
                        Review a comprehensive overview of your day's events and
                        tasks to better understand your schedule and activities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutTwo;
