import React, {useState} from "react";
import img from "../../assets/img/home-two-testimonial/veido-test.png";
import { AiOutlinePlayCircle } from 'react-icons/ai';
// Modal 
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/css/modal-video.min.css'
import img1 from "../../../src/assets/img/about/3.png";


const TimekeeperVideoPartner = () => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <section id="testimonial-area" className="py100 bg-color testimonial_padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="testmonial-veido text-center">
                <img src={img1} alt="img" />
                <div className="veido-play">
                  <ModalVideo channel='youtube' api={false} autoplay isOpen={isOpen} videoId="MLpWrANjFbI" onClose={() => setOpen(false)}/>
                  <a href="#!" onClick={() => setOpen(true)}><i><AiOutlinePlayCircle /></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TimekeeperVideoPartner;