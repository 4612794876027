import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import TwocolumnsImage from '../component/FeaturesTimeKiosk/TwocolumnsImage'
import Reportkisok from '../component/FeaturesTimeKiosk/Reportkisok'
import WorkDevice from '../component/FeaturesTimeKiosk/WorkDevice'
import MobileApp from '../component/FeaturesTimeKiosk/MobileApp'
import MoreFeatures from '../component/FeaturesTimeKiosk/MoreFeatures'
import FirstScheduling from '../component/FeaturesScheduling/FirstScheduling'
import ViewScheduling from '../component/FeaturesScheduling/ViewScheduling'
import EmplyoeeScheduling from '../component/FeaturesScheduling/EmplyoeeScheduling'
import ReportScheduling from '../component/FeaturesScheduling/ReportScheduling'
import ForecastingScheduling from '../component/FeaturesScheduling/ForecastingScheduling'
import MoreFeaturesScheduling from '../component/FeaturesScheduling/MoreFeaturesScheduling'

const FeaturesSchedulingPage = () => {
    return (
        <>
            <Header/>
            <FirstScheduling/>
            <ViewScheduling/>
            <EmplyoeeScheduling/>
            <ReportScheduling/>
            <ForecastingScheduling/>
            <MoreFeaturesScheduling/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default FeaturesSchedulingPage
