import React from "react";
import { Link } from "react-router-dom";

const KisokSeeFeatures = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Kiosk</h2>
                <p className="mb-4">
                  Enable clocking in using a unique PIN from a shared terminal.
                </p>
                <p>
                  <span>
                    <a href="#">MORE DETAILS</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image07.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Intervals</h5>
                      <p className="mt-2 ">
                        Monitor and record employee breaks.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Work Activity</h5>
                      <p className="mt-2 ">
                        Review your tracked work hours and completed tasks.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Personal Code</h5>
                      <p className="mt-2 ">
                        Access your account securely using a unique
                        identification number.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Clock-in</h5>
                      <p className="mt-2 ">
                        Begin your work session from a shared device.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default KisokSeeFeatures;
