import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import Firstsect from '../component/Reporting/Rates/Firstsect'
import TwoColumns from '../component/Reporting/Rates/TwoColumns'
import AboutTwo from '../component/Reporting/Rates/AboutTwo'

const ReportingRates = () => {
    return (
        <>
            <Header/>
            <Firstsect />
            <TwoColumns />
            <AboutTwo />
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ReportingRates
