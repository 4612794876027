import React from 'react'
import { Link } from 'react-router-dom';
import { FaPeopleGroup } from "react-icons/fa6";
import img from "../../assets/img/testing_image/star.svg"

const RatingArea = () => {
    return (
        <>
            <section id="rating-area" className="py50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="news-letter-text pl40 pr40 responsive-mt-30">
                                <div className="cta-text">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                                        <Link to="/register" className="btn btn-theme mt30">CREATE FREE ACCOUNT</Link>
                                    </div>
                                    <div className="mt80">
                                    <img src={img} alt="" />
                                    <p className="black-color"><i><FaPeopleGroup/></i>1,14,677 people signed up last month</p>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RatingArea