import React, { useState } from "react";
import { Link } from "react-router-dom";
import check from "../../assets/img/about/tick.png";
import AboutImg from "../../assets/img/about/1.png";

import img from "../../assets/img/home-two-testimonial/veido-test.png";
import { AiOutlinePlayCircle } from "react-icons/ai";
// Modal
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.min.css";
import img1 from "../../../src/assets/img/about/3.png";


const AccountingSecVideo = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="" className=" bg-color section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h3 className="heading-section-color pt-3">
              How eClock.Work CPA Time and Billing Software Works
              </h3>
              <p className="py-3">
              eClock.Work is a FREE time and billing software that CPA firms can use to track billable hours and bill their clients in a professional manner.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className=" text-center">
                <img src={img1} alt="img" />
                <div className="veido-play">
                  <ModalVideo
                    channel="youtube"
                    api={false}
                    autoplay
                    isOpen={isOpen}
                    videoId="MLpWrANjFbI"
                    onClose={() => setOpen(false)}
                  />
                  <a href="#!" onClick={() => setOpen(true)}>
                    <i>
                      <AiOutlinePlayCircle />
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountingSecVideo;
