import React from "react";
import { IoCheckmarkDone } from "react-icons/io5";

const FeaturesTable = () => {
  return (
    <section id="about-us-area-home4" className="py100 bg-color">
      <div className="container border-bottom" >
        <div className="row text-center">
          <h2 className="mx-auto">Compare features across plans</h2>
        </div>
        <div className="row pb-5">
          <table class="table">
            <thead>
              <tr className="styleclass">
                <th scope="col"></th>
                <th scope="col" className="hover-color">
                  BASIC
                </th>
                <th scope="col" className="hover-color">
                  STANDARD
                </th>
                <th scope="col" className="hover-color">
                  ENTERPRISE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-primary">
                <th scope="row">TIME TRACKING</th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Unlimited tracking</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className="table-light">
                <th scope="row">Time tracker</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr>
                <th scope="row">Timesheet</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td></td>
              </tr>
              <tr className="table-light">
                <th scope="row">Auto tracker</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr>
                <th scope="row">Screenshots</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className="table-light">
                <th scope="row">GPS tracking</th>
                <td></td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr>
                <th scope="row">Force timer</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className="table-light">
                <th scope="row">Custom fields</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr>
                <th scope="row">Lock timesheets</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className="table-light">
                <th scope="row">Targets & reminders</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr>
                <th scope="row">Add time for others</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td></td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className="table-light">
                <th scope="row">Time off</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr>
                <th scope="row">Breaks</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className="table-light">
                <th scope="row">Idle detection & reminders</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Apps</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className="table-light">
                <th scope="row">Time tracker</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td></td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr>
                <th scope="row">Timesheet</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className="table-light">
                <th scope="row">Time tracker</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr>
                <th scope="row">Unlimited tracking</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className="table-light">
                <th scope="row">Time tracker</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr>
                <th scope="row">Unlimited tracking</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className="table-light">
                <th scope="row">Time tracker</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr>
                <th scope="row">Unlimited tracking</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className="table-light">
                <th scope="row">Time tracker</th>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
                <td>
                  <IoCheckmarkDone />
                </td>
              </tr>
              <tr className=" table-primary">
                <th scope="row"></th>
                <td>
                  <a href="#">SIGN UP</a>
                </td>
                <td>
                  <a href="#">EXPLORE</a>
                </td>
                <td>
                  <a href="#">EXPLORE</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default FeaturesTable;
