import React, { useState } from "react";
import img20 from "../../../assets/img/coming-soon.jpg";
import { Link } from "react-router-dom";

// Modal
// Video Modal
import ModalVideo from "react-modal-video";
import "../../../../node_modules/react-modal-video/css/modal-video.min.css";

const Firstsect = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h3>Automated Application Monitoring</h3>
                <h4>
                  Monitor application and website usage time automatically
                  tracked.
                </h4>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <Link to="https://eclockdashboard.vercel.app/login" target="blank" className="btn btn-theme mt-3 mr-2">
                START TRACKING TIME
              </Link>
              <Link
                to="#!"
                className="btn btn-theme mt-3"
                onClick={() => setOpen(true)}
              >
                WATCH VIDEO
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}
      {/* Watch video Start */}
      <ModalVideo
        channel="youtube"
        api={false}
        autoplay
        isOpen={isOpen}
        videoId="MLpWrANjFbI"
        onClose={() => setOpen(false)}
      />
      {/* Watch video End */}

      {/* Testimonial Area */}
      <section id="testimonial-area" className="py-5 mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 text-center">
              <div className="">
                {/* <img src={img20} alt="" className="img-fluid" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Firstsect;
