import React from "react";
import { Link } from "react-router-dom";
import { GiGraduateCap } from "react-icons/gi";
import img1 from "../../assets/img/project-details/2.png";

const ProConsStep = () => {
  return (
    <>
      <section id="" className="">
        <div className="container">
          <div className="row mb-4">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12  ">
              <div className="mt-5">
                <span className="step-font">STEP 1</span>
                <h3 className="step-heading">Create an Account</h3>
                <p>
                  <Link to="#">
                    <span className="span-color">Sign up for free on the eClock.Work website.</span>
                  </Link>
                </p>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 2</span>
                <h3 className="step-heading">Set Up Projects</h3>
                <ol>
                  <li>Create Projects: Organize projects and categorize them by clients.</li>
                  <li>Create Tasks: Within each project, create specific tasks.</li>
                  <li>Track Time: Employees select a project/task, optionally describe their work, and start the timer.</li>
                </ol>
                <div className="service-dec-img animation-img-two">
                  <img alt="" src={img1} className="mt-3" />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 3</span>
                <h3 className="step-heading">Set Hourly Rates</h3>
                <ol>
                  <li><strong>Define Rates: </strong>Set hourly rates for each employee, project, or task.</li>
                  <li><strong>Billable and Non-billable: </strong>Choose which tasks are billable and which are not.</li>
                </ol>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image01.png")}
                    className="mt-3"
                  />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 4</span>
                <h3 className="step-heading">
                Track Billable Hours and Fill Timesheets
                </h3>
                <ol>
                  <li><strong>Automatic and Manual Tracking: </strong> Use a timer or manually log hours in a timesheet via web, mobile, or desktop app.
</li>
                  <li><strong>Mark Billable Time: </strong> Ensure the dollar sign is checked for billable hours.</li>
                </ol>
                <div className="d-flex justify-content-center ">
                  <img
                    alt=""
                    src="/static/media/1.984011c2ff425a179994.png"
                    className="pb-5 mt-3"
                  />
                </div>
                
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 5</span>
                <h3 className="step-heading">
                See and Analyze Time in Reports
                </h3>
                <ol>
                  <li><strong>Generate Reports: </strong>Create detailed reports and download them in PDF, CSV, or Excel formats.</li>
                  <li><strong>Share Reports: </strong>Save reports in eClock.Work and send shareable links to clients for real-time cost tracking.</li>
                </ol>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image02.jpg")}
                    className="mt-3"
                  />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 6</span>
                <h3 className="step-heading">Create Invoices</h3>
                <ol>
                  <li><strong>Automated Invoicing: </strong> eClock.Work automatically creates invoices based on tracked billable time.
</li>
                  <li><strong>Download Invoices: </strong>Save invoices in PDF format for easy sharing with clients.</li>
                </ol>
                <div className="d-flex justify-content-center ">
                  <img
                    alt=""
                    src="/static/media/1.984011c2ff425a179994.png"
                    className="pb-5 mt-3"
                  />
                </div>
              </div>
              <div className="mt-5">
                <span className="step-font">STEP 7</span>
                <h3 className="step-heading">Send Time to QuickBooks</h3>
                <ol>
                  <li><strong>Integration:</strong> Review and approve tracked time.</li>
                  <li><strong>Transfer to QuickBooks: </strong>Send the approved time directly to your QuickBooks account through the integration.</li>
                </ol>
              </div>
              <div className="service-dec-img animation-img-two">
                <img
                  alt=""
                  src={require("../../assets/img/testing_image/image02.jpg")}
                  className="mt-3 text-left"
                />
                <p className="py-3 text-left">
                  <Link to="#" className="span-color">
                    Send time to QuickBooks for payroll and accounting
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProConsStep;
