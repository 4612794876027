import React from "react";
import SectionHeading from "../Home/SectionHeading/SectionHeading";

const CompanyReview = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <SectionHeading title="Make a company review " />
          <div className="row mb-4">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">Benefits of Using eClock Digital Time Card</h4>
              <ol>
                <li>Accurate Time Tracking: eClock ensures precise logging of work hours, eliminating errors associated with manual timekeeping. This accuracy helps in fair employee compensation and reliable job costing.</li>
                <br></br>
                <li>Enhanced Productivity: By automating time tracking, eClock reduces administrative tasks, allowing employees and managers to focus on more productive activities. This efficiency boost leads to better overall performance.</li>
                <br></br>
                <li>HR Compliance: eClock automates compliance with labor laws and HR regulations, maintaining accurate records and generating necessary reports. This reduces the risk of legal issues and simplifies audits.</li>
                <br></br>
                <li>Real-Time Insights: The platform provides real-time data and analytical reporting on attendance, project progress, and labor costs. These insights enable data-driven decision-making and better resource management.</li>
                <br></br>
                <li>Seamless Integration: eClock integrates easily with existing payroll and HR systems, streamlining processes and ensuring a smooth transition from manual to digital time tracking. This integration enhances operational efficiency and consistency.</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}
    </>
  );
};

export default CompanyReview;
