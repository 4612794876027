import React from "react";
// import Banner from "../component/Home/Banner"; // Corrected import path
import Header from "../layout/Header"; // Corrected import path
import Footer from "../layout/Footer"; // Corrected import path
import CopyRight from "../layout/CopyRight"; // Corrected import path
import Features from '../component/HomeTwo/CoreFeatures'
import PricingIndex from '../component/ProductPlansFree/PricingIndex'
import FeaturesTable from '../component/ProductPlansFree/FeaturesTable'
import HappyCustomers from '../component/ProductPlansFree/HappyCustomers'
import FrequentlyAskQuestions from '../component/ProductPlansFree/FrequentlyAskQuestions'
import ImproveBusiness from '../component/ProductPlansFree/ImproveBusiness'

const ProductPlanSeePage = () => {
  return (
    <>
      <Header />
      {/* <Banner /> */}
      <PricingIndex/>
      <FeaturesTable/>
      <HappyCustomers tilt={"tilt"}/>
      <FrequentlyAskQuestions/>
      <ImproveBusiness/>
      <Footer />
      <CopyRight />
    </>
  );
};

export default ProductPlanSeePage;
