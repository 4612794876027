import React from 'react'
import { Link } from 'react-router-dom';
// Import Components
import SectionHeading from '../SectionHeading/SectionHeading' 

//  Import Img
import Icon1 from '../../../assets/img/service/1.png'
import Icon2 from '../../../assets/img/service/2.png'
import Icon3 from '../../../assets/img/service/3.png'
import Icon4 from '../../../assets/img/service/4.png'
import Icon5 from '../../../assets/img/home4/core.png'
import check from '../../../assets/img/about/tick.png'


const WhyWithEclock=()=> {
return (
  <>
  {/* Service Area Start */}
    <section id="service" className="bg-color">
        <div className="container">
            <SectionHeading title="Why track time with eClock.Work" heading={""}/>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                    <div className="service-box animation-translate box-shadow border-radius">
                        <div className="service-box-inner text-center">
                            <img alt="" src={Icon1}  />
                            <h3><Link to="#">Boost productivity</Link></h3>
                            <p>Track time you spend on activities, see where your time goes, and improve your time management skills.</p>
                            <Link to="#">Read More ...</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                    <div className="service-box animation-translate box-shadow border-radius">
                        <div className="service-box-inner text-center">
                            <img alt="" src={Icon2}  />
                            <h3><Link to="#">Bill clients</Link></h3>
                            <p>Track billable time and expenses, show clients how much you've worked, see how much you've earned, and create invoices.</p>
                            <Link to="#">Read More ...</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                    <div className="service-box animation-translate box-shadow border-radius">
                        <div className="service-box-inner text-center">
                            <img alt="" src={Icon3}  />
                            <h3><Link to="#">Manage team</Link></h3>
                            <p>Track attendance for payroll and accounting, see who works on what, and manage workload among teams.</p>
                            <Link to="#">Read More ...</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                    <div className="service-box animation-translate box-shadow border-radius">
                        <div className="service-box-inner text-center">
                            <img alt="" src={Icon4}  />
                            <h3><Link to="#">Streamline processes</Link></h3>
                            <p>Track attendance for payroll and accounting, see who works on what, and manage workload among teams.</p>
                            <Link to="#">Read More ...</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="use-cases">
                        <h6>MORE USE CASES</h6> 
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Time eClock.Work</Link>
                            <Link to="/#" className="tag-btn">Work Hours Tracker</Link>
                            <Link to="/#" className="tag-btn">Employee Time Tracker</Link>
                            <Link to="/#" className="tag-btn">Attendance Tracker</Link>
                            <Link to="/#" className="tag-btn">Task Timer</Link>
                            <Link to="/#" className="tag-btn">Time Card Calculator</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="free-forever-img">   
                        <img alt="" src={Icon5}  />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="free-forever">
                        <div className="left-side-title">
                            <h3>FREE FOREVER!</h3>
                        </div>
                        <div className="about-details pt10">
                            <ul className="pt20">
                                <li className="wow zoomIn" data-wow-duration="2.0s">
                                    <img alt="" src={check}  />Unlimited users
                                </li>
                                <li className="wow zoomIn" data-wow-duration="2.0s">
                                    <img alt="" src={check}  />Unlimited tracking
                                </li>
                                <li className="wow zoomIn" data-wow-duration="2.0s">
                                    <img alt="" src={check}  />Advanced Advisory Team
                                </li>
                                <li className="wow zoomIn" data-wow-duration="2.0s">
                                    <img alt="" src={check}  />Unlimited projects
                                </li>
                            </ul>
                            <Link to="https://eclockdashboard.vercel.app/login" target="blank" className="btn btn-theme mt30">Upgrade →</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
      {/* Service Area End */} 
  </> 
 )
}

export default WhyWithEclock
