import React from 'react'
import CommonBanner from '../component/Common/Banner'
import OurServices from '../component/Home/OurService/OurServices'
import GetStarted from '../component/Common/GetStarted/index'
import Counter from '../component/Common/Counter/index'
import Blog from '../component/Home/Blog/Blog'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import Servicescontent from '../component/FooterPages/Servicescontent'

const Services = () => {
    return (
        <>
            <Header/>
            <Servicescontent/>
            {/* <CommonBanner heading="Services" menu1="Home" menu2="Services"/>
            <OurServices/>
            <GetStarted/>
            <Counter/>
            <Blog/> */}
            {/* <CtaNew/> */}
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default Services
