import React from 'react'
import SectionHeading from '../../Home/SectionHeading/SectionHeading' 
//  Import Img
import img57 from '../../../assets/img/coming-soon.jpg'
import img58 from '../../../assets/img/coming-soon.jpg'
import img59 from '../../../assets/img/coming-soon.jpg'
import img60 from '../../../assets/img/coming-soon.jpg'
import img61 from '../../../assets/img/coming-soon.jpg'


import { Link } from 'react-router-dom';

const MobileApp=()=> {
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="about" className="py100">
       <div className="container">
            <SectionHeading title="Integrations" heading={"Track time inside your favorite project management platform."}/>
            <div className="row mb-4">
               <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2"><a href="">See all integrations</a></h5>
                        <img className="mb-4" alt="" src={img57}  />
                       
                   </div>
               </div>
            </div> 
       </div>
   </section>
      {/* Two Columns Area End */}

  <section id="about" className="py100">
       <div className="container">
            <SectionHeading title="Project management" heading={"Create to-dos, collaborate, and track progress."}/>
            <div className="row justify-content-center mb-4">
                <div className="col-md-6 text-center">
                    <Link to="/#" className="btn btn-theme mt-3">GET PLAY</Link>
                </div>
            </div>
            <div className="row">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Boards</h5>
                        <p>Keep all to-dos and updates in one place.</p>
                   </div>
               </div>
			   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img58}  />
                   </div>
               </div>
            </div>
            
			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="two-columns-holder">
						<img alt="" src={img59} />
					</div>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
					<div className="two-columns-holder">
						<h5 className="mt-2">Fields</h5>
						<p>Only assignees can track time on their tasks.</p>
					</div>
				</div>
			</div>


			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
					<div className="two-columns-holder">
						<h5 className="mt-2">Updates</h5>
						<p>Control who can create new projects and tasks.</p>
					</div>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="two-columns-holder">
						<img alt="" src={img59} />
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="two-columns-holder">
						<img alt="" src={img60} />
					</div>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
					<div className="two-columns-holder">
						<h5 className="mt-2">Views</h5>
						<p>Allow certain people to manage their projects and see all time tracked on them.</p>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12 col-12 mt60">
					<div className="two-columns-holder">
						<h5 className="mt-2">Tracking</h5>
						<p>Archive finished projects and mark tasks as completed so people don’t track time on them.</p>
					</div>
				</div>
				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
					<div className="two-columns-holder">
						<img alt="" src={img61} />
					</div>
				</div>
			</div>
       </div>
   </section>
      {/* Two Columns Area End */}

      <section id="" className="product-section">
        <div className="container">
            <div className="feature-area-bg">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="section-title-center text-center pb30">
							<h3 className="pb10">More features</h3>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Hourly Rates</h3>
									<p>Define hourly rates for tasks and projects, and see earnings.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Projects</h3>
									<p>Break down project into tasks, and categorize them by client.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Reports</h3>
									<p>See breakdown of all time by custom date and dimension.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Export</h3>
									<p>Customize and export data from reports (Excel, PDF, CSV).</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Invoicing</h3>
									<p>Create invoices based on tracked billable hours.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Rounding</h3>
									<p>Round time up, down, or to nearest X minutes.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Costs & Profit</h3>
									<p>Compare what you bill client vs what you pay your team.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Estimates</h3>
									<p>Set time estimates on projects and tasks and track progress.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Expenses</h3>
									<p>Record and invoice project and member fixed-fee expenses.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
        </div>
    </section>

    <section id="" className="product-section">
        <div className="container">
            <div className="feature-area-bg">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="section-title-center text-center pb30">
							<h3 className="pb10">Related</h3>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-6">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Kiosk</h3>
									<p>Define hourly rates for tasks and projects, and see earnings.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Reporting</h3>
									<p>Break down project into tasks, and categorize them by client.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
        </div>
    </section>
  </>

 )
}

export default MobileApp
