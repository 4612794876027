import React from "react";
import { Link } from "react-router-dom";

const DashboardSeeFeat = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Overview</h2>
                <p className="mb-4">
                  View time allocation and team activities at a glance.
                </p>
                <p>
                  <span>
                    <a href="#">MORE DETAILS</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image08.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Insights</h5>
                      <p className="mt-2 ">Insights </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Graphs</h5>
                      <p className="mt-2 ">Overview of time and resources.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Details</h5>
                      <p className="mt-2 ">Track team activities.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Current Tasks</h5>
                      <p className="mt-2 ">
                        Current Tasks Monitor ongoing work assignments.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardSeeFeat;
