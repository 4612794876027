import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import BudgetExpenses from '../component/FeaturesExpenses/BudgetExpenses'             
import FirstExpenses from '../component/FeaturesExpenses/FirstExpenses'             
import TwoColumnExpenses from '../component/FeaturesExpenses/TwoColumnExpenses'             
import ReportExpenses from '../component/FeaturesExpenses/ReportExpenses'             
import ApprovalExpenses from '../component/FeaturesExpenses/ApprovalExpenses'             
import MoreFeaturesExpenses from '../component/FeaturesExpenses/MoreFeaturesExpenses'             

const FeaturesExpensesPage = () => {
    return (
        <>
            <Header/>
            <FirstExpenses/>
            <TwoColumnExpenses/>
            <ReportExpenses/>
            <ApprovalExpenses/>
            <BudgetExpenses/>
            <MoreFeaturesExpenses/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default FeaturesExpensesPage;
