import React from "react";
import { Link } from "react-router-dom";
import { FaPeopleGroup } from "react-icons/fa6";
// import img from "../../assets/img/testing_image/star.svg";
import img1 from '../../assets/img/svg/job-search.svg'
import img2 from '../../assets/img/svg/automation.svg'
import img3 from '../../assets/img/svg/smart-home.svg'
import Tilt from 'react-parallax-tilt';


const ServiceData =[
    {
        img:img1,
        heading:"Enterprise Job Scheduling",
        para:`Enterprise job scheduling software is a powerful tool for organizations of all sizes. It goes beyond simple time tracking, offering a comprehensive solution for automating and managing complex workflows across your entire IT infrastructure.`
    },
    {
        img:img2,
        heading:"Dynamic Workload Automation",
        para:`In today's dynamic business landscape, static scheduling methods just don't cut it. Organizations need a flexible and responsive approach to managing their workloads.`
    },
    {
        img:img3,
        heading:"AI Cloud Automation",
        para:`The convergence of Artificial Intelligence (AI) and cloud computing is revolutionizing automation. AI Cloud Automation takes automation to the next level, leveraging the power of AI to optimize and manage tasks within a cloud environment.`
    },
    
]

const HappyCustomersReport =  (props) => {
  return (
    <>
      <section id="rating-area" className="py-5 bg-color ">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="news-letter-text pl40 pr40 responsive-mt-30">
                <div className="cta-text">
                  <h2 className="black-color heading-section-color fsSize">
                   <Link to="#"> Join Milion of Happy Customers</Link> 
                  </h2>
                  <span className="d-inline-block my-2 mx-auto rating-color">
                    4.7 RATING&nbsp;&nbsp;•&nbsp;&nbsp;4,000+
                    REVIEWS&nbsp;&nbsp;•&nbsp;&nbsp;95% CUSTOMER SATISFACTION
                  </span>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="" className="  bg-color ">
    <div className="container border-bottom pb-5">
         {props.tilt ? (
                 <div className="row">
                 {ServiceData.map((data, index)=>(
                     <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={index}>
                     <Tilt>
                      <div className="service-single-items" >
                          <img src={data.img} alt="icon" />
                          <h4><Link to="service_details">{data.heading}</Link></h4>
                          <p>{data.para}</p>
                     </div>
                     </Tilt>
                  </div>
                 ))}
             </div>
         ):(
            <div className="row">
            {ServiceData.map((data, index)=>(
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={index}>
                 <div className="service-single-items" >
                     <img src={data.img} alt="icon" />
                     <h4><Link to="service_details">{data.heading}</Link></h4>
                     <p>{data.para}</p>
                </div>
             </div>
            ))}
        </div>
         )}

    </div>
 </section> 
    </>
  );
};

export default HappyCustomersReport;
