import React from 'react'
import Header from '../layout/Header'
import CommonBanner from '../component/Common/Banner'
import SingleProject from '../component/ProjectDetails'
import Footer from '../layout/Footer'
import CopyRight from '../layout/CopyRight'

const ProjectDetails = () => {
    return (
        <>
            <Header/>
            <CommonBanner heading="Project Details" menu1="Home" menu2="Project Details"/>
            <SingleProject/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ProjectDetails
