import React from 'react'
import CommonBanner from '../component/Common/Banner'
import AllFaqs from "../component/Faqs";
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'

const Faqs = () => {
    return (
        <>
            <Header/>
            <CommonBanner heading="Faqs" menu1="Home" menu2="Faqs"/>
            <AllFaqs/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default Faqs
