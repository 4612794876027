import React from "react";
import { Link } from "react-router-dom";
import { FcGraduationCap } from "react-icons/fc";
import img1 from "../../assets/img/project-details/2.png";
import { FaArrowCircleRight } from "react-icons/fa";

const BlogResources = () => {
  return (
    <>
      <section id="about" className="heading-color-bg ">
        <div className="container ">
          <div className="row mb-4 ">
            <div className=" col-lg-6 col-md-12 col-sm-12 col-12  text-left p-4">
              <div class="container pt-4  mb-1">
                <h2 class="text-left heading-section-color ">
                  Free time tracker{" "}
                </h2>
                <div class=" pb-1">
                  <p>
                    Time tracking software used by millions. eClock.Work is a time
                    tracker and timesheet app that lets you track work hours
                    across projects.
                  </p>
                  <p class="text-12-px mb-2">FREE FOREVER • UNLIMITED USERS</p>
                </div>
              </div>
            </div>
            <div className=" col-lg-6 col-md-12 col-sm-12 col-12   p-4">
              <div class="container pt-4 pb-4 mb-1">
                <div className="pt-3 text-lg-right text-centre ">
                  <Link to="/#" className="btn btn-theme">
                    GET RESOURES{" "}
                    <span className="iconstyle">
                      <FaArrowCircleRight />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogResources;
