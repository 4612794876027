import React from 'react'
import {AiOutlineCheckCircle} from "react-icons/ai";
import img1 from "../../assets/img/project-details/1.jpg"
import img2 from "../../assets/img/project-details/2.png"
import { Link } from "react-router-dom";

const SecondImagessection = () => {
    return (
        <>
            <section id="project-details-area" className="py100">
                <div className="container">
                    <div className="mt-5">
                        <span className="step-font"></span>
                        <h3 className="step-heading">Feelance Time Tracking Feature</h3>
                        <p> <strong>Create Projects and Tasks: </strong>
                            Organize your freelance work by creating projects and categorizing them by clients. Define specific tasks within each project to streamline time tracking.
                        </p>
                        <p> <strong>Easy Time Tracking:: </strong>
                        Employees can track time from anywhere using the eClock.Work platform. Simply select a project/task, provide a brief optional description, and start the timer to accurately capture billable hours.
                        </p>
                        <p> <strong>Track Time from Anywhere:: </strong>
                        With eClock.Work, freelancers can track their time from any location, ensuring flexibility and convenience. Whether working from home, a coffee shop, or a client's office, you can easily log your hours.
                        </p>
                        <h4 className="step-heading">Track Time from anywhere</h4>
                        <p>With eClock.Work, employees can seamlessly track their work hours. Employees simply select a project/task, add an optional description, and start the timer to accurately capture their billable time. With eClock.Work's freelance time tracking feature, managing your projects and tracking your time has never been easier. Stay organized, efficient, and focused on delivering high-quality work for your clients.</p>
                        <ol>
                            <li>Streamlined Project Management</li>
                            <li>Effortless Time Tracking</li>
                            <li>Flexibility and Convenience</li>
                        </ol>
                        <div className="service-dec-img animation-img-two">
                            <img alt="" src={img1}  className="mt-3"/>
                        </div>
                    </div> 
                    <div className="mt-5">
                        <span className="step-font"></span>
                        <h3 className="step-heading">Freelance Time Tracking and Invoicing</h3>
                        <p>
                        With eClock.Work's employee  time tracking and invoicing features, you can effectively manage your projects, track your time accurately, and ensure smooth invoicing for your freelance work.
                        </p>
                        <ol>
                            <li>Efficient Project Management</li>
                            <li>Simple Time Tracking</li>
                            <li>Convenient Invoicing</li>
                        </ol>
                        <div className="service-dec-img animation-img-two">
                        <img
                                alt=""
                                src={require("../../assets/img/testing_image/image05.jpg")} className="mt-3"
                            />
                        </div>
                    </div> 
                    <div className="mt-5">
                        <span className="step-font"></span>
                        <h3 className="step-heading">Track earnings and project progress </h3>
                        <p>
                        By leveraging eClock.Work's capabilities for time tracking and project management, you can effectively track your earnings and project progress, enabling you to stay organized, efficient, and profitable in your freelance endeavors.
                        </p>
                        <ol>
                            <li>Efficient Project Management</li>
                            <li>Streamlined Time Tracking</li>
                            <li>Monitor Earnings</li>
                            <li>Track Project Progress</li>
                        </ol>
                        <div className="service-dec-img animation-img-two">
                        <img
                            alt=""
                            src={require("../../assets/img/testing_image/image02.jpg")} className="mt-3"
                            />
                        </div>
                    </div> 
                    <div className="row mt-4">  
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                            <Link to="/features_seeall_features" className="btn btn-theme mt30">SEE ALL FEATURES</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SecondImagessection
