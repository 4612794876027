import React from 'react'
import {AiOutlineCheckCircle} from "react-icons/ai";
import img1 from "../../assets/img/project-details/1.jpg"
import img7 from "../../assets/img/coming-soon.jpg"
import img8 from "../../assets/img/coming-soon.jpg"
import img9 from "../../assets/img/testing_image/9.png"
import img3 from "../../assets/img/testing_image/1.png"
import img4 from "../../assets/img/coming-soon.jpg"


import { Link } from "react-router-dom";

const AboutSteps = () => {
    return (
        <>
            <section id="project-details-area" className="py100">
                <div className="container">
                        <div className="mt-5">
                            <span className="step-font">STEP 1</span>
                            <h3 className="step-heading">Create an Account</h3>
                            <p>Visit eClock.Work’s website.</p>
                            <p>
                            Sign up for free using your Google account—it only takes seconds.
                            </p>
                        </div>
                        <div className="mt-5">
                            <span className="step-font">STEP 2</span>
                            <h3 className="step-heading">Invite Your Team</h3>
                            <p>
                            With all eClock.Work plans, including the Pro and Enterprise packages, you can invite an unlimited number of team members via email.
                            </p>
                        </div>
                        <div className="mt-5">
                            <span className="step-font">STEP 3</span>
                            <h3 className="step-heading">Track time</h3>
                            <ol>
                            <li>Your team can track time using a timer or manually.</li>
                            <li>Time can be tracked via the browser, desktop app, or mobile app.</li>
                            <li>Desktop apps include features like reminders and idle detection.</li>
                            <li>Integrations with apps like Trello, Asana, and Todoist allow time tracking within those platforms.</li>
                        </ol>
                            <div className="service-dec-img animation-img-two">
                                {/* <img alt="" src={img7}  className="mt-3"/> */}
                            </div>
                        </div>
                        <div className="mt-5">
                                <span className="step-font">STEP 4</span>
                                <h3 className="step-heading">Set Up Projects</h3>
                                <ol>
                                    <li> Create projects and categorize them by clients.</li>
                                    <li>Create tasks within these projects.</li>
                                    <li>Team members select a project/task, optionally describe their work, and start the timer.</li>
                                </ol>
                            <div className="service-dec-img animation-img-two">
                                {/* <img
                                    alt=""
                                    src={img8} className="mt-3"
                                /> */}
                            </div>
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 5</span>
                                <h3 className="step-heading">Track Time Inside Other Apps</h3>
                                <ol>
                                    <li>Install web extensions to add a timer button in your web apps.</li>
                                    <li>eClock.Work integrates with over 80 tools, including Jira, Trello, Github, Gitlab, and Azure.</li>
                                </ol>
                                <div className="service-dec-img animation-img-two">
                                {/* <img
                                    alt=""
                                    src={img7} className="mt-3"
                                /> */}
                            </div>  
                        </div>

                        <div className="mt-5">
                            <span className="step-font">STEP 6</span>
                            <h3 className="step-heading">Set Hourly Rates</h3>
                            <ol>
                                <li>Set hourly rates for each project.</li>
                                <li>Mark time entries as billable, and eClock.Work will automatically calculate the billable amount.</li>
                            </ol>
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 7</span>
                                <h3 className="step-heading">Export reports</h3>
                                <ol>
                                    <li>Run online reports and customize the data breakdown as needed.</li>
                                    <li>Export reports as PDF, Excel, or CSV.</li>
                                    <li>Share a link to the report with others if necessary.</li>
                                </ol>
                                <p>
                                By following these steps, you and your team can efficiently track work hours, manage projects, and generate reports using eClock.Work.
                                </p>
                            <div className="service-dec-img animation-img-two">
                                {/* <img
                                    alt=""
                                    src={img4} className="mt-3"
                                /> */}
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

export default AboutSteps
