import React,{useState} from 'react'
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";

// Video Modal
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/css/modal-video.min.css';

const ReportsHead = () => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <section id="about-us-area-home4" className="py100">
        <div className="container border-bottom">
          <div className="row text-center">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
              <div className=" ">
                <h2 className="heading-section-color text-centre">
                Analytics
                </h2>
                <p className="py-3 ">
                Analyze breakdowns of time and costs, and export data as needed.







                </p>
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center">
                    <Link to="https://eclockdashboard.vercel.app/login" target="blank" className="btn btn-theme mt-3 mr-2">START TRACKING TIME</Link>
                    <Link to="#!" className="btn btn-theme mt-3" onClick={() => setOpen(true)}>WATCH VIDEO</Link>
                  </div>
                </div>
              </div>
              <div className="mx-auto">
                {/* <img
                  alt=""
                  src={require("../../assets/img/coming-soon.jpg")}
                  className="my-3  py-5"
                /> */}
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
              <div className=" ">
                <h2 className="heading-section-color text-centre">
                Overview Dashboard
                </h2>
                <p className="py-3 ">
                Gain a comprehensive view of all tracked time in a single glance.







                </p>
              </div>
              <div className="mx-auto">
                {/* <img
                  alt=""
                  src={require("../../assets/img/coming-soon.jpg")}
                  className="my-3  py-5"
                /> */}
                <h4 className="heading-section-color text-centre">
                Time Summary
                </h4>
                <p className="py-3 ">
                Review a summary of time allocation categorized by project or billable status, and track both personal and team activities.


                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Watch video Start */}
      <ModalVideo channel='youtube' api={false} autoplay isOpen={isOpen} videoId="MLpWrANjFbI" onClose={() => setOpen(false)}/>
      {/* Watch video End */}
      
    </>
  );
};

export default ReportsHead;