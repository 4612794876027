import React from 'react'
import {AiOutlineCheckCircle} from "react-icons/ai";
import img1 from "../../assets/img/project-details/1.jpg"
import img2 from "../../assets/img/project-details/2.png"
import { Link } from "react-router-dom";

const AboutStep = () => {
    return (
        <>
            <section id="project-details-area" className="py100">
                <div className="container">
                        <div className="mt-5">
                            <span className="step-font">STEP 1</span>
                            <h3 className="step-heading">Create an account</h3>
                            <p>
                            <Link to="#">
                                <span className="span-color">Sign up</span> for free to access eClock.Work's comprehensive time tracking features.
                            </Link>
                            </p>
                        </div>
                        <div className="mt-5">
                            <span className="step-font">STEP 2</span>
                            <h3 className="step-heading">Set up projects</h3>
                            <p>
                            Create projects, categorize them by clients, and define tasks within each project. Simplify task management for your team.
                            </p>
                            <div className="service-dec-img animation-img-two">
                                <img alt="" src={img1}  className="mt-3"/>
                            </div>
                        </div>
                        <div className="mt-5">
                            <span className="step-font">STEP 3</span>
                            <h3 className="step-heading">Track Time</h3>
                            <p>
                            Utilize eClock.Work's unlimited user feature to accommodate your entire team, including developers, designers, managers, and clients.
                            </p>
                            <div className="service-dec-img animation-img-two">
                                <img alt="" src={img1}  className="mt-3"/>
                            </div>
                        </div>
                        <div className="mt-5">
                                <span className="step-font">STEP 4</span>
                                <h3 className="step-heading">Track Time Inside other apps</h3>
                                <p>
                                Your team can conveniently track time using a timer or manual entry via the browser, desktop, or mobile apps. Take advantage of features like reminders and idle detection in desktop apps for enhanced productivity.
                                <Link to="#" className="span-color">
                                </Link>{" "}
                                
                                </p>
                            <div className="service-dec-img animation-img-two">
                                <img
                                    alt=""
                                    src={require("../../assets/img/testing_image/image01.png")} className="mt-3"
                                />
                            </div>
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 5</span>
                                <h3 className="step-heading">Set hourly Rates</h3>
                                <p>
                                Define hourly rates for accurate billing. Employees can track time using the timer or manual entry, ensuring precise invoicing for client projects.
                                </p>
                                
                                <div className="service-dec-img animation-img-two">
                                    <img
                                        alt=""
                                        src={require("../../assets/img/testing_image/image02.jpg")} className="mt-3"
                                    />
                                </div>
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 6</span>
                                <h3 className="step-heading">Export reports</h3>
                                <p>
                                enerate detailed reports to analyze time tracking data. Export reports in various formats (PDF, Excel, etc.) for easy sharing and integration with other tools.
                                </p>
                            <div className="service-dec-img animation-img-two">
                                <img
                                    alt=""
                                    src={require("../../assets/img/testing_image/image02.jpg")} className="mt-3"
                                />
                            </div>
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 7</span>
                                <h3 className="step-heading">Set budget and invoice tracked time</h3>
                                <p>
                                Set project budgets and invoice tracked time effortlessly using eClock.Work's intuitive interface. Streamline your billing process and ensure transparency with clients.
                                </p>
                            <div className="service-dec-img animation-img-two">
                                <img
                                    alt=""
                                    src={require("../../assets/img/testing_image/image03.png")} className="mt-3"
                                />
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

export default AboutStep
