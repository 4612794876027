import React from 'react'
//  Import Img

import img42 from '../../../assets/img/coming-soon.jpg'
import img43 from '../../../assets/img/coming-soon.jpg'
import img44 from '../../../assets/img/coming-soon.jpg'
import img45 from '../../../assets/img/coming-soon.jpg'
import img46 from '../../../assets/img/coming-soon.jpg'
import img47 from '../../../assets/img/coming-soon.jpg'
import img48 from '../../../assets/img/coming-soon.jpg'

import { Link } from 'react-router-dom';

const TwoColumns=()=> {
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="about" className="py100">
       <div className="container">
            <div className="row mb-4">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img42}  />
                        <h5 className="mt-2">Tasks</h5>
                        <p>Categorize time by client, project, and task.</p>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img43}  />
                        <h5 className="mt-2">Status</h5>
                        <p>Track project’s progress as people track time.</p>
                   </div>
               </div>
            </div>
            <div className="row mb-4">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img44}  />
                        <h5 className="mt-2">Time estimate</h5>
                        <p>Estimate how long a project or task will take.</p>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img45}  />
                        <h5 className="mt-2">Budget estimate</h5>
                        <p>Set a fixed budget and track spend against it.</p>
                   </div>
               </div>
            </div>
            <div className="row mb-4">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img46}  />
                        <h5 className="mt-2">Alerts</h5>
                        <p>Get notified before you spend the budget.</p>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img47}  />
                        <h5 className="mt-2">Notes</h5>
                        <p>Keep project related info in one place.</p>
                   </div>
               </div>
            </div>
       </div>
   </section>
      {/* Two Columns Area End */}

      <section id="about" className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <div className="logo-area-heading pb-3">
              <h3>Forecasting progress</h3>
              <h4>Visualize project progress and get predictions based on future work.</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Two Columns Area End */}
    
    {/* Testimonial Area */}
    <section id="testimonial-area" className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6 text-center">
            <div className="">
              <img src={img48} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </>

 )
}

export default TwoColumns
