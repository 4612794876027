import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import About from '../component/Feature/Timer/About'
// import AboutTwo from '../component/Feature/Timer/AboutTwo'
import FirstHeading from '../component/FeaturesTimeKiosk/FirstHeading'
import TwocolumnsImage from '../component/FeaturesTimeKiosk/TwocolumnsImage'
import Reportkisok from '../component/FeaturesTimeKiosk/Reportkisok'
import WorkDevice from '../component/FeaturesTimeKiosk/WorkDevice'
import MobileApp from '../component/FeaturesTimeKiosk/MobileApp'
import MoreFeatures from '../component/FeaturesTimeKiosk/MoreFeatures'

const FeaturesTimeKioskPage = () => {
    return (
        <>
            <Header/>
            <FirstHeading/>
            <TwocolumnsImage/>
            <Reportkisok/>
            <WorkDevice/>
            {/* <About /> */}
            <MobileApp/>
            <MoreFeatures/>
            {/* <AboutTwo /> */}
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default FeaturesTimeKioskPage
