import React from "react";
//  Import Img
import img3 from "../../assets/img/coming-soon.jpg";
import img2 from "../../assets/img/coming-soon.jpg";

import { Link } from "react-router-dom";

const EmplyoeeScheduling = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="">
        <div className="container border-bottom pb-5">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
            <div className="text-center pb-3">
              {" "}
              {/* Added text-center class to center the text horizontally */}
              <h2>Employee Calendar</h2>
              <p>
                Enable individuals to access and track time according to their
                designated timetable.
              </p>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600"> Task Calendar</h5>
                <p>View all your tasks displayed on a calendar.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4 ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color fw600"> Time Tracking</h5>
                <p>Monitor assignment durations via web or phone.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmplyoeeScheduling;
