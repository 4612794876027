import React from 'react'
import SectionHeading from '../../Home/SectionHeading/SectionHeading' 
//  Import Img
import img2 from '../../../assets/img/main/2.png'
import img3 from '../../../assets/img/main/3.png'
import img4 from '../../../assets/img/main/4.png'
import img5 from '../../../assets/img/main/5.png'
import img6 from '../../../assets/img/main/6.png'
import img7 from '../../../assets/img/main/7.png'
import img8 from '../../../assets/img/main/8.png'
import img9 from '../../../assets/img/coming-soon.jpg'
import AboutImg from '../../../assets/img/about/1.png'
import AboutImgTow from '../../../assets/img/about/2.png'

import { Link } from 'react-router-dom';

const MobileApp=()=> {
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="about" className="py100">
       <div className="container">
            <SectionHeading title="Mobile App" heading={"Log your time when you're not at your computer. It also works without an internet connection."}/>
            <div className="row mb-4">
               <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Track time using a timer</h5>
                        {/* <img className="mb-4" alt="" src={img9}  /> */}
                        {/* <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Windows</Link>
                            <Link to="/#" className="tag-btn">Mac</Link>
                            <Link to="/#" className="tag-btn">Linux</Link>
                        </div> */}
                   </div>
               </div>
               <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Enter time in timesheet</h5>
                        {/* <img className="mb-4" alt="" src={img9}  /> */}
                        
                   </div>
               </div>
               <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">See how your day looked like</h5>
                        {/* <img className="mb-4" alt="" src={img9}  /> */}
                        
                   </div>
               </div>
            </div> 
            <div className="row mb-4">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">See tracked time in reports</h5>
                        {/* <img className="mb-4" alt="" src={img9}  /> */}
                        
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Enter time in timesheet</h5>
                        {/* <img className="mb-4" alt="" src={img9}  /> */}
                   </div>
               </div>
            </div> 
            
       </div>
   </section>
      {/* Two Columns Area End */}
  </>

 )
}

export default MobileApp
