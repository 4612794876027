import img1 from '../../../assets/img/shop/1.png'


export const TutorialData = [
    {
        img: img1,
        name: "Travel Bag",
        price: 200.00,
        
    },
    {
        img: img1,
        name: "Mens T-Shirt",
        price: 150.00,
        button: "Add TO CART"
    },
    {
        img: img1,
        name: "Polo T-Shirt",
        price: 120.00,
        button: "Add TO CART"
    },
    {
        img: img1,
        name: "Mens T-Shirt",
        price: 60.00,
        button: "Add TO CART"
    },
    {
        img: img1,
        name: "Women,s T-Shirt",
        price: 70.00,
        button: "Add TO CART"
    },
    {
        img: img1,
        name: "Girls T-Shirt",
        price: 45.00,
        button: "Add TO CART"
    },
    {
        img: img1,
        name: "Hand Bag",
        price: 35.00,
        button: "Add TO CART"
    },
    {
        img: img1,
        name: "Shoes",
        price: 150.00,
        button: "Add TO CART"
    },
    // {
    //     img: img1,
    //     name: "Smart Watc",
    //     price: 300.00,
    //     button: "Add TO CART"
    // },
    // {
    //     img: img1,
    //     name: "Smart Watc",
    //     price: 450.00,
    //     button: "Add TO CART"
    // },
    // {
    //     img: img1,
    //     name: "Travel Bag",
    //     price: 130.00,
    //     button: "Smart Watch"
    // },
    // {
    //     img: img1,
    //     name: "School Bag",
    //     price: 110.00,
    //     button: "Add TO CART"
    // }
]
