import Icon1 from '../../assets/img/service/1.png'
import Icon2 from '../../assets/img/service/2.png'
import Icon3 from '../../assets/img/service/3.png'
import Icon4 from '../../assets/img/service/4.png'
import Icon5 from '../../assets/img/service/5.png'
import Icon6 from '../../assets/img/service/6.png'

export const OurServiceData = [
{
 img:Icon1,
 heading:"Getting Started",
 paragraph:"Lorem ipsum dolor sit amet, sed nom consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
 button:"Read More ..."
},
{
 img:Icon2,
 heading:"Time Tracking",
 paragraph:"Lorem ipsum dolor sit amet, sed nom consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
 button:"Read More ..."
},
{
 img:Icon3,
 heading:"Apps ",
 paragraph:"Lorem ipsum dolor sit amet, sed nom consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
 button:"Read More ..."
},
{
 img:Icon4,
 heading:"Reports ",
 paragraph:"Lorem ipsum dolor sit amet, sed nom consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
 button:"Read More ..."
},
{
 img:Icon5,
 heading:"Projects ",
 paragraph:"Lorem ipsum dolor sit amet, sed nom consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
 button:"Read More ..."
},
{
 img:Icon6,
 heading:"Users",
 paragraph:"Lorem ipsum dolor sit amet, sed nom consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
 button:"Read More ..."
}
]
