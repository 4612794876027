import React from "react";
//  Import Img

import { Link } from "react-router-dom";

const TwoColumnsImage = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100 ">
        <div className="container">
          <div className="row mb-4 animation-img-two bg-color">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image07.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div class="blue-box-text">
                    <h3 className="text-left">Leave Policies</h3>
                    <p className="text-left">
                      Establish your own set of time-off rules.
                    </p>
                    <ul className="custom-list li text-left marginleft-list">
                      <li>
                        Policy Applicability
                        <p>Choose the employees the policy applies to.</p>
                      </li>

                      <li>
                        Approval Rights
                        <p>Designate approvers for time-off requests.</p>
                      </li>
                      <li>
                        Automatic Accrual
                        <p>Monthly adjustment of leave balances.</p>{" "}
                      </li>
                      <li>
                        UnTime Unitsit{" "}
                        <p>Request leave in days, hours, or minutes.</p>
                      </li>
                      <li>
                        Color Coding
                        <p>Assign colors to different policies and holidays.</p>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Holiday Settings</h5>
                  <p className="mt-2">
                    Specify holidays when team members are off.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Leave Requests</h5>
                  <p className="mt-2">
                    Submit and authorize time off requests.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image11.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Alerts</h5>
                  <p className="mt-2">
                    Receive email notifications for leave requests.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Supervisor Approval</h5>
                  <p className="mt-2">
                    Enable managers to authorize their team’s time entries.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image03.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Search</h5>
                  <p className="mt-2">Easily locate specific requests.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Leave Entries</h5>
                  <p className="mt-2">
                    Generate time entries from leave requests for inclusion in
                    time reports.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image07.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Restricted Users</h5>
                  <p className="mt-2">
                    Include kiosk participants by name (no email needed).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}
    </>
  );
};

export default TwoColumnsImage;
