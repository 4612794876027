import React from 'react'
import ResetPasswordForm from '../component/ResetPassword'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'

const ResetPassword = () => {
    return (
        <>
            <Header/>
            <ResetPasswordForm/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ResetPassword
