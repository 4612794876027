import React, {useState} from "react";
import { AiOutlinePlayCircle } from 'react-icons/ai';
// images
import check from "../../assets/img/home-two-data/1.jpg";
import img from "../../assets/img/home-two-testimonial/veido-test.png";
import setp2img from '../../assets/img/about/1.png'
import setp3img from '../../assets/img/about/2.png'
import logo1 from '../../assets/img/logo/1.png'
import logo2 from '../../assets/img/logo/2.png'
import logo3 from '../../assets/img/logo/3.png'
import logo4 from '../../assets/img/logo/4.png'
import logo5 from '../../assets/img/logo/5.png'
import img1 from "../../../src/assets/img/about/3.png";
// Modal
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/css/modal-video.min.css'

const PlanningContent = () => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
    <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h2>What is employee scheduling software?</h2>
              <p className="mb-5">eClock.Work software is a tool that simplifies the process of organizing staff schedules and tracking time. With eClock.Work, you can efficiently manage your work and stay on top of all your projects.With eClock.Work you will be able to:</p>
              <ul className="data-check-arae">
                <li><img src={check} alt="img" />Monitor team availability</li>
                <li><img src={check} alt="img" />Make optimized schedules</li>
                <li><img src={check} alt="img" />Manage time off</li>
                <li><img src={check} alt="img" />Visualize projects & milestones</li>
                <li><img src={check} alt="img" />Monitor budget & expenses</li>
                <li><img src={check} alt="img" />Export reports for payroll</li>
              </ul>
            </div>
          </div>
        </div>
    </section>
    <section>
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <h2 className="text-center">How does employee scheduling work?</h2>
                </div>
            </div>
        </div>
    </section>
    <section id="testimonial-area" className="py100 bg-color testimonial_padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="testmonial-veido text-center">
                <img src={img1} alt="img" />
                <div className="veido-play">
                  <ModalVideo channel='youtube' api={false} autoplay isOpen={isOpen} videoId="MLpWrANjFbI" onClose={() => setOpen(false)}/>
                  <a href="#!" onClick={() => setOpen(true)}><i><AiOutlinePlayCircle /></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
    <section className="py100">
        <div className="container">
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 1</h6>
                    <h5>Create an account</h5>
                    <a href="#!" className="link-color">Sign up - it's free</a>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 2</h6>
                    <h5>Set up projects</h5>
                    <p>Create projects and tasks your team is going to work on.</p>
                    <img src={setp2img} alt="img" />
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 3</h6>
                    <h5>Add team members</h5>
                    <p>Invite your entire team to eClock.Work so you can effectively plan and manage their schedules.</p>
                    <img src={setp3img} alt="img" />
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 4</h6>
                    <h5>Create assignments</h5>
                    <p>Define which team members need to work on specific tasks and when they are scheduled to do so. Add detailed notes for clarity and additional information.</p>
                    <img src={setp2img} alt="img" />
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 5</h6>
                    <h5>Publish assignments</h5>
                    <p>Make the schedule public so that all team members are informed about their assignments and when they are scheduled to work.</p>
                    <img src={setp3img} alt="img" />
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 6</h6>
                    <h5>Manage capacity</h5>
                    <ul className="data-check-arae">
                        <li><img src={check} alt="img" />Visualize assignments on a timeline to better understand workload distribution.</li>
                        <li><img src={check} alt="img" />Identify team members who have too many hours assigned and may be overloaded.</li>
                        <li><img src={check} alt="img" />Determine which team members have capacity to take on additional work.</li>
                    </ul>
                    <img src={setp2img} alt="img" />
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 7</h6>
                    <h5>Track time</h5>
                    <p>Employees can view their assignments and easily track time on them using either the app or a shared kiosk station.</p>
                    <img src={setp3img} alt="img" />
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 8</h6>
                    <h5>Sync calendars</h5>
                    <p>Sync Outlook or Google Calendar with eClock.Work to ensure that your employees can view tracked time, events, and published assignments directly from their Calendar. They can also track time on tasks directly from the Calendar interface.</p>
                    <img src={setp2img} alt="img" />
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 9</h6>
                    <h5>Manage time off</h5>
                    <p>Effortlessly request or approve employee time off and manage company policies all in one centralized location.</p>
                    <img src={setp3img} alt="img" />
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 10</h6>
                    <h5>Monitor Task Assignment and Work Status</h5>
                    <p>Navigate to the Dashboard tab to conveniently view which team members are currently clocked in and assigned to specific tasks.</p>
                    <img src={setp2img} alt="img" />
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 11</h6>
                    <h5>Attendance report</h5>
                    <p>Generate comprehensive reports to track daily employee arrival and departure times, breaks, overtime, and time off.</p>
                    <img src={setp3img} alt="img" />
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <h6>Step 12</h6>
                    <h5>Assignments report</h5>
                    <p>Assign work and compare scheduled versus actual work hours across projects, users, and dates with detailed assignments reports.</p>
                    <img src={setp2img} alt="img" />
                </div>
            </div>
        </div>
    </section>
    <section className="py50">
        <div className="container">
            <div className="row justify-content-center mb-5">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <div className="rated-software text-center">
                        <h6>#1 RATED SOFTWARE</h6>
                        <h2>Join millions of happy customers</h2>
                        <div className="customers-support">
                            <p>World-class support | 4,000+ reviews | 95% customer satisfaction.</p>
                        </div>
                    </div>
                    <div className="support-features-flex mt-5">
                        <div className="row">
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                <div class="support-features-p">
                                    <span class="support-features-headline">24/7</span>
                                    <span class="support-features-explainer">support anytime, anywhere</span>
                                </div>

                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                <div class="support-features-p">
                                    <span class="support-features-headline">1h</span>
                                    <span class="support-features-explainer">average response time</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                <div class="support-features-p">
                                    <span class="support-features-headline">95%</span>
                                    <span class="support-features-explainer">satisfaction score (16k+ ratings)</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                <div class="support-features-p">
                                    <span class="support-features-headline">99.99%</span>
                                    <span class="support-features-explainer">highest uptime levels</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="customer-logos">
                        <div className="row text-center">
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <img src={logo1} alt="img" />
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <img src={logo2} alt="img" />
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <img src={logo3} alt="img" />
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <img src={logo4} alt="img" />
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <img src={logo5} alt="img" />
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <img src={logo1} alt="img" />
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <img src={logo1} alt="img" />
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <img src={logo2} alt="img" />
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <img src={logo3} alt="img" />
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </div>
    </section>
    </>
  );
};

export default PlanningContent;
