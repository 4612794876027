import React from "react";
import { Link } from "react-router-dom";

const ReportTimeOff = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className=" ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Daily Attendance Overview</h2>
                <p className="mb-4">
                  View daily hours worked, breaks taken, overtime hours, and
                  time off taken.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image07.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>
    </>
  );
};

export default ReportTimeOff;
