import React from 'react'
import Banner from '../component/Home/Banner'
import Partner from '../component/Home/Partner/Partner'
import BudgetingVideo from '../component/ProductBudgeting/BudgetingVideo'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import ProductBudgeting from '../component/ProductBudgeting/ProductBudgeting' 
import ToollManage from '../component/ProductBudgeting/ToolManage' 




const ProductBudgetingPage = () => {
    return (
        <>
            <Header/>
            <Banner/>
            {/* <Partner/>  */}
            <BudgetingVideo/>
            <ProductBudgeting/>
            <ToollManage/>
            {/* <Partner/>  */}
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ProductBudgetingPage
