import React from 'react'
import HeaderHelp from '../layout/HeaderHelp'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import FirstVideo from '../component/Help/Tutorials/FirstVide'
import Product from '../component/Help/Tutorials/Product'



const HelpAboutus = () => {
    return (
        <>
            <HeaderHelp/>
            <FirstVideo />
            <Product />
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default HelpAboutus
