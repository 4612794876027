import React from "react";
import { Link } from "react-router-dom";
import { FcGraduationCap } from "react-icons/fc";
import img1 from "../../assets/img/project-details/2.png";

const ReportingStep = () => {
  return (
    <>
      <section id="about" className="bg-color pt-5 ">
        <div className="container pb-3">
          <div className="row mb-4">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 border-bottom">
              <h2 className="heading-section-color mt-2">
                Project tracking & billing features
              </h2>
              <h3 className="my-3">Track billable and non-billable time</h3>
              <p className="py-3">
              With eClock.Work, you can easily track both billable and non-billable time for your projects. This free project time tracker empowers your employees to accurately monitor the time they spend working on tasks associated with their projects.
              </p>
              <div className="d-flex justify-content-center">
                <div className="mx-auto">
                  <img
                    alt=""
                    src="/static/media/1.984011c2ff425a179994.png"
                    className="pb-5"
                  />
                </div>
              </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 border-bottom ">
              <h3 className="my-3 pt-5">
              Effectively manage projects and monitor progress with eClock.Work
              </h3>
              <p className="py-2">
              <strong> Create Tasks and Assign People:</strong> Generate tasks within projects and assign team members to them. This enables individuals to track their time against specific tasks.
              </p>
              <p className="py-2">
              <strong> Estimate Task Duration:</strong> Provide estimates for how long each task should take to complete. This helps in setting realistic expectations and deadlines.
              </p>
              <p className="py-2">
              <strong> Visualize Progress in the Dashboard:</strong>  Utilize the Dashboard feature to visually analyze the progress of tasks and projects. Track time spent, compare it to estimates, and ensure that projects are on schedule.
              </p>
              <div className="d-flex justify-content-center">
                <div className="mx-auto">
                  <img
                    src="/static/media/2.cbf079bf62b977b1a92b.png"
                    alt="img"
                    className="pb-5"
                  />
                </div>
              </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 border-bottom ">
              <h3 className="my-3 pt-5">Forecast project progress effectively with eClock.Work</h3>
              <p className="py-2">
              <strong> Create Tasks and Assign People:</strong> Visualize Project Progress: Utilize visualization tools to see how your project is advancing in real-time. Monitor completed tasks, remaining work, and overall project status.
              </p>
              <p className="py-2">
              <strong> Predict Project Performance:</strong> Incorporate time and budget estimates into your project planning. By comparing these estimates with tracked and scheduled tasks, you can make informed predictions about project completion dates and financial performance.
              </p>
              <p className="py-2">
              <strong> Make Data-Driven Decisions: </strong>Use the insights gained from forecasting to make data-driven decisions about resource allocation, task prioritization, and project timelines. This ensures that your projects stay on track and within budget.
              </p>
              <div className="d-flex justify-content-center">
                <div className="mx-auto">
                  <img
                    alt=""
                    src="/static/media/1.984011c2ff425a179994.png"
                    className="pb-5"
                  />
                </div>
              </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 border-bottom pt-5">
              <h2 className="heading-section-color mt-2">
                Project tracking & billing features
              </h2>
              <p>eClock.Work offers robust project tracking and billing features, including the ability to:</p>
              <h3 className="my-3">Track billable and non-billable time</h3>
              <p className="py-3">
              Monitor both billable hours, which can be invoiced to clients, and non-billable hours, which are spent on internal tasks or administrative work.
              </p>
              <div className="d-flex justify-content-center">
                <div className="mx-auto">
                  <img
                    alt=""
                    src="/static/media/1.984011c2ff425a179994.png"
                    className="pb-5"
                  />
                </div>
              </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 border-bottom ">
              <h3 className="my-3 pt-5">
                Project Time Tracking:
              </h3>
              <p className="py-3">
              Utilize the completely project time tracker to allow employees to accurately record their work hours on tasks associated with various projects.
              With these features, eClock.Work empowers businesses to effectively manage project time and billing processes without incurring additional costs. 
              </p>
              <div className="d-flex justify-content-center">
                <div className="mx-auto">
                  <img
                    src="/static/media/2.cbf079bf62b977b1a92b.png"
                    alt="img"
                    className="pb-5"
                  />
                </div>
              </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12  pb-5">
              <h3 className="my-3 pt-5">Manage projects and monitor progress</h3>
              <p className="py-3">
              To manage projects effectively and monitor progress, eClock.Work offers the following features:
              <li className="pb-2">Create Tasks</li>
              <li className="pb-2">Assign People</li>
              <li className="pb-2">Estimate Task Duration</li>
              <li className="pb-2">Visualize Progress in the Dashboard</li>
              </p>
              <div className="d-flex justify-content-center">
                <div className="mx-auto">
                  <img
                    alt=""
                    src="/static/media/1.984011c2ff425a179994.png"
                    className="pb-5"
                  />
                </div>
              </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12  pb-5">
              <h3 className="my-3 pt-5">Forecast project progress effectively with eClock.Work</h3>
              <p className="py-3">
              <li className="pb-2">Visualize Project Progress</li>
              <li className="pb-2">Predict Project Performance</li>
              <li className="pb-2">Make Data-Driven Decisions</li>
              </p>
              <div className="d-flex justify-content-center">
                <div className="mx-auto">
                  <img
                    alt=""
                    src="/static/media/1.984011c2ff425a179994.png"
                    className="pb-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportingStep;
