import React from "react";
import SectionHeading from "../../Home/SectionHeading/SectionHeading";
//  Import Img
import img2 from "../../../assets/img/coming-soon.jpg";
import img3 from "../../../assets/img/coming-soon.jpg";
import img4 from "../../../assets/img/coming-soon.jpg";
import img5 from "../../../assets/img/coming-soon.jpg";
import img6 from "../../../assets/img/coming-soon.jpg";
import img7 from "../../../assets/img/coming-soon.jpg";
import img8 from "../../../assets/img/main/8.png";
import AboutImg from "../../../assets/img/about/1.png";
import AboutImgTow from "../../../assets/img/about/2.png";

import { Link } from "react-router-dom";

const TwoColumns = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
                <h5 className="mt-2">Timeline</h5>
                <p>View your daily schedule in time blocks.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
                <h5 className="mt-2">Total time</h5>
                <p>Track total time across the week.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img4} /> */}
                <h5 className="mt-2">Zoom</h5>
                <p>Choose between 5, 15, 30, or 60-minute time blocks.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img5} /> */}
                <h5 className="mt-2">Add hours</h5>
                <p>Easily add or edit time blocks with a click.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img6} /> */}
                <h5 className="mt-2">Duplicate</h5>
                <p>Duplicate time blocks quickly.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img7} /> */}
                <h5 className="mt-2">Details</h5>
                <p>Edit details by clicking on a time block.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img6} /> */}
                <h5 className="mt-2">Time off</h5>
                <p>Access upcoming holidays and vacations.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img7} /> */}
                <h5 className="mt-2">Team's calendar</h5>
                <p>Manage and edit calendars for your team members.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}
    </>
  );
};

export default TwoColumns;
