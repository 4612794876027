import React from 'react'
import {BsSearch} from "react-icons/bs"


const Searchbar = () => {
    return (
        <>  
            <section id="rating-area" className="py50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="news-letter-text pl40 pr40 responsive-mt-30">
                                <h3>How can we help ?</h3>
                                    <form action="#">
                                        <div className="blog-search-option">
                                            <input type="text" placeholder="Search..."/>
                                            <button className="button" type="submit"><i><BsSearch/></i>
                                            </button>
                                        </div>
                                    </form>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default Searchbar