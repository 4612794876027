import React from 'react'
import CommonBanner from '../component/Common/Banner'
import BlogDetailsArea from "../component/BlogDetails/BlogDetailsArea";
import DetailsContent from "../component/BlogDetails/DetailsContent";
import Sidebar from "../component/Blog/Sidebar";
import ProductHelpForum from "../component/ProductHelpForum/ProductHelpForum";

const ProductHelpForumPage = () => {
    return (
        <>
            {/* <CommonBanner heading="Blog Details" menu1="Home" menu2="Blog Details"/> */}
            {/* <ProductHelpForum/> */}
            <BlogDetailsArea>
                <DetailsContent/>
                <Sidebar/>
            <ProductHelpForum/>
            </BlogDetailsArea>
        </>
    )
}

export default ProductHelpForumPage
