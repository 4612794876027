import React, { useState } from "react";
import "../../../node_modules/react-modal-video/css/modal-video.min.css";

const VideoComponent = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <div className="service-dec-img animation-img-two">
                <img
                  alt=""
                  src={require("../../assets/img/testing_image/image06.jpg")}
                  className="mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoComponent;
