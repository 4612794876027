import React from "react";
// import About Img
import img1 from "../../assets/img/about-page.png";
// import Icon
import { FiCheck } from "react-icons/fi";

const History = () => {
  return (
    <>
      {/* About Top Area Start */}
      <section id="about-top" className="py100 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="about-img">
                <h2>About eClock:</h2>
                <p>
                  eClock is a cutting-edge digital time tracking solution
                  designed to meet the diverse needs of modern businesses across
                  all industries, including education and government sectors.
                  Our platform offers comprehensive features that streamline
                  workforce management, enhance operational efficiency, and
                  ensure compliance with HR regulations.
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-2">
              <div className="about-img">
                <h2>Time Tracking and Job Costing:</h2>
                <p>
                  eClock provides precise time tracking capabilities, allowing
                  employees to log their hours effortlessly. With integrated job
                  costing features, businesses can accurately allocate labor
                  costs to specific projects or tasks, ensuring better budget
                  management and financial oversight. This is particularly
                  beneficial for industries where project-based work and
                  detailed cost tracking are essential.
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-2">
              <div className="about-img">
                <h2>Analytical Reporting:</h2>
                <p>
                  eClock’s robust analytical reporting tools provide valuable
                  insights into workforce productivity and operational
                  efficiency. Businesses can generate detailed reports on
                  employee attendance, project progress, and labor costs,
                  enabling data-driven decision-making. These insights help
                  identify trends, optimize resource allocation, and improve
                  overall performance.
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-2">
              <div className="about-img">
                <h2>Industry Applications</h2>
                <p>
                  eClock is designed to serve a wide range of industries,
                  including education and government. In educational
                  institutions, our solution helps manage faculty and staff
                  hours, ensuring fair compensation and compliance with
                  educational labor standards. For government agencies, eClock
                  offers secure and reliable time tracking and reporting,
                  supporting transparency and accountability in public sector
                  operations.
                </p>
                <p>
                  At eClock, we are committed to delivering innovative workforce
                  management solutions that drive efficiency, compliance, and
                  informed decision-making. Our user-friendly platform empowers
                  organizations to optimize their operations, enhance
                  productivity, and achieve their strategic goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Top Area Start */}
    </>
  );
};

export default History;
