import React from "react";
import SectionHeading from "../../Home/SectionHeading/SectionHeading";
//  Import Img
import img2 from "../../../assets/img/coming-soon.jpg";
import img3 from "../../../assets/img/coming-soon.jpg";
import img4 from "../../../assets/img/main/4.png";
import img5 from "../../../assets/img/main/5.png";
import img18 from "../../../assets/img/coming-soon.jpg";
import img22 from "../../../assets/img/coming-soon.jpg";
import img21 from "../../../assets/img/coming-soon.jpg";
import AboutImg from "../../../assets/img/about/1.png";
import AboutImgTow from "../../../assets/img/about/2.png";

import { Link } from "react-router-dom";

const TwoColumns = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
                <h5 className="mt-2">Overview</h5>
                <p>View the start and end times of your work sessions.</p>
                <div className="btn-holder"></div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
                <h5 className="mt-2">Activity Logging</h5>
                <p>Track the applications and websites you access.</p>
                <div className="btn-holder"></div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img21} /> */}
                <h5 className="mt-2">Transform into Entries</h5>
                <p>
                  Generate time logs from recorded activities and generate
                  reports based on them.
                </p>
                <div className="btn-holder"></div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img22} /> */}
                <h5 className="mt-2">Total Privacy Assurance</h5>
                <p>
                  Activities recorded are stored locally on your device,
                  accessible exclusively to you.
                </p>
                <div className="btn-holder"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}

      {/* Offline mode */}
      <section id="about" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h3>Automatic Tracking Application</h3>
                <h4>
                  Download the desktop application to log activities
                  automatically.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}

      {/* Testimonial Area */}
      <section id="testimonial-area" className="py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-6 text-center">
              <div className="">
                {/* <img src={img18} alt="" className="img-fluid" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TwoColumns;
