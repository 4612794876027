import React, { useState } from "react";
import { Link } from "react-router-dom";
// Import Components
import { AiOutlinePlayCircle } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs"; // Import random icons
import { MdOutlineAccountBox } from "react-icons/md";
import { MdAccountBalance } from "react-icons/md";
import { BiBookAlt } from "react-icons/bi";
import { GrIntegration } from "react-icons/gr";
import { SiPivotaltracker } from "react-icons/si";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { IoIosFingerPrint } from "react-icons/io";
import { RxDashboard } from "react-icons/rx";
import { SlPeople } from "react-icons/sl";
import { CiFilter } from "react-icons/ci";


//  Import Img
import small from "../../assets/img/small-icon/1.png";
import small2 from "../../assets/img/small-icon/2.png";
import small3 from "../../assets/img/small-icon/3.png";
import img from "../../assets/img/home-two-testimonial/veido-test.png";

// Modal
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.min.css";

const ProductMoreInd = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* Product Content Start */}
      <section id="" className="product-section " >
        <div className="container border-bottom">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <span>TIMESHEET</span>
                  <h2 className="pb10 heading-section-color">
                    For office workers
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <BsFillPersonFill className="feature-icon" />{" "}
                      {/* Random icon */}
                      <h3 className="hover-color">Accounting</h3>
                      <p>
                        Start and stop timer as you work on different
                        activities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <MdOutlineAccountBox className="feature-icon" />
                      <h3 className="hover-color">Cross-Platform</h3>
                      <p>Track time from any device and see all data online.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <MdAccountBalance className="feature-icon" />
                      <h3 className="hover-color">Reminders</h3>
                      <p>
                        Receive a notification when you forget to start the
                        timer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <BiBookAlt className="feature-icon" />
                      <h3 className="hover-color">Idle Detection</h3>
                      <p>Remove time you've spent away from the computer.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <GrIntegration className="feature-icon" />
                      <h3 className="hover-color">Pomodoro</h3>
                      <p>
                        Improve focus by working in intervals, followed by brief
                        breaks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <SiPivotaltracker className="feature-icon" />
                      <h3 className="hover-color">Integrations</h3>
                      <p>Track time in other apps, like Trello, Gmail, etc.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <IoIosFingerPrint className="feature-icon" />
                      <h3 className="hover-color">Auto Tracker</h3>
                      <p>Track time based on apps and websites you use.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <IoCalendarNumberOutline className="feature-icon" />
                      <h3 className="hover-color">Calendar</h3>
                      <p>
                        See how your day and week really looks like based on
                        tracked data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <MdAccountBalance className="feature-icon" />

                      <h3 className="hover-color">Dashboard</h3>
                      <p>
                        Visualize productivity trends and how you spend time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="" className="product-section bg-color">
        <div className="container ">
          <div className="feature-area-bg bg-color">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <span>TIME CLOCK</span>
                  <h2 className="pb10 heading-section-color">
                  For onsite workers
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                    <RxDashboard  className="feature-icon"/>

                      <h3 className="hover-color">Call Center</h3>
                      <p>
                        Start and stop timer as you work on different
                        activities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <SlPeople className="feature-icon"/>
                      <h3 className="hover-color">Cross-Platform</h3>
                      <p>Track time from any device and see all data online.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <CiFilter className="feature-icon"/>
                      <h3 className="hover-color">Reminders</h3>
                      <p>
                        Receive a notification when you forget to start the
                        timer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <BiBookAlt className="feature-icon" />
                      <h3 className="hover-color">Idle Detection</h3>
                      <p>Remove time you've spent away from the computer.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <GrIntegration className="feature-icon" />
                      <h3 className="hover-color">Pomodoro</h3>
                      <p>
                        Improve focus by working in intervals, followed by brief
                        breaks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <SiPivotaltracker className="feature-icon" />
                      <h3 className="hover-color">Integrations</h3>
                      <p>Track time in other apps, like Trello, Gmail, etc.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <IoIosFingerPrint className="feature-icon" />
                      <h3 className="hover-color">Auto Tracker</h3>
                      <p>Track time based on apps and websites you use.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <IoCalendarNumberOutline className="feature-icon" />
                      <h3 className="hover-color">Calendar</h3>
                      <p>
                        See how your day and week really looks like based on
                        tracked data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <MdAccountBalance className="feature-icon" />

                      <h3 className="hover-color">Dashboard</h3>
                      <p>
                        Visualize productivity trends and how you spend time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="" className="product-section">
        <div className="container border-bottom">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <span>TIME TRACKER</span>
                  <h2 className="pb10 heading-section-color">
                  For field services
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <BsFillPersonFill className="feature-icon" />{" "}
                      {/* Random icon */}
                      <h3 className="hover-color">Accounting</h3>
                      <p>
                        Start and stop timer as you work on different
                        activities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <MdOutlineAccountBox className="feature-icon" />
                      <h3 className="hover-color">Cross-Platform</h3>
                      <p>Track time from any device and see all data online.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <MdAccountBalance className="feature-icon" />
                      <h3 className="hover-color">Reminders</h3>
                      <p>
                        Receive a notification when you forget to start the
                        timer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <BiBookAlt className="feature-icon" />
                      <h3 className="hover-color">Idle Detection</h3>
                      <p>Remove time you've spent away from the computer.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <GrIntegration className="feature-icon" />
                      <h3 className="hover-color">Pomodoro</h3>
                      <p>
                        Improve focus by working in intervals, followed by brief
                        breaks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <SiPivotaltracker className="feature-icon" />
                      <h3 className="hover-color">Integrations</h3>
                      <p>Track time in other apps, like Trello, Gmail, etc.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <IoIosFingerPrint className="feature-icon" />
                      <h3 className="hover-color">Auto Tracker</h3>
                      <p>Track time based on apps and websites you use.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <IoCalendarNumberOutline className="feature-icon" />
                      <h3 className="hover-color">Calendar</h3>
                      <p>
                        See how your day and week really looks like based on
                        tracked data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <MdAccountBalance className="feature-icon" />

                      <h3 className="hover-color">Dashboard</h3>
                      <p>
                        Visualize productivity trends and how you spend time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    
    </>
  );
};

export default ProductMoreInd;
