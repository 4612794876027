import React from "react";
//  Import Img
import img3 from "../../assets/img/coming-soon.jpg";
import img2 from "../../assets/img/coming-soon.jpg";
import img4 from "../../assets/img/testing_image/image11.png";

import { Link } from "react-router-dom";

const StepsTimeOff = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="">
        <div className="container border-bottom pb-5">
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600"> Time Availability</h5>
                <p>
                  Track accumulated versus utilized time off by user or policy.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4 ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color fw600"> Adjustment</h5>
                <p>
                  Modify users' time off balances by adding or deducting hours.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600"> Transaction History</h5>
                <p>A comprehensive record of all balance adjustments.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4 ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img4} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color fw600">
                  {" "}
                  Mobile Leave Management
                </h5>
                <p>
                  Submit time off requests and view holidays through mobile
                  access.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepsTimeOff;
