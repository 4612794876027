import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import MoreFeaturesApproval from '../component/FeaturesApproval/MoreFeaturesApproval'             
import FirstApproval from '../component/FeaturesApproval/FirstApproval'             
import TwoColumnApproval from '../component/FeaturesApproval/TwoColumnApproval'             
import ReminderApproval from '../component/FeaturesApproval/ReminderApproval'             
import ReportsApproval from '../component/FeaturesApproval/ReportsApproval'             

const FeaturesApprovalPage = () => {
    return (
        <>
            <Header/>
            <FirstApproval/>
            <TwoColumnApproval/>
            <ReminderApproval/>
            <ReportsApproval/>
            <MoreFeaturesApproval/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default FeaturesApprovalPage;
