import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import logos from '../assets/img/logo.png'
import {HiMenuAlt3} from "react-icons/hi";
import {AiOutlineClose} from "react-icons/ai";
import {MenuData} from './MenuData'
import NavItem from './NavItems'
import {FiChevronDown} from "react-icons/fi";

// image
import megamenu1 from '../assets/img/mega-menu/timekeeping.svg'
import megamenu2 from '../assets/img/mega-menu/reporting.svg'
import megamenu3 from '../assets/img/mega-menu/planning.svg'
import megamenu4 from '../assets/img/mega-menu/budgeting.svg'
import megamenu5 from '../assets/img/mega-menu/attendance.svg'
import megamenu6 from '../assets/img/mega-menu/payroll.svg'
import megamenu7 from '../assets/img/mega-menu/freelancers.svg'
import megamenu8 from '../assets/img/mega-menu/agencies.svg'
import megamenu9 from '../assets/img/mega-menu/lawyers.svg'
import megamenu10 from '../assets/img/mega-menu/startups.svg'
import megamenu11 from '../assets/img/mega-menu/developers.svg'
import megamenu12 from '../assets/img/mega-menu/consultants.svg'
import megamenu13 from '../assets/img/mega-menu/accountants.svg'
import megamenu14 from '../assets/img/mega-menu/construction.svg'
import megamenu15 from '../assets/img/mega-menu/plan-free.svg'
import megamenu16 from '../assets/img/mega-menu/plan-pro.svg'
import megamenu17 from '../assets/img/mega-menu/plan-enterprise.svg'
import megamenu18 from '../assets/img/mega-menu/plan-compare.svg'
import megamenu19 from '../assets/img/mega-menu/contact-us.svg'
import megamenu20 from '../assets/img/mega-menu/tutorials.svg'
import megamenu21 from '../assets/img/mega-menu/knowledge-hub.svg'
import megamenu22 from '../assets/img/mega-menu/blog.svg'
import megamenu23 from '../assets/img/mega-menu/help.svg'
import megamenu24 from '../assets/img/mega-menu/stars.svg'

import timer from '../assets/img/mega-menu/timer.svg'
import timesheet from '../assets/img/mega-menu/timesheet.svg'
import kiosk from '../assets/img/mega-menu/kiosk.svg'
import calendar from '../assets/img/mega-menu/calendar.svg'
import autotracker from '../assets/img/mega-menu/autotracker.svg'
import reports from '../assets/img/mega-menu/reports.svg'
import rates from '../assets/img/mega-menu/rates.svg'
import progress from '../assets/img/mega-menu/progress.svg'
import activity from '../assets/img/mega-menu/activity.svg'
import location from '../assets/img/mega-menu/location.svg'
import scheduling from '../assets/img/mega-menu/scheduling.svg'
import timeoff from '../assets/img/mega-menu/timeoff.svg'
import approval from '../assets/img/mega-menu/approval.svg'
import expenses from '../assets/img/mega-menu/expenses.svg'
import invoicing from '../assets/img/mega-menu/invoicing.svg'


const Header = () => {
    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e)=>{
        const header = document.querySelector('.navbar-area');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };
    const [click, setClick] = useState(false);

    const handleClick = () => {
        if (click) {
            document.querySelector("#navbarSupportedContent").classList.remove("d-block")
        } else {
            document.querySelector("#navbarSupportedContent").classList.add("d-block")
        }
        setClick(!click);
    }

    return (
        <>
            <div className="navbar-area">
                <div className="plamb-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <Link className="navbar-brand" to="/">
                                {/* <img src={logos} className="white-logo" alt="logo"/>
                                <img src={logos} className="black-logo" alt="logo"/> */}
                                <h2><Link to="/">eClock.work</Link></h2>
                            </Link>
                            <div className="mean-menu" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    {/* {
                                        MenuData.map((item, index) => (
                                            <NavItem item={item} key={index}/>
                                        ))
                                    } */}
                                    <li className="nav-item">
                                        <Link className="nav-link" to="//">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/help">Help</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/help/news">News</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/tutorial">Tutorials</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/product_contact_helpstatus">Status</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/product_helpfeedback">Feedback</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/forum">Forum</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/product_helpguide">Guides</Link>
                                    </li>
                                </ul>
                                <div className="other-option">
                                    <Link className="btn btn-theme" to="/Contact">
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="plamb-responsive-nav">
                    <div className="container">
                        <div className="responsive-button" onClick={handleClick}>
                            {click ? <AiOutlineClose/> : <HiMenuAlt3/>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
