import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import Firstsect from '../component/Reporting/Projects/Firstsect'
import TwoColumns from '../component/Reporting/Projects/TwoColumns'
import AboutTwo from '../component/Reporting/Projects/AboutTwo'
import AboutThree from '../component/Reporting/Projects/AboutThree'

const ReportingProjects = () => {
    return (
        <>
            <Header/>
            <Firstsect />
            <TwoColumns />
            <AboutTwo />
            <AboutThree />
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ReportingProjects
