import React from 'react'
import SectionHeading from '../../Home/SectionHeading/SectionHeading' 
//  Import Img
import img2 from '../../../assets/img/main/2.png'
import img3 from '../../../assets/img/main/3.png'
import img4 from '../../../assets/img/main/4.png'
import img5 from '../../../assets/img/main/5.png'
import img18 from '../../../assets/img/main/18.png'
import img22 from '../../../assets/img/main/22.png'
import img21 from '../../../assets/img/main/21.png'
import img25 from '../../../assets/img/coming-soon.jpg'
import AboutImg from '../../../assets/img/about/1.png'
import AboutImgTow from '../../../assets/img/about/2.png'

import { Link } from 'react-router-dom';

const TwoColumns=()=> {
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="news" className="py100">
       <div className="container">
        <div className="mb60">
          <h1>What's New</h1>
          <p>Check out full list of <a href="">upcoming features</a></p>
        </div>

        {/* MonthWise News */}
       <h2 className="mt-2">April 2024</h2>
            <div className="row mb-4">
               <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img25}  />
                   </div>
               </div>
            </div>
            <div className="row mb-4">
               <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="">
                        <h5 className="mt-2"><strong>Features</strong></h5>
                        <ul>
                          <li>CAKE.com Bundle plan: Get time tracking, project management, and team communication app for a special price and save 54%</li>
                          <li>CAKE.com Bundle plan: Get time tracking.</li>
                        </ul>
                   </div>
                    <div className="">
                        <h5 className="mt-2"><strong>Fixes</strong></h5>
                        <ul>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>

                        </ul>
                   </div>
               </div>
            </div>
       </div>
   </section>

      {/* Two Columns Area End */}
    <section id="news" className="py100">
       <div className="container">
        {/* MonthWise News */}
       <h2 className="mt-2">March 2024</h2>
            <div className="row mb-4">
               <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img25}  />
                   </div>
               </div>
            </div>
            <div className="row mb-4">
               <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="">
                        <h5 className="mt-2"><strong>Features</strong></h5>
                        <ul>
                          <li>CAKE.com Bundle plan: Get time tracking, project management, and team communication app for a special price and save 54%</li>
                          <li>CAKE.com Bundle plan: Get time tracking.</li>
                        </ul>
                   </div>
                    <div className="">
                        <h5 className="mt-2"><strong>Fixes</strong></h5>
                        <ul>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>
                          <li>Date on the Tracker page shows a day behind if a timezone is set to Pacific/Auckland</li>

                        </ul>
                   </div>
               </div>
            </div>
       </div>
   </section>
      {/* Two Columns Area End */}

       
  </>

 )
}

export default TwoColumns
