import React from "react";
// import SectionHeading from '../../Home/SectionHeading/SectionHeading'
//  Import Img
import img2 from "../../assets/img/coming-soon.jpg";
import img3 from "../../assets/img/coming-soon.jpg";
import img4 from "../../assets/img/coming-soon.jpg";
import img5 from "../../assets/img/coming-soon.jpg";
import img6 from "../../assets/img/coming-soon.jpg";
import img7 from "../../assets/img/coming-soon.jpg";
import img8 from "../../assets/img/coming-soon.jpg";
import img12 from "../../assets/img/coming-soon.jpg";
import AboutImg from "../../assets/img/about/1.png";
import AboutImgTow from "../../assets/img/about/2.png";

import { Link } from "react-router-dom";

const ReportsMid = () => {
  return (
    <>
      <section id="about" className="py100">
        <div className="container">
          <div className="col-md-6 mx-auto text-center">
            <div className="logo-area-heading pb-3">
              <h2 className="heading-section-color text-centre">
                Customized Time Grouping
              </h2>
              <p className="py-3 ">
                Arrange time records according to your specific requirements,
                allowing for flexible categorization by any chosen dimension.
              </p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
                <h4 className="mt-2">Time Flow Visualization</h4>
                <p>Group time by any dimension you need.</p>
                <div className="btn-holder"></div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img7} /> */}
                <h4 className="mt-2">Time Estimation Analysis</h4>
                <p>
                  Analyze the difference between estimated and logged durations
                  to enhance accuracy and productivity strategies.
                </p>
                <div className="btn-holder"></div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img7} /> */}
                <h5 className="mt-2">Financial Breakdown</h5>
                <p>
                  View earnings, costs, and profit for each category breakdown.
                </p>
                <div className="btn-holder"></div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img7} /> */}
                <h4 className="mt-2">Costs & Profit</h4>
                <p>Earnings, costs, and profit for each breakdown.</p>
                <div className="btn-holder"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="container py100">
          <div className="col-md-6 mx-auto text-center">
            <div className=" ">
              <h2 className="heading-section-color text-centre">
                Revenue and Cost Assessment
              </h2>
              <p className="py-3 ">
                Assess revenue, costs, and overall profitability for each
                breakdown category to improve financial decision-making.
              </p>
            </div>
            <div className="mx-auto">
              {/* <img alt="" src={img3} className="my-3  py-5" /> */}
              <h4 className="heading-section-color text-centre">
                Breakdown of Financial Performance
              </h4>
              <p className="py-3 ">
                Explore detailed insights into earnings, costs, and profit
                across different breakdown categories to enhance financial
                management.
              </p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
                <h4 className="mt-2">Batch Editing</h4>
                <p>
                  Modify entries individually or in bulk to streamline
                  management tasks efficiently.
                </p>
                <div className="btn-holder"></div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img5} /> */}
                <h4 className="mt-2">Team Time Adjustment</h4>
                <p>
                  Input omitted hours for your team members' schedules as
                  needed.
                </p>
                <div className="btn-holder"></div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
                <h4 className="mt-2">Time Entry Audit</h4>
                <p>
                  Identify and correct inaccurate time entries to ensure
                  accurate records.
                </p>
                <div className="btn-holder"></div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img7} /> */}
                <h4 className="mt-2">Sync with QuickBooks</h4>
                <p>
                  Integrate tracked time seamlessly with QuickBooks for
                  streamlined financial reporting.
                </p>
                <div className="btn-holder"></div>
              </div>
            </div>
          </div>

          <div className="row py100 border-bottom">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <div className="">
                <h2 className="heading-section-color">
                  Weekly Activity Overview
                </h2>
                <p className="py-3">
                  Review team and project time summaries on a weekly basis.
                </p>
              </div>
              <div className="mx-auto">
                {/* <img
                  alt=""
                  src={require("../../assets/img/coming-soon.jpg")}
                  className="my-3 py-5"
                /> */}
                <h4 className="heading-section-color">Time-Logged Status</h4>
                <p className="py-3">
                  Identify users who have not recorded any time for monitoring
                  and management purposes.
                </p>
              </div>
            </div>
          </div>

          <div className="row py100 border-bottom">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <div className="">
                <h2 className="heading-section-color">Report Sharing</h2>
                <p className="py-3">
                  Filter and distribute reports with others for collaborative
                  analysis and decision-making.
                </p>
              </div>
              <div className="row mb-4">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="two-columns-holder">
                    {/* <img alt="" src={img3} /> */}
                    <h4 className="mt-2">Data Export Options</h4>
                    <p>
                      Export all data in CSV, Excel, and PDF formats for
                      comprehensive analysis and storage.
                    </p>
                    <div className="btn-holder"></div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="two-columns-holder">
                    {/* <img alt="" src={img7} /> */}
                    <h4 className="mt-2">Public Report Link</h4>
                    <p>
                      Create a customizable link to share a live report publicly
                      for easy access and collaboration.
                    </p>
                    <div className="btn-holder"></div>
                  </div>
                </div>
              </div>
              <div className="mx-auto">
                {/* <img
                  alt=""
                  src={require("../../assets/img/coming-soon.jpg")}
                  className="my-3 py-5"
                /> */}
                <h4 className="heading-section-color">
                  Automated Email Reports
                </h4>
                <p className="py-3">
                  Set up scheduled delivery of customized reports daily, weekly,
                  or monthly via email.
                </p>
              </div>
            </div>
          </div>

          <div className="row py100 border-bottom">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <div className="">
                <h2 className="heading-section-color">Expenditure Overview</h2>
                <p className="py-3">
                  View and download comprehensive details of all expenses and
                  associated receipts.
                </p>
              </div>
              <div className="mx-auto">
                {/* <img
                  alt=""
                  src={require("../../assets/img/coming-soon.jpg")}
                  className="my-3 py-5"
                /> */}
              </div>
            </div>
          </div>

          <div className="row py100 border-bottom">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <div className="">
                <h2 className="heading-section-color">Task Report</h2>
                <p className="py-3">
                  Compare scheduled versus recorded time for assignments.
                </p>
              </div>
              <div className="mx-auto">
                {/* <img
                  alt=""
                  src={require("../../assets/img/coming-soon.jpg")}
                  className="my-3 py-5"
                /> */}
              </div>
            </div>
          </div>

          <div className="row py100 border-bottom">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <div className="">
                <h2 className="heading-section-color">Attendance Overview</h2>
                <p className="py-3">
                  View daily hours, breaks, overtime, and leave time in a
                  comprehensive report.
                </p>
              </div>
              <div className="mx-auto">
                {/* <img
                  alt=""
                  src={require("../../assets/img/coming-soon.jpg")}
                  className="my-3 py-5"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportsMid;
