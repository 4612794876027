import img1 from '../../../assets/img/work-process/1.png'
import img2 from '../../../assets/img/work-process/2.png'
import img3 from '../../../assets/img/work-process/3.png'

export const WorkProcessData =[
{
 img:img1,
 heading:"Collect Business Data",
 para:"Lorem ipsum dolor sit amet, consect adipiscing elit. Nulla neque, maximus ut accumsan ut, posuere sit ipsum.",
 button:"Read More..."
},
{
 img:img2,
 heading:"Research & Data Analysis",
 para:"Lorem ipsum dolor sit amet, consect adipiscing elit. Nulla neque, maximus ut accumsan ut, posuere sit ipsum.",
 button:"Read More..."
},
{
 img:img3,
 heading:"Maximize Business Profits",
 para:"Lorem ipsum dolor sit amet, consect adipiscing elit. Nulla neque, maximus ut accumsan ut, posuere sit ipsum.",
 button:"Read More..."
}
]
