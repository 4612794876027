import React from "react";
import { Link } from "react-router-dom";

const ReminderApproval = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py-5">
        <div className="container pb-3">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 heading-section-color">
                  Notifications Reminders{" "}
                </h2>
                <p>Avoid delays in timesheet submissions.</p>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image03.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Time Submission Reminders</h5>
                  <p className="mt-2">
                    Prompt individuals to submit their logged time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Pending Approval Notification</h5>
                  <p className="mt-2">
                    Notify managers to review pending requests.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </section>
      {/* Two Columns Area End */}
    </>
  );
};

export default ReminderApproval;
