import React from 'react';
import Banner from '../component/Home/Banner'; // Corrected import path
import Partner from '../component/Home/Partner/Partner'; // Corrected import path
import VideoComponent from '../component/Home/Video/Video'; // Corrected import path
import Header from '../layout/Header'; // Corrected import path
import Footer from '../layout/Footer'; // Corrected import path
import CtaNew from '../layout/CtaNew'; // Corrected import path
import CopyRight from '../layout/CopyRight'; // Corrected import path
import ProductConstruction from '../component/ProductConstruction/ProductConstruction'; // Corrected import path
import ProConsVideo from '../component/ProductConstruction/ProConsVideo'; // Corrected import path
import ProConsStep from '../component/ProductConstruction/ProConsStep'; // Corrected import path


const ProductConstructionPage = () => {
    return (
        <>
            <Header/>
            <Banner/>
            {/* <Partner/>  */}
            <VideoComponent/>  
            <ProductConstruction/>
            <ProConsVideo/>
            <ProConsStep/>
           
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ProductConstructionPage
