import React from 'react'
import Header from '../layout/Header'
import CommonBanner from '../component/Common/Banner'
import SingleProduct from '../component/SingleProduct'
import Footer from '../layout/Footer'
import CopyRight from '../layout/CopyRight'

const ProductDetails = () => {
    return (
        <>
            <Header/>
            <CommonBanner heading="Product Details" menu1="Home" menu2="Product Details"/>
            <SingleProduct/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ProductDetails
 