import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import ProductIndAccountants from '../component/ProductIndAccountants/ProductIndAccountants' 
import AccountingSecVideo from '../component/ProductIndAccountants/AccountingSecVideo' 
import AccountStep from '../component/ProductIndAccountants/AccountStep'   
import TutorialVideo from '../component/ProductTutorials/TutorialVideo'   
import TutorailStep1 from '../component/ProductTutorials/TutorailStep1'   




const ProductTutorialsPage = () => {
    return (
        <>
            <Header/>
    
            <TutorialVideo/>
            <TutorailStep1/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ProductTutorialsPage
