import React, {useState} from "react";
import { Link } from 'react-router-dom';
// Import Components
import { AiOutlinePlayCircle } from 'react-icons/ai';

//  Import Img
import small from '../../assets/img/small-icon/1.png'
import small2 from '../../assets/img/small-icon/2.png'
import small3 from '../../assets/img/small-icon/3.png'
import img from "../../assets/img/home-two-testimonial/veido-test.png";

// Modal 
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/css/modal-video.min.css'



const ProductContent=()=> {
const [isOpen, setOpen] = useState(false)
return (
  <>
  {/* Product Content Start */}
    <section id="" className="product-section">
        <div className="container">
            <div className="feature-area-bg">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="section-title-center text-center pb30">
							<h3 className="pb10">Improve productivity</h3>
							<h2>
                                Track time you spend on activities, see where your time goes, and improve your time management skills.
							</h2>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Timer</h3>
									<p>Start and stop timer as you work on different activities.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Cross-Platform</h3>
									<p>Track time from any device and see all data online.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Reminders</h3>
									<p>Receive a notification when you forget to start the timer.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Idle Detection</h3>
									<p>Remove time you've spent away from the computer.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Pomodoro</h3>
									<p>Improve focus by working in intervals, followed by brief breaks.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Integrations</h3>
									<p>Track time in other apps, like Trello, Gmail, etc.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Auto Tracker</h3>
									<p>Track time based on apps and websites you use.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Calendar</h3>
									<p>See how your day and week really looks like based on tracked data.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Dashboard</h3>
									<p>Visualize productivity trends and how you spend time.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="testimonial-area" className="py100 bg-color testimonial_padding">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="testmonial-veido text-center">
							<img src={img} alt="img" />
							<div className="veido-play">
								<ModalVideo channel='youtube' api={false} autoplay isOpen={isOpen} videoId="MLpWrANjFbI" onClose={() => setOpen(false)}/>
								<a href="#!" onClick={() => setOpen(true)}><i><AiOutlinePlayCircle /></i></a>
							</div>
						</div>
					</div>
				</div>
      		</div>
        </div>
    </section>
	<section id="" className="product-section">
        <div className="container">
            <div className="feature-area-bg">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="section-title-center text-center pb30">
							<h3 className="pb10">Manage billable hours</h3>
							<h2>
								Track billable time, show clients how much you've worked, see how much money you've earned, and create invoices.
							</h2>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Hourly Rates</h3>
									<p>Define hourly rates for tasks and projects, and see earnings.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Projects</h3>
									<p>Break down project into tasks, and categorize them by client.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Reports</h3>
									<p>See breakdown of all time by custom date and dimension.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Export</h3>
									<p>Customize and export data from reports (Excel, PDF, CSV).</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Invoicing</h3>
									<p>Create invoices based on tracked billable hours.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Rounding</h3>
									<p>Round time up, down, or to nearest X minutes.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Costs & Profit</h3>
									<p>Compare what you bill client vs what you pay your team.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Estimates</h3>
									<p>Set time estimates on projects and tasks and track progress.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Expenses</h3>
									<p>Record and invoice project and member fixed-fee expenses.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Budget</h3>
									<p>Set fixed-fee budget on projects and tasks and track progress.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Alerts</h3>
									<p>Receive an email when a project is near or is about to go over budget.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Scheduling</h3>
									<p>Visualize projects on a timeline and see who can work on them.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="testimonial-area" className="py100 bg-color testimonial_padding">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="testmonial-veido text-center">
							<img src={img} alt="img" />
							<div className="veido-play">
								<ModalVideo channel='youtube' api={false} autoplay isOpen={isOpen} videoId="MLpWrANjFbI" onClose={() => setOpen(false)}/>
								<a href="#!" onClick={() => setOpen(true)}><i><AiOutlinePlayCircle /></i></a>
							</div>
						</div>
					</div>
				</div>
      		</div>
        </div>
    </section>
	<section id="" className="product-section">
        <div className="container">
            <div className="feature-area-bg">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="section-title-center text-center pb30">
							<h3 className="pb10">Track team activity</h3>
							<h2>
								Invite your team so they can track time they spend on activities, see who works on what, manage workload, and send attendance data to payroll and accounting.
							</h2>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Task Assignees</h3>
									<p>Assign team members to projects and tasks so they can track time.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Force Timer</h3>
									<p>Prevent users from manually adding or editing time entries.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Team Activity</h3>
									<p>See who currently works on what and how their day looks like.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Lock Time</h3>
									<p>Prevent users from updating and changing their past timesheets.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Schedule Reports</h3>
									<p>Receive a custom report each day, week, or month via email.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>GPS Tracking</h3>
									<p>See location of your remote team members and client sites.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Screenshots</h3>
									<p>Capture screenshot while working for future reference. </p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Kiosk</h3>
									<p>Set up a shared time eClock.Work kiosk for employees to eClock.Work in.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Time Off</h3>
									<p>Request and approve vacations, sick leaves, and holidays.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Team Calendar</h3>
									<p>See how your team's day looks like, and find gaps or overlaps.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Capacity</h3>
									<p>See who's busy, who's available, and who's overbooked.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Employee Scheduling</h3>
									<p>See who's available for work and give assignments.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="testimonial-area" className="py100 bg-color testimonial_padding">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="testmonial-veido text-center">
							<img src={img} alt="img" />
							<div className="veido-play">
								<ModalVideo channel='youtube' api={false} autoplay isOpen={isOpen} videoId="MLpWrANjFbI" onClose={() => setOpen(false)}/>
								<a href="#!" onClick={() => setOpen(true)}><i><AiOutlinePlayCircle /></i></a>
							</div>
						</div>
					</div>
				</div>
      		</div>
        </div>
    </section>
	<section id="" className="product-section">
        <div className="container">
            <div className="feature-area-bg">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="section-title-center text-center pb30">
							<h3 className="pb10">Streamline processes</h3>
							<h2>
								Measure time spent on daily activities to find opportunities for improvements, understand time spent on ad-hoc requests and workload distribution, and improve project efficiency and cost capitalization.
							</h2>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Online Timesheets</h3>
									<p>Fill your weekly timesheet in less than a minute.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Timesheet Reminders</h3>
									<p>Get an automatic reminder when you forget to fill your timesheet.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Approval</h3>
									<p>Submit and approve timesheets, and track changes.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Project Status</h3>
									<p>See project progress and improve estimates.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Manager Role</h3>
									<p>Make someone a manager so they can manage projects and teams.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Custom Fields</h3>
									<p>Add custom fields and information to time entries.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>API & Webhooks</h3>
									<p>Connect eClock.Work with other systems and sync data.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Import Data</h3>
									<p>Import projects, tasks, and historic time entries from a file.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Security & Privacy</h3>
									<p>Control who can do what, and log in with your SSO</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Reports</h3>
									<p>See breakdown of all work hours and dig into data.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Audit Log</h3>
									<p>Track all changes users make in their timesheets.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Export</h3>
									<p>Customize and export data from reports (Excel, PDF, CSV).</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="testimonial-area" className="py100 bg-color testimonial_padding">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="testmonial-veido text-center">
							<img src={img} alt="img" />
							<div className="veido-play">
								<ModalVideo channel='youtube' api={false} autoplay isOpen={isOpen} videoId="MLpWrANjFbI" onClose={() => setOpen(false)}/>
								<a href="#!" onClick={() => setOpen(true)}><i><AiOutlinePlayCircle /></i></a>
							</div>
						</div>
					</div>
				</div>
      		</div>
        </div>
    </section>
    {/* ProductContent End */} 
  </> 
 )
}

export default ProductContent
