import React from 'react'
import LoginForm from '../component/Login'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
const Login = () => {
    return (
        <>
            <Header/>
            <LoginForm/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default Login
