import React from "react";
import { Link } from "react-router-dom";
import Tilt from 'react-parallax-tilt';
import img from '../../assets/img/home5/about.png'
import { GoCheck } from 'react-icons/go';
// images
import imgabout from '../../assets/img/home4/about.png'
import img2 from '../../assets/img/home-two-data/1.png'
import svg1 from "../../assets/img/svg/settings.svg";
import svg2 from "../../assets/img/svg/testing.svg";
import svg3 from "../../assets/img/svg/template.svg";
import svg4 from "../../assets/img/svg/edit-tools.svg";
import svg5 from "../../assets/img/svg/user-experience.svg";
import svg6 from "../../assets/img/svg/responsive.svg";
import check from "../../assets/img/home-two-data/1.jpg";

const TimekeeperContent = () => {
  return (
    <>
      <section id="data-those" className="py100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="data-those-img animation-img-one">
                <img src={img} alt="img" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="data-those-texted">
                <div className="data-those-title">
                  <h2 className="color-white">
                    A Modern Timekeeping Web App
                  </h2>
                  <p className="color-white pt10 mb-5">
                    eClock.Work is a  timekeeping application that allows you to track the time spent on projects and tasks. Ideal for both personal and business use, eClock.Work ensures you manage your own or   your employees' hours in a more organized manner.
                  </p>
                  <h4 className="color-white">
                    Why Use a Timekeeping App
                  </h4>
                  <p className="color-white pt10 mb-5">
                    Benefits for Owners and Employees::
                  </p>
                  <h4 className="color-white">
                    Track time and fill timesheets online
                  </h4>
                  <p className="color-white pt10">
                    Employees can either use a timer to track their working hours in real time or manually enter their hours at the end of the day.
                  </p>
                  <h4 className="color-white">
                    Enhanced Organization
                  </h4>
                  <p className="color-white pt10">
                    Maintain a more structured approach to managing work hours.
                  </p>
                  <h4 className="color-white">
                    Detailed Reports
                  </h4>
                  <p className="color-white pt10">
                    Generate comprehensive reports to analyze time usage across various projects and tasks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-us-area-home4" className="py100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="home4-about-img">
                <Tilt>
                <img src={imgabout} alt="img" />
                </Tilt>
              </div>
            </div>
            <div className="col-lg-6  col-md-12 col-sm-12 col-12">
              <div className="who-we-contant">
                <h2>
                Flexible and Robust Reporting
                </h2>
                <p>
                Modern timekeeping apps offer extensive reporting capabilities. Online time reports enable you to monitor your employees' time management habits and process work hour information on a daily, weekly, or monthly basis.
                </p>
              </div>
            </div>
          </div>
          <div className="row  align-items-center ">
            <div className="col-lg-6  col-md-12 col-sm-12 col-12 pt60">
              <div className="data-science-text">
                <div className="left-side-title">
                  <h2 className="pt10">
                    Manage projects and tasks
                  </h2>
                </div>
                <div className="data-science-content">
                  <p>
                    Timekeeping apps let you <a href="#!" className="link-color">track time on projects.</a> Set up projects, assign tasks to employees, estimate project hours, and visually monitor work progress.
                  </p>
                  <p>
                    Go one step further and <a href="#!" className="link-color">predict project progress</a> based on estimates, tracked, and scheduled entries.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6  col-md-12 col-sm-12 col-12 pt60">
              <div className="data-since-img">
              <Tilt>
                <img src={img2} alt="img" />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-us-area-home4" className="py100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="home4-about-img">
                <Tilt>
                <img src={imgabout} alt="img" />
                </Tilt>
              </div>
            </div>
            <div className="col-lg-6  col-md-12 col-sm-12 col-12">
              <div className="who-we-contant">
                <h2>
                  Works everywhere
                </h2>
                <p>
                  Advanced timekeeping apps can track time from anywhere and offer its users FREE <a href="#!" className="link-color">desktop and mobile timekeeping apps.</a>
                </p>
                {/* <a href="/features_seeall_features" className="btn btn-theme">See all features</a> */}
                <Link to="/features_seeall_features" className="btn btn-theme">See all features</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h2>4 benefits of eClock.Work timekeeping software</h2>
              <p className="mb-5">Here is why you should start keeping record of your time in eClock.Work:</p>
              <h4>Maximize productive work hours</h4>
              <p className="mb-5">Unlike traditional timekeeping systems, eClock.Work is online timekeeping software that provides an intuitive and user-friendly interface. eClock.Work makes time tracking hassle-free so employees can track their own time-use, use work hours better, and increase focus and productivity.</p>
              <h4>Improve project profitability</h4>
              <p className="mb-5">Estimate how long a project should take and then simply let your team track time. eClock.Work calculates how much money each project made based on your billable rate, plus it lets you see estimated vs actual time tracked for each project and task.</p>
              <a href="#!" className="link-color mb-5">Start tracking project profitability</a>
              <h4>Insanely simple to use</h4>
              <p className="mb-5">The biggest problem with most timekeeping systems is their complexity, which discourages employees from tracking time. But eClock.Work is so intuitive and easy to use that anyone can start tracking time with just one click.</p>
              <h4>Use data from timekeeping software to:</h4>
              <ul className="data-check-arae">
                <li><img src={check} alt="img" />Improve the overall value of the work done by employees</li>
                <li><img src={check} alt="img" />Maximize time spent on high-value tasks</li>
                <li><img src={check} alt="img" />Minimize time spent on low-value tasks</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TimekeeperContent;
