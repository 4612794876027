import React from "react";
import img from "../../assets/img/about.png";
import img1 from "../../assets/img/1.png";
import img2 from "../../assets/img/2.png";
import img3 from "../../assets/img/3.png";

const AnalysisBlog = () => {
  return (
    <>
      <section id="about" className="py100 border-bottom">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="about-tabs ">
                <img src={img} alt="img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="about-right-side-tabs border-bottom pb-4">
                <div className="tabs-heading">
                  <h2 className="heading-section-color">
                    Guide to Pay Periods: How Many Pay Periods in a Year & How
                    to Choose the Best One
                  </h2>
                  <p>
                    Familiarize yourself with pay periods, different types of
                    pay periods, how many pay periods per year, and more....
                  </p>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-lg-6 col-12">
                  <span className="d-block py-2">APPS&TOOLS</span>
                  <h4 className="hover-color">
                    9 Best Firefox Extensions to Boost Your Workflow
                  </h4>
                  <p>
                    Check out some of the best Firefox extensions that you can
                    use for your business needs in 2023....
                  </p>
                </div>
                <div className="col-lg-6 col-12">
                  <span className="d-block py-2">FUN</span>
                  <h4 className="hover-color">
                    140 Payroll and Timesheet Memes to Brighten Your Day in 2024
                  </h4>
                  <p>
                    Spice up your payroll and timesheet reminders with some of
                    the funniest payroll and timesheet memes for 2024....
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AnalysisBlog;
