import React from 'react'
// import CommonBanner from '../component/Common/Banner'
// import OurServices from '../component/Home/OurService/OurServices'
// import GetStarted from '../component/Common/GetStarted/index'
import Counter from '../component/Common/Counter/index'
import ProductHelpGuides from '../component/ProductHelpGuides/ProductHelpGuides'
import Resources from '../component/ProductHelpGuides/Resources'
import HeaderHelp from '../layout/HeaderHelp'
import CopyRight from '../layout/CopyRight'
import Footer from '../layout/Footer'
import FreeTimeTracker from '../component/ProductHelpGuides/FreeTimeTracker'




const ProductHelpGuidesPage = () => {
    return (
        <>
            {/* <CommonBanner heading="Services" menu1="Home" menu2="Services"/> */}
            <HeaderHelp/>
            <Resources/>
            <ProductHelpGuides/>
            {/* <GetStarted/> */}
            <FreeTimeTracker/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ProductHelpGuidesPage
