import React from 'react'
import {MdPermPhoneMsg} from "react-icons/md";
import {ImLocation2} from "react-icons/im";
import {BiMailSend} from "react-icons/bi";

const ContactInfo = () => {
    return (
        <>
            <div className="contact-content">
                <h3>Contact Here</h3>
                <p>Interested in a demo or need help with time tracking? Reach out to eClock today! Employers can sign up for a demo, and employees can get assistance with our solutions. Email us at support@eclock.work or call +1 800 5701 492.</p>
                <ul className="contact-info">
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><MdPermPhoneMsg/></i></div>
                            <div className="media-body contact-info-here">
                                <h5><a href="tel:+18005701492">+1 800 5701 492</a></h5>
                                {/* <h5><a href="tel:+0-123-097-250">+0-123-456-789</a></h5> */}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><ImLocation2/></i></div>
                            <div className="media-body contact-info-here">
                                <h5>7920 Belt Line Road, Suite 720, Dallas, TX, 75245</h5>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><BiMailSend/></i></div>
                            <div className="media-body contact-info-here">
                                <h5><a href="mailto:support@eclock.work">support@eclock.work</a></h5>
                                {/* <h5><a href="mailto:admin@eclock.work">admin@eclock.work</a></h5> */}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default ContactInfo
