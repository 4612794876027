import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import FirstTimeOff from '../component/FeaturesTimeOff/FirstTimeOff'
import TwoColumnsImage from '../component/FeaturesTimeOff/TwoColumnsImage'
import ExportTimeOff from '../component/FeaturesTimeOff/ExportTimeOff'
import TimelineTimeOff from '../component/FeaturesTimeOff/TimelineTimeOff'
import StepsTimeOff from '../component/FeaturesTimeOff/StepsTimeOff'             
import ReportTimeOff from '../component/FeaturesTimeOff/ReportTimeOff'             
import MoreFeaturesTimeOff from '../component/FeaturesTimeOff/MoreFeaturesTimeOff'             

const FeaturesTimeOffPage = () => {
    return (
        <>
            <Header/>
            <FirstTimeOff/>
            <TwoColumnsImage/>
            <ExportTimeOff/>
            <TimelineTimeOff/>
            <StepsTimeOff/> 
            <ReportTimeOff/> 
            <MoreFeaturesTimeOff/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default FeaturesTimeOffPage;
