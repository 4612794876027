import React from "react";
//  Import Img
import { FaCircleArrowRight } from "react-icons/fa6";
import { GiCheckMark } from "react-icons/gi";

import { Link } from "react-router-dom";

const MoreFeaturesTimeOff = () => {
  return (
    <>
      <section id="" className="">
        <div className="container border-bottom">
          <div className="py-5">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">More features</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Authorization
                      </h3>
                      <p>
                        Assess and authorize employee work hours, time off, and
                        expenses.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Employee Profile
                      </h3>
                      <p>
                        Include extra details about employees and establish
                        their workdays.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Work Planning
                      </h3>
                      <p>Incorporate time off into scheduling tasks.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Data Breakdown
                      </h3>
                      <p>
                        Analyze and export information for payroll processing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Activity Dashboard
                      </h3>
                      <p>Monitor active users and their current tasks.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Time Off Visibility
                      </h3>
                      <p>
                        View scheduled time off while planning work schedules.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="product-section">
        <div className="container">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h2 className="pb10 ">Related</h2>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6 border-start related-wrapper py-3">
                <div className="single-feature ">
                  <div className="media">
                    <div className="media-body pl30 animation-img-two">
                      <h3 className="hover-color">
                        Work Activity Report
                        {/* <span className="iconstyle-arrow-first hover-color">
                          {" "}
                          <FaCircleArrowRight />
                        </span> */}
                      </h3>
                      <p>
                        Review details of tasks completed and their durations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 related-wrapper py-3">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30  animation-img-two">
                      <h3 className="hover-color">
                        Work Allocation{" "}
                        {/* <span className="iconstyle-arrow ">
                          {" "}
                          <FaCircleArrowRight />
                        </span> */}
                      </h3>
                      <p>Organize assignments and task distribution.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MoreFeaturesTimeOff;
