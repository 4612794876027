import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import img2 from "../../assets/img/coming-soon.jpg";
import img3 from "../../assets/img/coming-soon.jpg";
import img5 from "../../assets/img/home5/banner.png";
import { Link } from "react-router-dom";

const SecondImagessection = () => {
  return (
    <>
      <section id="project-details-area" className="py100">
        <div className="container">
          <h2 className="">Timekeeping for Startups and Product Companies</h2>
          <p>
            eClock.Work is a completely timekeeping application that allows your
            startup to track the time spent on various projects and tasks.
          </p>
          <div className="mt-5">
            <span className="step-font"></span>
            <h2 className="mb-4">Why Use a Timekeeping App</h2>
            <h3 className="step-heading">Super Simple Timesheets</h3>
            <p>
              Lawyers and attorneys can easily track time using a timer or
              manually input their hours into timesheets. Ensure accurate
              billing by logging every call, email, and meeting.
            </p>

            <div className="service-dec-img animation-img-two">
              <img alt="" src={img5} className="mt-3" />
            </div>
          </div>
          <div className="mt-5">
            <span className="step-font"></span>
            <h3 className="step-heading">Flexible Reports</h3>
            <h4 className="step-heading">Resource Utilization Analysis</h4>
            <p>
              Evaluate resource utilization across users, departments, projects,
              and dates. Identify if time is being spent excessively on
              non-profitable activities and insufficiently on profitable ones,
              enabling better delegation of work.
            </p>
            <div className="service-dec-img animation-img-two">
              {/* <img alt="" src={img2} className="mt-3"/> */}
            </div>
          </div>
          <div className="mt-5">
            <span className="step-font"></span>
            <h3 className="step-heading">Task Management</h3>
            <h4 className="step-heading">Project Setup and Task Assignment</h4>
            <p>
              Establish projects, allocate tasks to team members, estimate
              project hours, and visually track work progress
            </p>
            <div className="service-dec-img animation-img-two">
              {/* <img alt="" src={img3} className="mt-3" /> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
              <Link
                to="/features_seeall_features"
                className="btn btn-theme mt30"
              >
                SEE ALL FEATURES
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecondImagessection;
