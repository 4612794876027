import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import FirstVideo from '../component/Help/AboutUs/FirstVideo'


const HelpAboutus = () => {
    return (
        <>
            <Header/>
            <FirstVideo />
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default HelpAboutus
