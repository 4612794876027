import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import CtaNew from "../layout/CtaNew";
import CopyRight from "../layout/CopyRight";
import FirstLocation from "../component/FeatReportingLocation/FirstLocation";
import MobileTracking from "../component/FeatReportingLocation/MobileTracking";
import MoreFeaturesLocation from "../component/FeatReportingLocation/MoreFeaturesLocation";
import ReviewLocation from "../component/FeatReportingLocation/ReviewLocation";

const FeatReportingLocationPage = () => {
  return (
    <>
      <Header />
      <FirstLocation />
      <MobileTracking />
      <ReviewLocation/>
      <MoreFeaturesLocation />
      <CtaNew />
      <Footer />
      <CopyRight />
    </>
  );
};

export default FeatReportingLocationPage;
