import React from 'react'
import {AiOutlineCheckCircle} from "react-icons/ai";
import img1 from "../../assets/img/project-details/1.jpg"
import img2 from "../../assets/img/project-details/2.png"
import img3 from "../../assets/img/testing_image/1.png"
import img4 from "../../assets/img/testing_image/image03.png"


import { Link } from "react-router-dom";

const AboutSteps = () => {
    return (
        <>
            <section id="project-details-area" className="py100">
                <div className="container">
                        <div className="mt-5">
                            <span className="step-font">STEP 1</span>
                            <h3 className="step-heading">Create an account</h3>
                            <p>
                            <Link to="">
                                <span className="span-color">Sign up for free</span>
                            </Link>
                            </p>
                        </div>
                        <div className="mt-5">
                            <span className="step-font">STEP 2</span>
                            <h3 className="step-heading">Set up projects</h3>
                            <p>
                            Create projects, categorize them by clients, and define tasks. Employees select a project/task, provide a brief optional description, and start the timer.
                            </p>
                            <div className="service-dec-img animation-img-two">
                                <img alt="" src={img1}  className="mt-3"/>
                            </div>
                        </div>
                        <div className="mt-5">
                            <span className="step-font">STEP 3</span>
                            <h3 className="step-heading">Track Time</h3>
                            <p>
                            Enjoy unlimited free users, including developers, designers, managers, and clients.
                            </p>
                            <div className="service-dec-img animation-img-two">
                                <img alt="" src={img1}  className="mt-3"/>
                            </div>
                        </div>
                        <div className="mt-5">
                                <span className="step-font">STEP 4</span>
                                <h3 className="step-heading">Install desktop app</h3>
                                <p>
                                Track time in the browser or via desktop and mobile apps (iOS, Android, Mac, Windows, Linux). The desktop app includes features like reminders, idle detection, and more.
                                </p>
                            <div className="service-dec-img animation-img-two">
                                <img
                                    alt=""
                                    src={img3} className="mt-3"
                                />
                            </div>
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 5</span>
                                <h3 className="step-heading">Set hourly Rates</h3>
                                <p>
                                Your team can use a timer or manually enter their time through desktop and mobile apps
                                </p>
                        </div>

                        <div className="mt-5">
                                <span className="step-font">STEP 6</span>
                                <h3 className="step-heading">Export reports</h3>
                                <p>
                                Generate detailed reports, leveraging the time tracked through desktop and mobile apps with features such as reminders and idle detection.
                                </p>
                            <div className="service-dec-img animation-img-two">
                                <img
                                    alt=""
                                    src={img4} className="mt-3"
                                />
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

export default AboutSteps
