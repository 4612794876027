import React from 'react'
import Banner from '../component/Home/Banner'
import Partner from '../component/Home/Partner/Partner'
import VideoComponent from '../component/Home/Video/Video'
// import AboutOne from '../component/Home/About/AboutOne'
// import AboutTwo from '../component/Home/About/AboutTwo'
// import AboutThree from '../component/Home/About/AboutThree'
// import TwoColumns from '../component/Home/TwoColumns/TwoColumns'
// import WhyWithEclock from '../component/Home/WhyWithEclock/WhyWithEclock' 
// import CustomerSupport from '../component/Home/CustomerSupport/CustomerSupport' 
// import OurServices from '../component/Home/OurService/OurServices'
// import Project from '../component/Home/Project/Project'
// import Pricing from '../component/Home/Pricing/Pricing'
// import Testimonials from '../component/Common/Testimonials'
// import Blog from '../component/Home/Blog/Blog'
// import WorkingProcess from '../component/Home/OurWorkingProcess/WorkingProcess'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import ProductIndAccountants from '../component/ProductIndAccountants/ProductIndAccountants' 
import AccountingSecVideo from '../component/ProductIndAccountants/AccountingSecVideo' 
import AccountStep from '../component/ProductIndAccountants/AccountStep' 




const ProductIndAccountantsPage = () => {
    return (
        <>
            <Header/>
            <Banner/>
            {/* <Partner/>  */}
            <VideoComponent/>
            {/* <OurServices/> */}
            {/* <AboutOne/> */}
            <ProductIndAccountants/>
            <AccountingSecVideo className="bg-color"/>
            <AccountStep/>
            {/* <AboutTwo className="bg-color" /> */}
            {/* <AboutThree/> */}
            {/* <TwoColumns/> */}
            {/* <WhyWithEclock/> */}
            {/* <CustomerSupport/> */}
            {/* <Testimonials className="bg-color" /> */}
            {/* <WorkingProcess/>
            <Project/>
            <Pricing/>
            <Blog/> */}
            {/* <Partner/>  */}
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ProductIndAccountantsPage
