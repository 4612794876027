import React from "react";

import { Link } from "react-router-dom";

const TwoColumnInvoicing = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Invoice Details</h5>
                  <p className="mt-2">
                    Specify all necessary information and include optional
                    notes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Add/Edit Items</h5>
                  <p className="mt-2">Manually input and modify items.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image10.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Apply Discounts and Taxes</h5>
                  <p className="mt-2">
                    Include discounts and taxes in your invoices for accurate
                    billing.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image03.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Calculate Totals</h5>
                  <p className="mt-2">
                    Obtain amounts calculated from price and quantity.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4 animation-img-two bg-color">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image07.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div class="blue-box-text">
                    <h3 className="text-left">Import Data</h3>

                    <ul className="custom-list li text-left marginleft-list">
                      <li>
                        Import Billable Hours and Expenses:
                        <p>
                          Upload billable hours and expenses for seamless
                          invoicing.
                        </p>
                      </li>
                      <li>
                        Grouping Options:
                        <p>Organize items according to your preferences.</p>
                      </li>
                      <li>
                        Customize Details:
                        <p>
                          Select specific information to include in your
                          imports.
                        </p>{" "}
                      </li>
                      <li>
                        Time Rounding:
                        <p>
                          Adjust times to the nearest minute for precision.
                        </p>{" "}
                      </li>
                      <li>
                        Automatic Invoicing Tag:
                        <p>
                          Automatically tag imported data as billable for
                          invoicing.
                        </p>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
      {/* Two Columns Area End */}
    </>
  );
};

export default TwoColumnInvoicing;
