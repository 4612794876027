import img1 from '../../../assets/img/testimonial/1.png'
import img2 from '../../../assets/img/testimonial/2.png'

export const TestData = [
    {
        para: "We grew from a couple people to almost 80 and it worked perfectly the whole time!",
        img: img1,
        name: "Korona Dos",
        des: "CEO, ABC Company"
    },
    {
        para: "Makes it incredibly easy to record time and attach pdf reports to invoices.",
        img: img2,
        name: "Alom Sha",
        des: "CEO, CDR Company"
    },
    {
        para: "I use eClock for work, but it also lets me know exactly what I am doing in my day!",
        img: img1,
        name: "Korn Sos",
        des: "Manager, HL Company"
    },
    {
        para: "Easy to use. Quick to maneuver. Excellent reporting and administration.",
        img: img2,
        name: "Hero Alome",
        des: "CEO, OPL Company"
    },
    {
        para: "The best app out there which allows us to keep a tab on our productivity.",
        img: img1,
        name: "Gas Der",
        des: "CEO, LP Company"
    },
    {
        para: "It is a powerful and often frightening insight into your own behavior.",
        img: img2,
        name: "Busondhara Mal",
        des: "CEO, MAL Company"
    },
]