import React from "react";
import Header from "../layout/Header";

import Footer from "../layout/Footer";
import CopyRight from "../layout/CopyRight";
import KnowledgeHeading from "../component/ProductKnowledge/KnowledgeHeading";
import FreeTimeTracker from '../component/ProductHelpGuides/FreeTimeTracker'



const ProductKnowledgePage = () => {
  return (
    <>
      <Header />
      <KnowledgeHeading/>
      <FreeTimeTracker/>
      <Footer />
      <CopyRight />
    </>
  );
};

export default ProductKnowledgePage;
