export const FaqsData = [
    {
        'tab_name': "PAYMENT",
        'data': [
            {
                'heading': "What is the best features and services of eclock?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Science?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "Can i have multiple activities in single feature?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Analytics?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            }
        ]
    },
    {
        'tab_name': "DELIVERY",
        'data': [
            {
                'heading': "What is the best features and services of eclock?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Science?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "Can i have multiple activities in single feature?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Analytics?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            }
        ]
    },
    {
        'tab_name': "ORDERS",
        'data': [
            {
                'heading': "What is the best features and services of eclock?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Science?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "Can i have multiple activities in single feature?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Analytics?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            }
        ]
    },
    {
        'tab_name': "PRODUCT & STOCK",
        'data': [
            {
                'heading': "What is the best features and services of eclock?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Science?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "Can i have multiple activities in single feature?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Analytics?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            }
        ]
    },
    {
        'tab_name': "REFUNDS",
        'data': [
            {
                'heading': "What is the best features and services of eclock?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Science?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "Can i have multiple activities in single feature?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Analytics?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            }
        ]
    },
    {
        'tab_name': "ACCOUNT",
        'data': [
            {
                'heading': "What is the best features and services of eclock?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Science?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "Can i have multiple activities in single feature?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            },
            {
                'heading': "What is the benefit of Data Analytics?",
                'para': `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
     omnis quaerat nostrum, pariatur ipsam sunt accusams enim
     necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
     cupiditate ipsum, dolorum voluptatum esse error?`

            }
        ]
    }
]