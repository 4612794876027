import React from 'react'
import {MdPermPhoneMsg} from "react-icons/md";
import {ImLocation2} from "react-icons/im";
import {BiMailSend} from "react-icons/bi";
import { BsArrowRightCircle } from "react-icons/bs";

const AboutArea = () => {
    return (
        <>
            <div className="container mt-4">
    <div className="contact-content">
        <h3>Featured Articles</h3>
        <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                <ul className="contact-info">
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><BsArrowRightCircle /></i></div>
                            <div className="media-body contact-info-here">
                                <h5><a href="3">Custom Fields</a></h5>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><BsArrowRightCircle /></i></div>
                            <div className="media-body contact-info-here">
                                <h5><a href="3">Custom Fields</a></h5>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><BsArrowRightCircle /></i></div>
                            <div className="media-body contact-info-here">
                                <h5><a href="3">Custom Fields</a></h5>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                <ul className="contact-info">
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><BsArrowRightCircle /></i></div>
                            <div className="media-body contact-info-here">
                                <h5><a href="3">Time Off</a></h5>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><BsArrowRightCircle /></i></div>
                            <div className="media-body contact-info-here">
                                <h5><a href="3">Time Off</a></h5>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><BsArrowRightCircle /></i></div>
                            <div className="media-body contact-info-here">
                                <h5><a href="3">Time Off</a></h5>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                <ul className="contact-info">
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><BsArrowRightCircle /></i></div>
                            <div className="media-body contact-info-here">
                                <h5><a href="3">Import Client & Project</a></h5>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><BsArrowRightCircle /></i></div>
                            <div className="media-body contact-info-here">
                                <h5><a href="3">Import Client & Project</a></h5>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <div className="icon-area"><i><BsArrowRightCircle /></i></div>
                            <div className="media-body contact-info-here">
                                <h5><a href="3">Import Client & Project</a></h5>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

        </>
    )
}

export default AboutArea
