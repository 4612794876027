import React from 'react'
// import CommonBanner from '../component/Common/Banner'
import ContactDetails from '../component/ContactUs'
import Footer from '../layout/Footer'
import Header from '../layout/Header'
import Tracker from '../../src/component/TimeTracker/tracker'


const TimeTracker = () => {
    return (
        <>
            <Header />
            <Tracker/>
            {/* <CommonBanner heading="Contact" menu1="Home" menu2="Contact"/> */}
            <Footer />
        </>
    )
}

export default TimeTracker
