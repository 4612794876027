import React from 'react'
import SectionHeading from '../../Home/SectionHeading/SectionHeading' 
//  Import Img
import img2 from '../../../assets/img/coming-soon.jpg'
import img3 from '../../../assets/img/coming-soon.jpg'
import img4 from '../../../assets/img/coming-soon.jpg'
import img5 from '../../../assets/img/coming-soon.jpg'
import img6 from '../../../assets/img/coming-soon.jpg'
import img7 from '../../../assets/img/coming-soon.jpg'
import img8 from '../../../assets/img/coming-soon.jpg'
import AboutImg from '../../../assets/img/about/1.png'
import AboutImgTow from '../../../assets/img/about/2.png'

import { Link } from 'react-router-dom';

const TwoColumns=()=> {
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="about" className="py100">
       <div className="container">
            <SectionHeading title="Time tracking apps" heading={"eClock.Work operates on multiple devices, allowing you to track time from any location with all data synchronized online."}/>
            <div className="row mb-5">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img2}  /> */}
                        <h5 className="mt-2">Desktop app</h5>
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Windows</Link>
                            <Link to="/#" className="tag-btn">Mac</Link>
                            <Link to="/#" className="tag-btn">Linux</Link>
                        </div>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img3}  /> */}
                        <h5 className="mt-2">Web app</h5>
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Edge</Link>
                            <Link to="/#" className="tag-btn">Chrome</Link>
                            <Link to="/#" className="tag-btn">Firefox</Link>
                        </div>
                   </div>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img4}  /> */}
                        <h5 className="mt-2">Mobile app</h5>
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Android</Link>
                            <Link to="/#" className="tag-btn">iOS</Link>
                        </div>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img5}  /> */}
                        <h5 className="mt-2">Kiosk app</h5>
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Any device</Link>
                        </div>
                   </div>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img6}  /> */}
                        <h5 className="mt-2">Mobile app</h5>
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Android</Link>
                            <Link to="/#" className="tag-btn">iOS</Link>
                        </div>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img7}  /> */}
                        <h5 className="mt-2">Kiosk app</h5>
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Any device</Link>
                        </div>
                   </div>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img8}  /> */}
                        <h5 className="mt-2">Mobile app</h5>
                        <div className="btn-holder">
                            <Link to="/#" className="tag-btn">Android</Link>
                            <Link to="/#" className="tag-btn">iOS</Link>
                        </div>
                   </div>
               </div>
               
            </div>
       </div>
   </section>
      {/* Two Columns Area End */}
  </>

 )
}

export default TwoColumns
