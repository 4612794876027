import React from 'react'
import ContactInfo from '../ContactUs/ContactInfo'
import ContactForm from '../ContactUs/Contactform'
const ContactDetails = () => {
    return (
        <>
            <section id="contact">
                <div className="container">
                    <div className="padding-areas">
                        <div className="row">
                            {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <ContactInfo/>
                            </div> */}
                            <div className="col-lg-12  col-md-12 col-sm-12 col-12">
                                <ContactForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactDetails
