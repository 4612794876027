import React from "react";
//  Import Img
import { FaCircleArrowRight } from "react-icons/fa6";
import { GiCheckMark } from "react-icons/gi";

import { Link } from "react-router-dom";

const MoreFeaturesInvoicing = () => {
  return (
    <>
      <section id="" className="">
        <div className="container border-bottom">
          <div className="py-5">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">More features</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Timesheet Approval
                      </h3>
                      <p>Send timesheets to managers for their approval.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Project Timeline
                      </h3>
                      <p>Organize projects and tasks chronologically.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Integration with QuickBooks
                      </h3>
                      <p>
                        Transfer tracked time data to QuickBooks for payroll and
                        accounting purposes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        QuickBooks integration
                      </h3>
                      <p>
                        Customize and export data from reports (Excel, PDF,
                        CSV).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Profit Analysis
                      </h3>
                      <p>Evaluate client charges versus labor expenses.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Hourly Rates
                      </h3>
                      <p>
                        Establish rates per hour for users, projects, or tasks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="product-section">
        <div className="container">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h2 className="pb10 ">Related</h2>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6 border-start related-wrapper py-3">
                <div className="single-feature ">
                  <div className="media">
                    <div className="media-body pl30 animation-img-two">
                      <h3 className="hover-color">
                        Costs
                        {/* <span className="iconstyle-arrow-first hover-color">
                          {" "}
                          <FaCircleArrowRight />
                        </span> */}
                      </h3>
                      <p>Track project-related expenditures and fees.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 related-wrapper py-3">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30  animation-img-two">
                      <h3 className="hover-color">
                        Analytics{" "}
                        {/* <span className="iconstyle-arrow ">
                          {" "}
                          <FaCircleArrowRight />
                        </span> */}
                      </h3>
                      <p>
                        Examine time distributions across projects and clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MoreFeaturesInvoicing;
