import React from 'react'
import { Link } from 'react-router-dom';
import { FaPeopleGroup } from "react-icons/fa6";
const CtaNew = () => {
    return (
        <>
            <section id="cta-area" className="py100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="news-letter-text pl40 pr40 responsive-mt-30">
                                <div className="cta-text">
                                    <h2 className="white-color">Start tracking time with eClock.Work</h2>
                                    <ul>
                                        <li>24/7 Support</li>
                                        <li className="separate">/</li>
                                        <li>Cancel Anytime</li>
                                        <li className="separate">/</li>
                                        <li>Free Forever</li>
                                    </ul>
                                    <Link to="/register" className="btn btn-theme mt30 btn-cta">Create FREE account</Link>
                                    <p className="mt10 white-color"><i><FaPeopleGroup/></i>1,14,677 people signed up last month</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CtaNew