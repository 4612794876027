import React from "react";
import img1 from "../../assets/img/project-details/2.png";
import { Link } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";

const ProductHelpGuides = () => {
  return (
    <>
      <section id="service-dec" className="py100 ">
        <div className="container border-bottom">
          <div className="row pb-5">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="service-dec-img animation-img-two">
                <img alt="" src={img1} />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="service-dec-right-side">
                <div className="left-side-title">
                  <h5>WHAT YOU'LL LEARN</h5>
                  <p>
                    Get to know the basic eClock.Work functionalities and learn how
                    to get started.
                  </p>
                </div>
                <div className="service-dec-left-text">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Sapiente eaque atque earum magnam ratione obcaecati.
                    Voluptatum magni, veritatis minima autem omnis suscipit
                    cumque, aperiam necessitatibus, aliquid qui nisi voluptatem
                    est.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="service-dec-right-side">
                <div className="left-side-title">
                  <h5>2 RESOURCES</h5>
                  <div className="service-img-text">
                    <ul>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Hadoop and other Big data platforms
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Licensed OCR tool
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Luigi workflow manager
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Distributed setup on Cloud for speed
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Django framework
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="service-dec" className="">
        <div className="container border-bottom">
          <div className="row pb-5">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="service-dec-img animation-img-two">
                <img
                  alt=""
                  src={require("../../assets/img/testing_image/image09.png")}
                  className="mt-3 pb-5"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="service-dec-right-side">
                <div className="left-side-title">
                  <h5>WHAT YOU'LL LEARN</h5>
                  <p>
                    Get to know the basic eClock.Work functionalities and learn how
                    to get started.
                  </p>
                </div>
                <div className="service-dec-left-text">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Sapiente eaque atque earum magnam ratione obcaecati.
                    
                    est.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="service-dec-right-side">
                <div className="left-side-title">
                  <h5>2 RESOURCES</h5>
                  <div className="service-img-text">
                    <ul>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Hadoop and other Big data platforms
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Licensed OCR tool
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Luigi workflow manager
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Distributed setup on Cloud for speed
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Django framework
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="service-dec" className="">
        <div className="container border-bottom">
          <div className="row py-5">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="service-dec-img animation-img-two">
                <img
                  alt=""
                  src={require("../../assets/img/testing_image/image10.png")}
                  className="mt-3 pb-5"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="service-dec-right-side">
                <div className="left-side-title">
                  <h5>WHAT YOU'LL LEARN</h5>
                  <p>
                    Get to know the basic eClock.Work functionalities and learn how
                    to get started.
                  </p>
                </div>
                <div className="service-dec-left-text">
                  <p>
                    
                    Sapiente eaque atque earum magnam ratione obcaecati.
                    Voluptatum magni, veritatis minima autem omnis suscipit
                    cumque, aperiam necessitatibus, aliquid qui nisi voluptatem
                    est.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="service-dec-right-side">
                <div className="left-side-title">
                  <h5>2 RESOURCES</h5>
                  <div className="service-img-text">
                    <ul>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Hadoop and other Big data platforms
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Licensed OCR tool
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Luigi workflow manager
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Distributed setup on Cloud for speed
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Django framework
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="service-dec" className="">
        <div className="container ">
          <div className="row py-5">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="service-dec-img animation-img-two">
                <img
                  alt=""
                  src={require("../../assets/img/testing_image/image01.png")}
                  className="mt-3 pb-5"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="service-dec-right-side">
                <div className="left-side-title">
                  <h5>WHAT YOU'LL LEARN</h5>
                  <p>
                    Get to know the basic eClock.Work functionalities and learn how
                    to get started.
                  </p>
                </div>
                <div className="service-dec-left-text">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    est.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="service-dec-right-side">
                <div className="left-side-title">
                  <h5>2 RESOURCES</h5>
                  <div className="service-img-text">
                    <ul>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Hadoop and other Big data platforms
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Licensed OCR tool
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Luigi workflow manager
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Distributed setup on Cloud for speed
                      </li>
                      <li>
                        <i>
                          <BsCheckCircle />
                        </i>
                        Django framework
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductHelpGuides;
