import React from "react";
//  Import Img
import img3 from "../../assets/img/coming-soon.jpg";
import img2 from "../../assets/img/coming-soon.jpg";

import { Link } from "react-router-dom";

const TwoColumns = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="">
        <div className="container border-bottom pb-5">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
            <div className="text-center">
              {" "}
              {/* Added text-center class to center the text horizontally */}
              <h2>Mobile app</h2>
              <p>
                Give employees personal logins so they can eClock.Work in from their
                own phone.
              </p>
              <div className="service-dec-img animation-img-two">
                <a href="#" className="image-zoom">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/android.png")}
                    className="mt-3 pb-5 px-2"
                  />
                </a>
                <a href="#" className="image-zoom">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/ios.png")}
                    className="mt-3 pb-5 px-2"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600">
                  {" "}
                  Choose task
                </h5>
                <p>Add more details about what you're working on.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color fw600"> Track location</h5>
                <p>Record your GPS location when you eClock.Work in.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TwoColumns;
