import React from "react";
// import SectionHeading from '../SectionHeading/SectionHeading'
//  Import Img
// import check from '../../../assets/img/about/tick.png'
// import AboutImg from '../../../assets/img/about/1.png'
import { Link } from "react-router-dom";

const ToollManage = () => {
  return (
    <>
      {/* About One Area Start */}
      <section className="">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center ">
              <div className="left-side-title text-center">
                <h3>One tool to manage projects from start to finish</h3>
                <p className="pt10">
                  Plaky is a free project and task management software that lets
                  you create to-dos and collaborate with teammates. Plus, you
                  can track time on Plaky tasks using eClock.Work.
                </p>
                <p>
                  <Link to="#" className="heading-section-color">
                    Explore Plaky →
                  </Link>
                </p>
                <div className="mx-auto">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image02.jpg")}
                    className="my-3  mb-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About One Area End */}
    </>
  );
};

export default ToollManage;
