import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import DisclaimerContent from '../component/FooterPages/DisclaimerContent'


const Disclaimer = () => {
    return (
        <>
            <Header/>
            <DisclaimerContent />
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default Disclaimer
