import React from 'react'
import {AiOutlineCheckCircle} from "react-icons/ai";
import img1 from "../../assets/img/testing_image/1.png"
import img2 from "../../assets/img/coming-soon.jpg"
import img3 from "../../assets/img/coming-soon.jpg"
import { Link } from "react-router-dom";

const SecondImagessection = () => {
    return (
        <>
            <section id="project-details-area" className="">
                <div className="container">
                <h2 className="">What is attendance tracker app</h2>
                <p className='mb-2' >An attendance tracker app simplifies team management for employers by documenting employee work hours, eliminating tedious paperwork, and enhancing long-term team productivity.</p>
                <p className='mb-2' >A proficient employee attendance tracker typically includes:</p>
                    <li>Clock-in and clock-out system</li>
                    <li>Timesheet auto-fill functionality</li>
                    <li>Payroll and budget tracking features</li>                            
                    <li>Billable hours tracking capabilities</li>                            
                    <li>PTO (Paid Time Off) management system</li>                            
                </div>
            </section>
        </>
    )
}

export default SecondImagessection
