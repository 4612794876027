import React, { useState } from "react";
import img1 from "../../assets/img/coming-soon.jpg";
import { Link } from "react-router-dom";
import { FaArrowAltCircleRight } from "react-icons/fa";

// Modal

const Reportkisok = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className=" border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Attendance report</h2>
                <p className="pb-4">
                  See daily work hours, breaks, overtime, and time off in one
                  report.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="service-dec-img animation-img-two">
              <img
                alt=""
                src={require("../../assets/img/testing_image/image08.png")}
                className="mt-3 pb-5"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="pt-5">
        <div className="container border-bottom">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Dashboard</h2>
                <p className="pb-4">
                See who’s currently eClock.Work in and their activity.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="service-dec-img animation-img-two">
              <img
                alt=""
                src={require("../../assets/img/testing_image/image02.jpg")}
                className="mt-3 pb-5"
              />
            </div>
          </div>
        </div>
      </section>
      
     
     
    </>
  );
};

export default Reportkisok;
