import React from 'react'
import HeaderHelp from '../layout/HeaderHelp'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import ProductFeedback from '../component/ProductFeedback/ProductFeedback' 




const ProductFeedbackPage = () => {
    return (
        <>
            <HeaderHelp/>
            <ProductFeedback/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ProductFeedbackPage;
