import React from "react";
import { Link } from "react-router-dom";
import { FaPeopleGroup } from "react-icons/fa6";
import img from "../../assets/img/testing_image/star.svg";

const ImproveBusiness = () => {
  return (
    <>
      <section id="rating-area" className="py50 bg-color">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="news-letter-text pl40 pr40 responsive-mt-30">
                <div className="cta-text">
                  <h2 className="black-color heading-section-color fsSize">
                    Ready to improve your business?
                  </h2>
                  <span className="d-inline-block my-2 mx-auto rating-color">
                    Streamline processes &nbsp;&nbsp;•&nbsp;&nbsp;Improve profit
                    &nbsp;&nbsp;•&nbsp;&nbsp;Manage teams
                  </span>

                  <div className=" d-flex justify-content-center">
                    {" "}
                    <Link to="https://eclockdashboard.vercel.app/login" target="blank" className="btn btn-theme pt30 mt-4">UPGRADE NOW</Link>
                  </div>
                  <div className=" d-flex justify-content-center">
                    {" "}
                    <Link to="https://eclockdashboard.vercel.app/login" target="blank" className="pt30 span-color">OR START FREE TRIAL</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImproveBusiness;
