import React from 'react'
import {AiOutlineCheckCircle} from "react-icons/ai";
import img1 from "../../assets/img/coming-soon.jpg"
import img2 from "../../assets/img/coming-soon.jpg"
import img3 from "../../assets/img/coming-soon.jpg"
import { Link } from "react-router-dom";

const SecondImagessection = () => {
    return (
        <>
            <section id="project-details-area" className="py100">
                <div className="container">
                <h2 className="">Why Use eClock.Work for Time Tracking</h2>
                        <ol>
                            <li>Track time using a timer or enter time in an online timesheet</li>
                            <li>Exceptionally simple and user-friendly (accessible via web, Mac, Windows, Android, and iPhone apps)</li>
                            <li>Automatically calculates billable hours based on your hourly rates (eliminating the need for Excel spreadsheet calculations)</li>                            
                        </ol>
                    <div className="mt-5">
                        <span className="step-font"></span>
                        <h2 className="mb-4">Time Tracking for Attorneys and Lawyers</h2>
                        <h3 className="step-heading">Track Billable and Non-Billable Time</h3>
                        <p>
                        Attorneys and lawyers can use a timer to track time or manually enter their hours in timesheets. Ensure you bill clients accurately by logging every call, email, and meeting.
                        </p>
                        
                        <div className="service-dec-img animation-img-two">
                            {/* <img alt="" src={img1}  className="mt-3"/> */}
                        </div>
                    </div> 
                    <div className="mt-5">
                        <span className="step-font"></span>
                        <h3 className="step-heading">Monitor budget</h3>
                        <p>
                        Set up projects, categorize them by clients, and create tasks. Employees simply select a project or task, optionally describe their activity, and start the timer.
                        </p>
                        <div className="service-dec-img animation-img-two">
                        {/* <img alt="" src={img2} className="mt-3"/> */}
                        </div>
                    </div> 
                    <div className="mt-5">
                        <span className="step-font"></span>
                        <h3 className="step-heading">Track Client Profitability</h3>
                        <p>
                        Organize projects by client and task. Employees just pick a project or task, add a brief optional description of their work, and start the timer.
                        </p>
                        <div className="service-dec-img animation-img-two">
                        {/* <img
                            alt=""
                            src={img3} className="mt-3"
                            /> */}
                        </div>
                    </div> 
                    <div className="row">  
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                            <Link to="/features_seeall_features" className="btn btn-theme mt30">SEE ALL FEATURES</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SecondImagessection
