import React from "react";
import SectionHeading from "../../Home/SectionHeading/SectionHeading";
//  Import Img
import img2 from "../../../assets/img/main/2.png";
import img3 from "../../../assets/img/coming-soon.jpg";
import img4 from "../../../assets/img/coming-soon.jpg";
import img5 from "../../../assets/img/coming-soon.jpg";
import img6 from "../../../assets/img/main/6.png";
import img7 from "../../../assets/img/coming-soon.jpg";
import img19 from "../../../assets/img/coming-soon.jpg";

import { Link } from "react-router-dom";

const TwoColumns = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <SectionHeading
            title="Mobile app"
            heading={
              "See all your events and assignments, and track time on them."
            }
          />
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <h5 className="mt-2">Auto start & stop</h5>
                <p>
                  Initiate the timer automatically upon computer startup and
                  halt it upon shutdown.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img4} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <h5 className="mt-2">Idle detection</h5>
                <p>
                  Receive notifications if you are inactive while your timer is
                  running.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <h5 className="mt-2">Reminders</h5>
                <p>
                  Receive alerts when you are idle while your timer is active.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img7} /> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img5} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <h5 className="mt-2">Auto tracker</h5>
                <p>
                  Automatically log the apps and websites you use to help you
                  recall your work activities in case you forget to manually
                  track time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}

      {/* Offline mode */}
      <section id="about" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h3>Mobile app</h3>
                <h4>
                  View all your events and assignments and track time spent on
                  each of them.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}

      {/* Testimonial Area */}
      <section id="testimonial-area" className="py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-6 text-center">
              <div className="">
                {/* <img src={img19} alt="" className="img-fluid" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* here paset */}

      <section>
        <div className="container">
          <SectionHeading
            title="Plan activities"
            heading={"Schedule events and see assignments."}
          />
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <h5 className="mt-2">Auto start & stop</h5>
                <p>
                  Initiate the timer automatically upon computer startup and
                  cease it upon shutdown.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img4} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <h5 className="mt-2">Idle detection</h5>
                <p>
                  Receive notifications if you remain inactive while your timer
                  is running.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="product-section">
        <div className="container">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">More features</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Performance Reports</h3>
                      <p>
                        Break down time spent on daily, weekly, and monthly
                        tasks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Projects</h3>
                      <p>
                        Segment projects into tasks and organize them by client
                        for clarity and organization.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Reports</h3>
                      <p>
                        View a detailed breakdown of all tracked time based on
                        specific custom dates and dimensions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Export</h3>
                      <p>
                        Tailor your reports and export data in formats such as
                        Excel, PDF, or CSV according to your preferences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Invoicing</h3>
                      <p>Create invoices based on tracked billable hours.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Progress Overview</h3>
                      <p>
                        Visualize team productivity and time allocation through
                        an intuitive dashboard.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Project Management</h3>
                      <p>
                        Track project estimates and monitor budget completion.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Hourly Rates</h3>
                      <p>
                        Implement hourly rates and analyze the allocation of
                        your billable hours.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Expenses</h3>
                      <p>
                        Monitor the progress of projects and tasks visually.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="product-section">
        <div className="container">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">Related</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Time Tracker</h3>
                      <p>
                        Monitor and record the time spent on various activities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>Project Planning</h3>
                      <p>
                        Strategically organize future projects and schedule your
                        team's tasks effectively.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TwoColumns;
