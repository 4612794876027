import React from "react";
//  Import Img
import img3 from "../../assets/img/coming-soon.jpg";
import img2 from "../../assets/img/coming-soon.jpg";
import img4 from "../../assets/img/testing_image/image11.png";

import { Link } from "react-router-dom";

const ReportsApproval = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="">
        <div className="container border-bottom pb-5">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
            <div className="two-columns-holder text-center  pb-3">
              <h2 className="mt-2 paragraph-color fw600">
                {" "}
                Data Accuracy Oversight
              </h2>
              <p>Ensure all data is correct personally.</p>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600"> Data Verification</h5>
                <p>Identify and correct inaccurate information.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4 ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color fw600"> Mandatory Fields</h5>
                <p>Prevent saving incomplete time entries.</p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600"> Mass Editing</h5>
                <p>Apply filters in reports and update multiple entries.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4 ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                <img alt="" src={img4} />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color fw600"> Proxy Management</h5>
                <p>Record or modify time and expenses on behalf of others.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportsApproval;
