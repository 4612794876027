import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import CtaNew from "../layout/CtaNew";
import CopyRight from "../layout/CopyRight";
import FirstActivity from "../component/FeatReportingActivity/FirstActivity";
import TwoColumnImage from "../component/FeatReportingActivity/TwoColumnImage";
import BelowAllPage from "../component/FeatReportingActivity/BelowAllPage";
import MoreFeaturesAcitivity from "../component/FeatReportingActivity/MoreFeaturesAcitivity";

const FeatReportingActivityPage = () => {
  return (
    <>
      <Header />
      <FirstActivity />
      <TwoColumnImage />
      <BelowAllPage />
      <MoreFeaturesAcitivity />
      <CtaNew />
      <Footer />
      <CopyRight />
    </>
  );
};

export default FeatReportingActivityPage;
