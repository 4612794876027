import React from 'react'
import HeaderHelp from '../layout/HeaderHelp'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import Firstsect from '../component/Feature/AutoTracker/Firstsect'
import TwoColumns from '../component//Help/News/TwoColumns'
import MobileApp from '../component/Feature/AutoTracker/MobileApp'
import About from '../component/Feature/AutoTracker/About'
import AboutTwo from '../component/Feature/AutoTracker/AboutTwo'

const AutoTracker = () => {
    return (
        <>
            <HeaderHelp/>
            <TwoColumns />
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default AutoTracker
