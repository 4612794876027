 import React from 'react'
 import SectionHeading from '../SectionHeading/SectionHeading' 
//  Import Img
 import check from '../../../assets/img/about/tick.png'
 import AboutImg from '../../../assets/img/about/1.png'

 import { Link } from 'react-router-dom';
 
 const AboutOne=()=> {
  return (
   <>
   {/* About One Area Start */}
     <section id="about" className="py100">
        <div className="container">
            <SectionHeading title="Time management features" heading={"Track productivity, attendance, and billable hours with a simple time tracker and timesheet."}/>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="left-side-title">
                        <h3>Timekeeping</h3>
                        <h2 className="pt10">
                            We Provide Real Time Data Solutions, Analysis, Analytics And Prediction
                        </h2>
                    </div>
                    <div className="about-details pt10">
                        {/* <p>
                            Dolor sit amet consectetur elit sed eiusmod tempor incidi dunt labore dolore magna aliqua
                            enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut.
                        </p> */}
                        <ul className="pt20">
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  /><strong>Timer</strong> - Track work hours in real time.
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  /><strong>Timesheet</strong> - Enter time in a weekly timesheet.
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  /><strong>Calendar</strong> - Visually block out and manage time.
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  /><strong>Auto tracker</strong> - Track apps and websites you use.
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  /><strong>Kiosk</strong> - Clock in from a shared device.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="animation-img animation-img-one">
                        <img alt="" src={AboutImg}  />
                    </div>
                </div>
            </div>
        </div>
    </section>
       {/* About One Area End */}
   </>

  )
 }
 
 export default AboutOne
 