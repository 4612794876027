import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import Firstsect from '../component/Feature/Timer/Firstsect'
import TwoColumns from '../component/Feature/Timer/TwoColumns'
import MobileApp from '../component/Feature/Timer/MobileApp'
import About from '../component/Feature/Timer/About'
import AboutTwo from '../component/Feature/Timer/AboutTwo'

const Timer = () => {
    return (
        <>
            <Header/>
            <Firstsect />
            <TwoColumns />
            <MobileApp />
            <About />
            <AboutTwo />
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default Timer
