import React from 'react'
// Import Components
import SectionHeading from '../Home/SectionHeading/SectionHeading'; 
import KnowledgeProcessCard from '../ProductKnowledge/KnowledgeProcessCard'
import {KnowledgeProcessData} from './KnowledgeProcessData'

const KnowledgeHeading=({className = ''})=> {
 return (
  <>                                                               
  {/* Work Process Area Start */}
    <section id="work-process" className={`py100 ${className}`}>
        <div className="container">
           <SectionHeading title="Knowledge Hub" heading="Everything you need to know about good time management and how to improve team productivity."/>
            <div className="row">
             {KnowledgeProcessData.map((data, index) =>( <KnowledgeProcessCard data={data} key={index} />))}   
            </div>
        </div>
    </section>
  </>
 )
}

export default KnowledgeHeading;
