import React from 'react'
import {OurServiceData} from "../OurServicesData";
import OurServiceCard from "../OurServiceCard";

const Options = () => {
    return (
        <>
            <section id="service-slider" className="py100 bg-color">
                <div className="container">
                    <div className="row">
                        {OurServiceData.slice(0,6).map( (data, index)=>( <OurServiceCard data={data} key={index}/> ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Options
