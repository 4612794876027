import React from "react";
// Import Components
import SectionHeading from "../Home/SectionHeading/SectionHeading";
import { BlogServicesData } from "../ProductBlog/BlogServicesData";
import BlogServiceCard from "../ProductBlog/BlogServiceCard";

const BlogServices = () => {
  return (
    <>
      {/* Service Area Start */}
      <section id="service" className="bg-color border-bottom">
        <div className="container">
          {/* <SectionHeading
            title="Our Services"
            heading={
              "We Offer A Wide Range Of Data Services And Data Analytics Solutions"
            }
          /> */}
          <div className="row">
            {BlogServicesData.map((data, index) => (
              <BlogServiceCard data={data} key={index} />
            ))}
          </div>
        </div>
      </section>
      {/* Service Area End */}
    </>
  );
};

export default BlogServices;
