import React from "react";
import SectionHeading from "../Home/SectionHeading/SectionHeading";
//  Import Img
import img3 from "../../assets/img/coming-soon.jpg";
import img2 from "../../assets/img/coming-soon.jpg";
import { FaCircleArrowRight } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";

import { Link } from "react-router-dom";

const ReportsLast = () => {
  return (
    <>
      <section id="about" className="">
        <div className="container border-bottom">
          <div className="col-md-6 mx-auto text-center">
            <div className="logo-area-heading pb-3">
              <h2 className="heading-section-color text-centre">
                Report Customization
              </h2>
              <p className="py-3 ">
                Manage and tailor the content displayed in reports according to
                your preferences.
              </p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color">Timeframe Flexibility</h5>
                <p className="mt-2 hover-color">
                  Access reports spanning any selected duration for
                  comprehensive insights and analysis.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={require("../../assets/img/coming-soon.jpg")} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color">Data Filtering Options</h5>
                <p className="mt-2 hover-color">
                  Customize data views using various dimensions and fields for
                  precise analysis and reporting.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color">Time Rounding Options</h5>
                <p className="mt-2 hover-color">
                  Adjust time to the nearest increment, round up, or down based
                  on your preference for accuracy.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={require("../../assets/img/coming-soon.jpg")} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color">Time Display Format</h5>
                <p className="mt-2 hover-color">
                  Customize how time information is presented to suit your
                  specific preferences and needs.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color">
                  Tailored PDF Export Options
                </h5>
                <ul className="list">
                  <li className="mt-2 hover-color">Rename Reports</li>
                  <li className="mt-2 hover-color">Include Personal Notes</li>
                  <li className="mt-2 hover-color">Select Content</li>
                  <li className="mt-2 hover-color">Logo Integration</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={require("../../assets/img/coming-soon.jpg")} /> */}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={require("../../assets/img/coming-soon.jpg")} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color">Enhanced Customization</h5>
                <ul className="list">
                  <li className="mt-2 hover-color">
                    Extended Time Fields - Access additional fields for time
                    entries to capture specific details
                  </li>
                  <li className="mt-2 hover-color">
                    User-Specific Custom Fields - Utilize additional fields for
                    users to enhance data collection and organization
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container border-bottom">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">More features</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <span className="iocncolor" style={{ marginRight: "10px" }}>
                      <FaCheck />
                    </span>
                    <div className="media-body pl30">
                      <h3>Rate Management</h3>
                      <p>
                        Effectively manage billable and cost rates for users and
                        projects to ensure accurate financial tracking and
                        invoicing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <span className="iocncolor" style={{ marginRight: "10px" }}>
                      <FaCheck />
                    </span>
                    <div className="media-body pl30">
                      <h3>Mandatory Fields</h3>
                      <p>
                        Enforce required fields to prevent incomplete time
                        entries from being added.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <span className="iocncolor" style={{ marginRight: "10px" }}>
                      <FaCheck />
                    </span>
                    <div className="media-body pl30">
                      <h3>Time Locking</h3>
                      <p>
                        Restrict members from modifying past timesheet entries
                        to maintain data integrity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <span className="iocncolor" style={{ marginRight: "10px" }}>
                      <FaCheck />
                    </span>
                    <div className="media-body pl30">
                      <h3>Team Monitoring</h3>
                      <p>
                        View screenshots and GPS data of your team to monitor
                        their activities and locations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <span className="iocncolor" style={{ marginRight: "10px" }}>
                      <FaCheck />
                    </span>
                    <div className="media-body pl30">
                      <h3>Access Control</h3>
                      <p>
                        Manage permissions to regulate visibility and actions
                        within your account.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <span className="iocncolor" style={{ marginRight: "10px" }}>
                      <FaCheck />
                    </span>
                    <div className="media-body pl30">
                      <h3>Timesheet Approval</h3>
                      <p>
                        Request manager review and approval for submitted
                        timesheets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <span className="iocncolor" style={{ marginRight: "10px" }}>
                      <FaCheck />
                    </span>
                    <div className="media-body pl30">
                      <h3>Workday Organization</h3>
                      <p>
                        Coordinate and schedule workdays efficiently for
                        yourself and your team.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <span className="iocncolor" style={{ marginRight: "10px" }}>
                      <FaCheck />
                    </span>
                    <div className="media-body pl30">
                      <h3>Weekly Activity Logging</h3>
                      <p>
                        Quickly record your weekly tasks and activities in under
                        a minute.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <span className="iocncolor" style={{ marginRight: "10px" }}>
                      <FaCheck />
                    </span>
                    <div className="media-body pl30">
                      <h3>Project Progress Monitoring</h3>
                      <p>
                        Monitor project advancement through setting estimates
                        and budgets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h2 className="pb10 ">Related</h2>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6 border-start related-wrapper">
                <div className="single-feature ">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3 className="">
                         Activity 
                        {/* <span className="iconstyle-arrow-first ">
                          {" "}
                          <FaCircleArrowRight />
                        </span> */}
                      </h3>
                      <p>
                        Monitor individuals' contributions, tasks, and durations
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 related-wrapper">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                         Management
                        {/* <span className="iconstyle-arrow">
                          {" "}
                          <FaCircleArrowRight />
                        </span> */}
                      </h3>
                      <p>
                        Administer employee vacations and holidays efficiently.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportsLast;
