import React from 'react'
import SectionHeading from '../../Home/SectionHeading/SectionHeading' 
//  Import Img
import img2 from '../../../assets/img/coming-soon.jpg'
import img3 from '../../../assets/img/coming-soon.jpg'
import img4 from '../../../assets/img/coming-soon.jpg'
import img5 from '../../../assets/img/coming-soon.jpg'
import img6 from '../../../assets/img/coming-soon.jpg'
import img7 from '../../../assets/img/coming-soon.jpg'
import img8 from '../../../assets/img/main/8.png'
import AboutImg from '../../../assets/img/about/1.png'
import AboutImgTow from '../../../assets/img/about/2.png'

import { Link } from 'react-router-dom';

const TwoColumns=()=> {
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="about" className="py100">
       <div className="container">
            <div className="row mb-4">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img2}  /> */}
                        <h5 className="mt-2">Activities</h5>
                        <p>Organize and track time spent on various projects and tasks by categorizing them accordingly.</p>
                        <div className="btn-holder">
                            
                        </div>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img3}  /> */}
                        <h5 className="mt-2">Add details</h5>
                        <p>Enhance your entries with supplementary details such as notes, tags, and custom information for thorough record-keeping.</p>
                        <div className="btn-holder">
                            
                        </div>
                   </div>
               </div>
            </div>
            <div className="row mb-4">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img4}  /> */}
                        <h5 className="mt-2">Templates</h5>
                        <p>Swiftly populate your timesheet with your frequently performed activities.</p>
                        <div className="btn-holder">
                            
                        </div>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img5}  /> */}
                        <h5 className="mt-2">Copy week</h5>
                        <p>Duplicate activities and their corresponding time entries from a previous timesheet for ease and accuracy.</p>
                        <div className="btn-holder">
                           
                        </div>
                   </div>
               </div>
            </div>
            <div className="row mb-4">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img6}  /> */}
                        <h5 className="mt-2">Team’s timesheet</h5>
                        <p>View and complete your team members' timesheets.</p>
                        <div className="btn-holder">
                            
                        </div>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img7}  /> */}
                        <h5 className="mt-2">See totals</h5>
                        <p>Track and review your logged time daily, weekly, and by specific activities.</p>
                        <div className="btn-holder">
                            
                        </div>
                   </div>
               </div>
            </div>
            <div className="row mb-4">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img6}  /> */}
                        <h5 className="mt-2">Lock timesheets</h5>
                        <p>Restrict modifications to timesheets by implementing daily or weekly locking mechanisms.</p>
                        <div className="btn-holder">
                            
                        </div>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        {/* <img alt="" src={img7}  /> */}
                        <h5 className="mt-2">Tracking alerts</h5>
                        <p>Receive email notifications when someone logs excessive or insufficient time.</p>
                        <div className="btn-holder">
                            
                        </div>
                   </div>
               </div>
            </div>
            
       </div>
   </section>
      {/* Two Columns Area End */}
  </>

 )
}

export default TwoColumns
