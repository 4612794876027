import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import img64 from "../../assets/img/coming-soon.jpg";
import img65 from "../../assets/img/coming-soon.jpg";
import img66 from "../../assets/img/coming-soon.jpg";
import img67 from "../../assets/img/coming-soon.jpg";
import img4 from "../../assets/img/coming-soon.jpg";

import { Link } from "react-router-dom";

const AboutSteps = () => {
  return (
    <>
      <section id="project-details-area" className="py50">
        <div className="container">
          <div className="mt-5">
            <span className="step-font">STEP 1</span>
            <h3 className="step-heading">Create eClock.Work account</h3>
            <p>
              To begin documenting your team's attendance with eClock.Work,
              start by creating an account.
            </p>
            <p>
              <Link to="#">
                <span className="span-color">Sign up for free</span>
              </Link>
            </p>
          </div>
          <div className="mt-5">
            <span className="step-font">STEP 2</span>
            <h3 className="step-heading">Invite your team</h3>
            <p>
              Set up projects, categorize them by clients, and create tasks.
              Your employees simply choose a project/task, provide a brief
              optional description, and start the timer.
            </p>
          </div>
          <div className="mt-5">
            <span className="step-font">STEP 3</span>
            <h3 className="step-heading">Track attendance</h3>
            <p>
              Utilize the web app or the available desktop and mobile apps to
              track attendance efficiently.
            </p>
            <div className="service-dec-img animation-img-two">
              {/* <img alt="" src={img64}  className="mt-3"/> */}
            </div>
          </div>
          <div className="mt-5">
            <span className="step-font">STEP 4</span>
            <h3 className="step-heading">Monitor Work Activities </h3>
            <p>
              Track time conveniently through the browser, desktop, or mobile
              apps (iOS, Android, Mac, Windows, Linux). Desktop apps offer
              additional time tracking features like reminders and idle
              detection.
            </p>
            <div className="service-dec-img animation-img-two">
              {/* <img alt="" src={img65} className="mt-3" /> */}
            </div>
          </div>

          <div className="mt-5">
            <span className="step-font">STEP 5</span>
            <h3 className="step-heading">Review Employee s</h3>
            <p>
              Hours in Reports Employees can track time using a timer or
              manually enter their hours via desktop and mobile apps.
            </p>
            <div className="service-dec-img animation-img-two">
              {/* <img alt="" src={img66} className="mt-3" /> */}
            </div>
          </div>

          <div className="mt-5">
            <span className="step-font">STEP 6</span>
            <h3 className="step-heading">Export reports</h3>
            <p>
              Employees can track time using a timer or manually enter their
              hours via desktop and mobile apps. Desktop apps offer useful
              features such as reminders and idle detection.
            </p>
            <div className="service-dec-img animation-img-two">
              {/* <img alt="" src={img67} className="mt-3" /> */}
            </div>
          </div>

          <section id="project-details-area" className="py50">
            <div className="container">
              <div className="mb-5">
                <h2 className="mb-2">
                  eClock.Work attendance tracker — additional uses
                </h2>
                <p className="mb-2">
                  In addition to employee attendance tracking, eClock.Work can
                  be utilized for:
                </p>
              </div>
              <div className="mb-5">
                <h3 className="mb-2">Student attendance</h3>
                <p className="mb-2">
                  Enhance student productivity by utilizing eClock.Work to
                  track, record, and report on student attendance. Gain insights
                  into student absences, projects, and total class time.
                </p>
              </div>
              <div className="mb-5">
                <h3 className="mb-2">Event attendance</h3>
                <p className="mb-2">
                  Utilize eClock.Work to monitor attendance at various events,
                  providing valuable insights into your target audience, event
                  budgeting, and potential for professional or educational
                  accreditation.
                </p>
              </div>
              <div className="mb-5">
                <h3 className="mb-2">Non-Profit Organizations Attendance</h3>
                <p className="mb-2">
                  Non-profit organizations can efficiently track time and
                  attendance of hourly workers using eClock.Work, saving time
                  and enhancing transparency within the organization.
                </p>
              </div>
              <div className="mb-2">
                <h3 className="mb-2">Free Time Tracker for Non-Profits</h3>
              </div>
              <div className="mb-5">
                <h3 className="mb-2">Church attendance</h3>
                <p className="mb-2">
                  eClock.Work offers attendance tracking for church groups,
                  enabling you to monitor attendance and better understand
                  resources, capacity, and the mission of the church. Tracking
                  attendance can also provide insights into individual member
                  involvement and contributions.
                </p>
              </div>
              <div className="mb-2">
                <h3 className="mb-2">How to Track and Check Attendance</h3>
                <p className="mb-2">
                  Utilize the features of eClock.Work to track attendance
                  effectively across various settings, including classrooms,
                  events, non-profit organizations, and churches. Simply sign up
                  for an account, set up attendance tracking parameters, and use
                  the platform to monitor attendance efficiently.
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default AboutSteps;
