import React, { useState } from "react";
import img1 from "../../assets/img/coming-soon.jpg";
import { Link } from "react-router-dom";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
// Import Sidebar Img

const TimekeepingTags = [
  {
    list: "Timetrack",
    link: "/Timer",
  },
  {
    list: "Kiosk",
    link: "/features_timekeepingkiosk",
  },
  {
    list: "App",
    link: "/features_seeall_features",
  },
  {
    list: "Timesheet",
    link: "/timesheet",
  },
  {
    list: "Calender",
    link: "/calendar",
  },
  {
    list: "Intrgatrion",
    link: "/features_seeall_features",
  },
];
const RepotingTags = [
  {
    list: "Dashboard",
    link: "/features_seeall_features",
  },
  {
    list: "Activity",
    link: "/features_reporting_activity",
  },
  {
    list: "Projects",
    link: "/projects",
  },
  {
    list: "Reports",
    link: "/reports",
  },
];
const ManagmentTags = [
  {
    list: "Team",
    link: "/features_seeall_features",
  },
  {
    list: "Timeoff",
    link: "/features_timeoff",
  },
  {
    list: "Expenses",
    link: "/features_expense",
  },
  {
    list: "Schedulling",
    link: "/features_scheduling",
  },
  {
    list: "Approval",
    link: "/features_approval",
  },
  {
    list: "Invoicing",
    link: "/features_invoicing",
  },
];

// Modal

const FirstSeeFeatures = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="pt-5">
        <div className="container">
          <h2 className="pb-2 heading-section-color text-center">Features</h2>
          <div className="row">
            <div className="col-md-4 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <div class="features-content-container">
                  <div className="left-blog-page box-shadow">
                    <div className="left-tags blog-tags">
                      <div className="popular-tag left-side-tags left-blog">
                        <h4 className="text-left">TIMEKEEPING</h4>
                        <ul>
                          {TimekeepingTags.map((data, index) => (
                            <li key={index}>
                              <Link to={data.link}>{data.list}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <div class="features-content-container">
                  <div className="left-blog-page box-shadow">
                    <div className="left-tags blog-tags">
                      <div className="popular-tag left-side-tags left-blog">
                        <h4 className="text-left">REPORTING</h4>
                        <ul>
                          {RepotingTags.map((data, index) => (
                            <li key={index}>
                              <Link to={data.link}>{data.list}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <div class="features-content-container">
                  <div className="left-blog-page box-shadow">
                    <div className="left-tags blog-tags">
                      <div className="popular-tag left-side-tags left-blog">
                        <h4 className="text-left">MANAGEMENT</h4>
                        <ul>
                          {ManagmentTags.map((data, index) => (
                            <li key={index}>
                              <Link to={data.link}>{data.list}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FirstSeeFeatures;
