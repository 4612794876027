import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import FirstVideo from '../component/ProductsIndustries/FirstVideo'
import  Secondsection from '../component/ProductsIndustries/SecondImagessection'
import AboutArea from '../component/ProductsIndustries/AboutArea'
import AboutSteps from '../component/ProductsIndustries/AboutStep'
import RatingArea from '../component/ProductsIndustries/RatingArea'

const ProductPayroll = () => {
    return (
        <>
            <Header/>
            <FirstVideo />
            <Secondsection />
            <AboutArea />
            <AboutSteps />
            <RatingArea />
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ProductPayroll
