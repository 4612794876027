import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// All Page Component
import Home from "../src/page/Home";
import HomeTwo from "../src/page/HomeTwo";
import HomeThree from "../src/page/HomeThree";
import HomeFour from "../src/page//HomeFour";
import HomeFive from "../src/page//HomeFive";
import Services from "../src/page/Services";
import About from "../src/page/About";
import ServiceDetails from "../src/page/ServiceDetails";
import Projects from "../src/page/Projects";
import ProjectDetails from "../src/page/ProjectDetails";
import Blog from "../src/page/Blog";
import BlogDetails from "../src/page/BlogDetails";
import Shop from "../src/page/Shop";
import ProductDetails from "../src/page/ProductDetails";
import Cart from "../src/page/Cart";
import CheckOut from "../src/page/CheckOut";
import Login from "../src/page/Login";
import Register from "../src/page/Register";
import ResetPassword from "../src/page/ResetPassword";
import Contact from "../src/page/Contact";
import Faqs from "../src/page/Faqs";
import Error from "../src/page/Error";



//Rahul
import ProductBudgetingPage from "../src/page/ProductBudgetingPage";
import ProductIndAccountantsPage from "../src/page/ProductIndAccountantsPage";
import ProductConstructionPage from "../src/page/ProductConstructionPage";
import ProductMoreIndPage from "../src/page/ProductMoreIndPage";
import ProductPlansFreePage from "../src/page/ProductPlansFreePage";
import ProductPlansProPage from "../src/page/ProductPlansProPage";
import ProductPlanSeePage from "../src/page/ProductPlanSeePage";
import ProductHelpStatusPage from "../src/page/ProductHelpStatusPage";
import ProductFeedbackPage from "../src/page/ProductFeedbackPage";
import ProductHelpForumPage from "../src/page/ProductHelpForumPage";
import ProductHelpGuidesPage from "../src/page/ProductHelpGuidesPage";
import ProductKnowledgePage from "../src/page/ProductKnowledgePage";
import ProductBlogPage from "../src/page/ProductBlogPage";
import ProductTutorialsPage from "../src/page/ProductTutorialsPage";
import FeaturesTimeKioskPage from "../src/page/FeaturesTimeKioskPage";
import FeaturesSchedulingPage from "../src/page/FeaturesSchedulingPage";
import FeaturesTimeOffPage from "../src/page/FeaturesTimeOffPage";
import FeaturesApprovalPage from "../src/page/FeaturesApprovalPage";
import FeaturesExpensesPage from "../src/page/FeaturesExpensesPage";
import FeaturesInvoicingPage from "../src/page/FeaturesInvoicingPage";
import FeaturesSeeAllPage from "../src/page/FeaturesSeeAllPage";
import FeatReportingActivityPage from "../src/page/FeatReportingActivityPage";
import FeatReportingLocationPage from "../src/page/FeatReportingLocationPage";
import HomeFeaturesPage from "../src/page/HomeFeaturesPage";
import HomeProductReportingPage from "../src/page/HomeProductReportingPage";




// prayas
import ProductPayroll from "../src/page/ProductPayroll";
import ProductIndustries from "../src/page/ProductIndustries";
import ProductAgencies from "../src/page/ProductAgencies";
import ProductLawyer from "../src/page/ProductLawyer";
import ProductStartup from "../src/page/ProductStartup";
import ProductDevelopers from "../src/page/ProductDevelopers";
import ProductConsultants from "../src/page/ProductConsultants";
import ProductAttendance from "../src/page/ProductAttendance";
import ContactUs from "../src/page/ContactUs";
import Help from "../src/page/Help";
import HelpAbout from "../src/page/HelpAboutus"
import Tutorial from "../src/page/HelpTutorials"
import Helpus from "../src/page/Helpus";
import Timer from "../src/page/Timer";
import TimeSheet from "../src/page/TimeSheet";
import Calendar from "../src/page/Calendar";
import AutoTracker from "../src/page/AutoTracker";
import HelpNews from "../src/page/HelpNews";
import ReportingRates from "./page/ReportingRates";
import ReportingProjects from "./page/ReportingProjects";
import Forum from "../src/page/Forum"
import TimeTracker from "./page/TimeTracker";
import CodeofConduct from "./page/CodeofConduct";
import Disclaimer from "./page/Disclaimer";
import TermnCondition from "./page/TermnCondition";
import PrivacyPolicyPage from "./page/PrivacyPolicyPage";



import Product from "../src/page/product";
import Timekeeper from "../src/page/Timekeeper";
import Planning from "../src/page/Planning";

import Reports from "../src/page/Reports";

// Layout Component
import Header from "../src/layout/Header";
import Footer from "../src/layout/Footer";
import Cta from "../src/layout/Cta";
import CopyRight from "../src/layout/CopyRight";


import VisionMission from "./page/vision_mission";
import CompanyReview from "./page/company_review";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/error" element={<Error/>} />
          <Route path="/home_two" element={<HomeTwo/>} />
          <Route path="/home_three" element={<HomeThree/>} />
          <Route path="/home_four" element={<HomeFour/>} />
          <Route path="/home_five" element={<HomeFive/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/service_details" element={<ServiceDetails/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/projects" element={<Projects/>} />
          <Route path="/project_details" element={<ProjectDetails/>} />
          <Route path="/blog" element={<Blog/>} />
          <Route path="/blog_details" element={<BlogDetails/>} />
          <Route path="/shop" element={<Shop/>} />
          <Route path="/product_details" element={<ProductDetails/>} />
          <Route path="/cart" element={<Cart/>} />
          <Route path="/checkout" element={<CheckOut/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/reset_password" element={<ResetPassword/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/faqs" element={<Faqs/>} />

          <Route path="/product_budgeting" element={<ProductBudgetingPage/>} />
          <Route path="/product_indaccountants" element={<ProductIndAccountantsPage/>} />
          <Route path="/Product_construction" element={<ProductConstructionPage/>} />
          <Route path="/product_MoreInd" element={<ProductMoreIndPage/>} />
          <Route path="/product_freeplan" element={<ProductPlansFreePage/>} />
          <Route path="/product_proplan" element={<ProductPlansProPage/>} />
          <Route path="/product_seepricingplan" element={<ProductPlanSeePage/>} />
          <Route path="/product_contact_helpstatus" element={<ProductHelpStatusPage/>} />
          <Route path="/product_helpfeedback" element={<ProductFeedbackPage/>} />
          <Route path="/product_helpforum" element={<ProductHelpForumPage/>} />
          <Route path="/product_helpguide" element={<ProductHelpGuidesPage/>} />
          <Route path="/product_knowledgehub" element={<ProductKnowledgePage/>} />
          <Route path="/product_blog" element={<ProductBlogPage/>} />
          <Route path="/product_tutorails" element={<ProductTutorialsPage/>} />
          <Route path="/features_timekeepingkiosk" element={<FeaturesTimeKioskPage/>} />
          <Route path="/features_scheduling" element={<FeaturesSchedulingPage/>} />
          <Route path="/features_timeoff" element={<FeaturesTimeOffPage/>} />
          <Route path="/features_approval" element={<FeaturesApprovalPage/>} />
          <Route path="/features_expense" element={<FeaturesExpensesPage/>} />
          <Route path="/features_invoicing" element={<FeaturesInvoicingPage/>} />
          <Route path="/features_seeall_features" element={<FeaturesSeeAllPage/>} />
          <Route path="/features_reporting_activity" element={<FeatReportingActivityPage/>} />  
          <Route path="/features_reporting_location" element={<FeatReportingLocationPage/>} />
          <Route path="/home_features" element={<HomeFeaturesPage/>} />
          <Route path="/product_homereporting" element={<HomeProductReportingPage/>} />

          

          <Route path="/productpayroll" element={<ProductPayroll/>} />
          <Route path="/productindustries" element={<ProductIndustries/>} />
          <Route path="/productagencies" element={<ProductAgencies/>} />
          <Route path="/productlawyer" element={<ProductLawyer/>} />
          <Route path="/productstartup" element={<ProductStartup/>} />
          <Route path="/productdevelopers" element={<ProductDevelopers/>} />
          <Route path="/productconsultants" element={<ProductConsultants/>} />
          <Route path="/productattendance" element={<ProductAttendance/>} />
          <Route path="/contactus" element={<ContactUs/>} />
          <Route path="/contactus/help" element={<Help/>} />
          <Route path="/aboutus" element={<HelpAbout/>} />
          <Route path="/tutorial" element={<Tutorial/>} />
          <Route path="/help" element={<Helpus/>} />
          <Route path="/timer" element={<Timer/>} />
          <Route path="/timesheet" element={<TimeSheet/>} />
          <Route path="/calendar" element={<Calendar/>} />
          <Route path="/autotracker" element={<AutoTracker/>} />
          <Route path="/help/news" element={<HelpNews/>} />
          <Route path="/rates" element={<ReportingRates/>} />
          <Route path="feature/rates" element={<ReportingRates/>} />
          <Route path="feature/projects" element={<ReportingProjects/>} />
          <Route path="/forum" element={<Forum/>} />
          <Route path="/timetracker" element={<TimeTracker/>} />

        
          <Route path="/product" element={<Product/>} />
          <Route path="/timekeeper" element={<Timekeeper/>} />
          <Route path="/planning" element={<Planning/>} />
          <Route path="/reports" element={<Reports />} />

          <Route path="/codeofconduct" element={<CodeofConduct />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/termofuse" element={<TermnCondition />} />
          <Route path="/privacypolicypage" element={<PrivacyPolicyPage />} />

          <Route path="/vission-mission" element={<VisionMission />} />
          <Route path="/company-review" element={<CompanyReview />} />

        </Routes>
      </Router>
    </>
  );
};

export default App;
