import React,{useState} from 'react'
import img1 from '../../../assets/img/coming-soon.jpg'
import { Link } from 'react-router-dom';

// Modal 

const About=()=> {
 return (
    <>
    {/* Two Columns Area Start */}
    <section id="about" className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <div className="logo-area-heading pb-3">
              <h3>Desktop app</h3>
              <h4>Install eClock.Work to monitor your time on your desktop.</h4>
            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center">
          <div className="col-md-4 text-center">
            <Link to="/#" className="btn btn-theme mt-3">START TRACKING TIME</Link>
          </div>
          <div className="col-md-4 text-center">
            <Link to="/#" className="btn btn-theme mt-3">WATCH VIDEO</Link>
          </div>
        </div> */}
      </div>
    </section>
    {/* Two Columns Area End */}
    
    {/* Testimonial Area */}
    <section id="testimonial-area" className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 text-center">
            <div className="">
              {/* <img src={img1} alt="" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Browse Extension */}
    <section id="about" className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <div className="logo-area-heading pb-3">
              <h3>Browser extension</h3>
              <h4>Add the plugin to enable time tracking on various websites.</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Two Columns Area End */}
    
    {/* Testimonial Area */}
    <section id="testimonial-area" className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6 text-center">
            <div className="">
              {/* <img src={img1} alt="" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);
}

export default About
