import React, {useState} from 'react'
// Import Modal
import '../../../node_modules/react-modal-video/css/modal-video.min.css'
// Img
import banner from '../../assets/img/home5/banner.png'

// Import React Dom
import {Link} from 'react-router-dom';

const PlanningBanner = () => {
    const [isOpen, setOpen] = useState(false)
    return (
        <>
        <section id="banner-five" className="mb200">
            <div className="bg-shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="home-one-banner-content color-white ">
                                <h1 className="color-white ">Employee Scheduling Software</h1>
                                <p className="color-white ">
                                Save hours every week with our employee scheduling software. Create shifts, schedule employees, manage time off, track time, and run reports
                                </p>
                                <div className="banner-one-btn pt30">
                                    {/* <a href="/login" className="btn btn-theme">
                                        Start tracking time — It's Free!
                                    </a> */}
                                    <Link to="https://eclockdashboard.vercel.app/login" target="blank" className="btn btn-theme">Start tracking time — It's Free!</Link>
                                </div>
                            </div>
                        </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="banner-img animation-img-one">
                            <img src={banner} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </section>     
        </>
    )
}

export default PlanningBanner
