export const PricingIndexData=[
    {
     title:"Basic",
     price:"2 ",
     data:[
      {
       list:"Unlimited Campaigns"
      },
      {
       list:"Basic Donor Data"
      },
      {
    
       list:"Team Fundraising"
      },
      {
       list:"Weekly Campaigns"
      },
      {
       list:"Data Analysis"
      },
      {
       list:"Data Analytics"
      },
      {
       list:"Daily Report Delivery"
      },
      {
       list:"Business Prediction"
      },
     ],
    btn:"BUY NOW"
    },
    {
     title:"STANDARD PACKAGE ",
     price:"40",
     data:[
      {
       list:"Unlimited Campaigns"
      },
      {
       list:"Basic Donor Data"
      },
      {
       list:"Team Fundraising"
      },
      {
       list:"Weekly Campaigns"
      },
      {
       list:"Data Analysis"
      },
      {
       list:"Data Analytics"
      },
      {
       list:"Daily Report Delivery"
      },
      {
       list:"Business Prediction"
      },
     ],
    btn:"BUY NOW"
    },
    {
     title:"ENTERPRISE",
     price:"20",
     data:[
      {
       list:"Unlimited Campaigns"
      },
      {
    
       list:"Basic Donor Data"
      },
      {
       list:"Team Fundraising"
      },
      {
       list:"Weekly Campaigns"
      },
      {
       list:"Data Analysis"
      },
      {
       list:"Data Analytics"
      },
      {
       list:"Daily Report Delivery"
      },
      {
       list:"Business Prediction"
      },
     ],
    btn:"BUY NOW"
    }
    ]