import React from 'react'
import {AiOutlineCheckCircle} from "react-icons/ai";
import img1 from "../../assets/img/home5/about.png"
import img2 from "../../assets/img/coming-soon.jpg"
import img3 from "../../assets/img/coming-soon.jpg"
import img6 from "../../assets/img/coming-soon.jpg"

import { Link } from "react-router-dom";

const SecondImagessection = () => {
    return (
        <>
            <section id="project-details-area" className="py100">
                <div className="container">
                <h2 className="">Time Tracking Solution for Consultants</h2>
                    <p><strong>eClock.Work: </strong>A versatile application suitable for virtual teams, firms, and consultants alike. Here's how it can benefit you:</p>
                    <div className="mt-5">
                        <span className="step-font"></span>
                        <h3 className="mb-4">Track Billable and Non-Billable Time</h3>
                        <p>Utilize the online timer or manually input work hours into eClock.Work's timesheet. Ensure precision in billing by meticulously logging every call, email, and meeting to maximize revenue.</p>
                        
                        <div className="service-dec-img animation-img-two">
                            <img alt="" src={img1}  className="mt-3"/>
                        </div>
                    </div> 
                    <div className="mt-5">
                        <span className="step-font"></span>
                        <h3 className="step-heading">Monitor progress</h3>
                        <p>
                        Estimate task durations and track time against them to visually assess progress. Set project parameters to receive alerts if budgets are exceeded, ensuring proactive management.
                        </p>
                        <div className="service-dec-img animation-img-two">
                        {/* <img alt="" src={img2} className="mt-3"/> */}
                        </div>
                    </div> 
                    <div className="mt-5">
                        <span className="step-font"></span>
                        <h3 className="step-heading">Enhance Profitability</h3>
                       <ul>
                        <p>Leverage eClock.Work to:</p>
                        <li className="mb-2">Break down tracked time by client and project.</li>
                        <li className="mb-2">Analyze billable versus non-billable hours.</li>
                        <li className="mb-2">Calculate earned revenue.</li>
                        <li className="mb-2">Share comprehensive data with clients, fostering transparency and trust in your services.</li>
                       </ul>
                        <div className="service-dec-img animation-img-two">
                        {/* <img
                            alt=""
                            src={img3} className="mt-3"
                            /> */}
                        </div>
                    </div> 
                    
                    <div className="row">  
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                            <Link to="/features_seeall_features" className="btn btn-theme mt30">SEE ALL FEATURES</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SecondImagessection
