import React from 'react'
import CommonBanner from '../component/Common/Banner'
import ContactDetails from '../component/Contact'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import ContactContent from '../component/FooterPages/ContactContent'

const Contact = () => {
    return (
        <>
            <Header/>
            {/* <ContactContent/> */}
            <CommonBanner heading="Contact" menu1="Home" menu2="Contact"/>
            <ContactDetails/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default Contact
