import React from "react";
// import Banner from "../component/HomeFive/Banner";
// import AnalysisWork from "../component/HomeFour/AnalysisWork";
import AnalysisBlog from "../component/ProductBlog/AnalysisBlog";

// import OurServices from "../component/Home/OurService/OurServices";
import BlogServices from "../component/ProductBlog/BlogServices";
// import WorkingProcess from "../component/Home/OurWorkingProcess/WorkingProcess";
import Header from "../layout/Header";
import CtaNew from "../layout/CtaNew";
import CopyRight from "../layout/CopyRight";
import Footer from '../layout/Footer'
import FreeTimeTracker from '../component/ProductHelpGuides/FreeTimeTracker'
import BlogResources from '../component/ProductBlog/BlogResources'
import BlogProcess from '../component/ProductBlog/BlogProcess/BlogProcess'



const ProductBlogPage = () => {
  return (
    <>
      <Header />
      {/* <Banner /> */}
      <AnalysisBlog />
      <BlogServices/>
      {/* <OurServices /> */}
      {/* <WorkingProcess  /> */}
      <BlogProcess  />
      <BlogResources />
      <FreeTimeTracker/>
      <Footer />
      <CopyRight />
    </>
  );
};

export default ProductBlogPage;
