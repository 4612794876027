import React from "react";
import { Link } from "react-router-dom";
import { FcGraduationCap } from "react-icons/fc";
import img1 from "../../assets/img/project-details/2.png";

const ProductBudgeting = () => {
  return (
    <>
      <section id="about" className="bg-color">
        <div className="container ">
          <div className="row mb-4 ">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12  text-left p-4">
              <div class="container pt-4 pb-4 mb-1">
                <h2 class="text-left heading-section-color ">System status </h2>
                <div class=" pb-1">
                  <p>Is eClock.Work up or down ?</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="">
        <div className="container ">
          <div className="row mb-4 ">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12  text-left p-4">
              <div class="container pt-4 pb-4 mb-1 text-center">
                <h1 class=" ">All OK! </h1>
                <div class=" pb-1">
                  <p>ALL SYSTEMS ARE UP AND RUNNING</p>
                </div>
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image09.png")}
                    className="mt-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </section>
      
    </>
  );
};

export default ProductBudgeting;
