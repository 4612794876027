import React from "react";
//  Import Img
import { FaCircleArrowRight } from "react-icons/fa6";
import { GiCheckMark } from "react-icons/gi";

import { Link } from "react-router-dom";

const MoreFeaturesAcitivity = () => {
  return (
    <>
      <section id="" className="">
        <div className="container border-bottom">
          <div className="py-5">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h3 className="pb10">More features</h3>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Expense Monitoring
                      </h3>
                      <p>
                        Track the hours dedicated to each task and calculate
                        associated labor costs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Billing Automation
                      </h3>
                      <p>
                        Efficiently generate invoices based on tracked time
                        entries.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        QuickBooks Sync
                      </h3>
                      <p>
                        Send timesheet reports directly to QuickBooks for
                        processing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Activity Approval Process
                      </h3>
                      <p>
                        Submit, review, and approve tracked activity timesheets
                        efficiently.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature pb-4">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Team Task Management
                      </h3>
                      <p>
                        Coordinate work schedules, assignments, and tasks among
                        teams effectively.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30">
                      <h3>
                        {" "}
                        <span className="iocncolor">
                          <GiCheckMark />
                        </span>{" "}
                        Billing Preferences
                      </h3>
                      <p>
                        Select which activities are eligible for billing and
                        which are not.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="product-section">
        <div className="container">
          <div className="feature-area-bg">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-title-center text-center pb30">
                  <h2 className="pb10 ">Related</h2>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6 border-start related-wrapper py-3">
                <div className="single-feature ">
                  <div className="media">
                    <div className="media-body pl30 animation-img-two">
                      <h3 className="hover-color">
                        Tracking Location
                        {/* <span className="iconstyle-arrow-first hover-color">
                          {" "}
                          <FaCircleArrowRight />
                        </span> */}
                      </h3>
                      <p>Track and view clock-in locations.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 related-wrapper py-3">
                <div className="single-feature">
                  <div className="media">
                    <div className="media-body pl30  animation-img-two">
                      <h3 className="hover-color">
                        Report Analysis{" "}
                        {/* <span className="iconstyle-arrow ">
                          {" "}
                          <FaCircleArrowRight />
                        </span> */}
                      </h3>
                      <p>View and analyze tracked data.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MoreFeaturesAcitivity;
