import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SectionHeading from "../../component/Common/SectionHeading";
import { Link } from "react-router-dom";
import { PricingIndexData } from "./PricingIndexData";
import { IoMdCheckboxOutline } from "react-icons/io";

const PricingIndex = ({ className = "" }) => {
  return (
    <>
      <section className={` ${className}`}>
        <div className="container">
          <div className="text-center pt-5">
            <h3 className="mx-auto text-centre heading-section-color">
              Our Pricing Plan
            </h3>
          </div>
          <SectionHeading
            title="Our Pricing Plan"
            heading={"Get started for free and upgrade to get more features!"}
          />
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
              <Tab.Container id="left-tabs-example" defaultActiveKey="monthly">
                <div className="tabs-box">
                  <div className="upper-box clearfix">
                    <div className="tab-btn-box">
                      <Nav className="nav nav-tabs tab-btns tab-buttons tab-title">
                        <Nav.Item>
                          <Nav.Link
                            className="tab-btn active-btn"
                            eventKey="monthly"
                          >
                            Monthly
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link className="tab-btn" eventKey="yearly">
                            Yearly
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                  <div className="tabs-content">
                    <Tab.Content>
                      <Tab.Pane eventKey="monthly">
                        <div>
                          <div className="row clearfix parent ">
                            {PricingIndexData.map((data, index) => (
                              <div
                                className="col-lg-4 col-md-6 col-sm-12 col-12 pricing-block single-item-mt-2"
                                key={index}
                              >
                                <div className="pricing-table box-shadow border-radius">
                                  <div className="pricing-table-header">
                                    <h3>{data.title}</h3>
                                    <h2>${data.price}</h2>
                                  </div>
                                  <div className="pricing-table-content">
                                    <ul>
                                      {data.data.map((datas, index) => (
                                        <li key={index}>
                                          <i>
                                            <IoMdCheckboxOutline />
                                          </i>{" "}
                                          {datas.list}
                                        </li>
                                      ))}
                                    </ul>
                                    <div className="pricing-table-btn text-center">
                                      <Link className="btn btn-pricing" to="/#">
                                        {data.btn}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="yearly">
                        <div>
                          <div className="row clearfix parent">
                            {PricingIndexData.map((data, index) => (
                              <div
                                className="col-lg-4 col-md-6 col-sm-12 col-12 pricing-block single-item-mt-2"
                                key={index}
                              >
                                <div className="pricing-table box-shadow border-radius">
                                  <div className="pricing-table-header">
                                    <h3>{data.title}</h3>
                                    <h2>${data.price}</h2>
                                  </div>
                                  <div className="pricing-table-content">
                                    <ul>
                                      {data.data.map((datas, index) => (
                                        <li key={index}>
                                          <i>
                                            <IoMdCheckboxOutline />
                                          </i>{" "}
                                          {datas.list}
                                        </li>
                                      ))}
                                    </ul>
                                    <div className="pricing-table-btn text-center">
                                      <Link className="btn btn-pricing" to="/#">
                                        {data.btn}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PricingIndex;
