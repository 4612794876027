import React from 'react'
import CommonBanner from '../component/Common/Banner'
import Options from '../component/Help/ContactusHelp/Options'
import Searchbar from '../component/Help/ContactusHelp/searchbar'
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import About from '../component/Help/Helpus/AboutArea'

const Help = () => {
    return (
        <>
            <Header/>
            <Searchbar />
            <Options/>
            <About />
            <Footer />
        </>
    )
}

export default Help
