import React from "react";
import { Link } from "react-router-dom";
import { FaRegArrowAltCircleRight } from "react-icons/fa";

const AllBelowSeePage = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py-5 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Insights</h2>
                <p className="mb-4">
                  Analyze team activities, earnings, and export data.
                </p>
                <p>
                  <span>
                    <a href="#">More Deatails</a> <FaRegArrowAltCircleRight />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    {/* <img
                      alt=""
                      src={require("../../assets/img/coming-soon.jpg")}
                      className="mt-3 pb-5"
                    /> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Sorting</h5>
                      <p className="mt-2 ">Narrow down your data.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Shareable</h5>
                      <p className="mt-2 ">Distribute reports using a link.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Download</h5>
                      <p className="mt-2 ">Save as PDF, CSV, or Excel.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Insights</h5>
                      <p className="mt-2 ">
                        Review data by: day, task, team...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Inspection</h2>
                <p className="mb-4">Review locations and tasks completed.</p>
                <p>
                  <span>
                    <a href="#">MORE DETAILS</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image02.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Geotagging</h5>
                      <p className="mt-2 ">Capture GPS coordinates.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Journeys</h5>
                      <p className="mt-2 ">Review all visited locations.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Snapshots</h5>
                      <p className="mt-2 ">Document tasks performed.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Snapshots</h5>
                      <p className="mt-2 ">
                        Manage data collection preferences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="py-5 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Initiatives</h2>
                <p className="mb-4">
                  Monitor time spent on projects and oversee progress and
                  financials.
                </p>
                <p>
                  <span>
                    <a href="#">More Deatails</a> <FaRegArrowAltCircleRight />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    {/* <img
                      alt=""
                      src={require("../../assets/img/coming-soon.jpg")}
                      className="mt-3 pb-5"
                    /> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Tasks</h5>
                      <p className="mt-2 ">Organize by job category.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Rates</h5>
                      <p className="mt-2 ">
                        Different levels of hourly charges.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Budget</h5>
                      <p className="mt-2 ">
                        Set financial plan or time estimate.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Forecasting</h5>
                      <p className="mt-2 ">
                        ForecastingPredict project outcomes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Collaborators</h2>
                <p className="mb-4">
                  Invite your team members to log time in your account.
                </p>
                <p>
                  <span>
                    <a href="#">MORE DETAILS</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image02.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Invite Team</h5>
                      <p className="mt-2 ">
                        Bring in your team members at no extra cost.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Rates</h5>
                      <p className="mt-2 ">Define hourly rates per user.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Supervisor</h5>
                      <p className="mt-2 ">Assign elevated permissions.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Teams</h5>
                      <p className="mt-2 ">
                        Organize access through teams or groups.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="py-5 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Scheduling</h2>
                <p className="mb-4">
                  Coordinate projects and visualize team capacity.
                </p>
                <p>
                  <span>
                    <a href="#">More Deatails</a> <FaRegArrowAltCircleRight />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    {/* <img
                      alt=""
                      src={require("../../assets/img/coming-soon.jpg")}
                      className="mt-3 pb-5"
                    /> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Project Milestones</h5>
                      <p className="mt-2 ">Map out projects on a timeline.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Task Allocations</h5>
                      <p className="mt-2 ">
                        Designate who handles which tasks.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Workload Assessment</h5>
                      <p className="mt-2 ">
                        Assess team workload capabilities.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Task Efficiency</h5>
                      <p className="mt-2 ">
                        Compare scheduled versus tracked progress.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Scheduling</h2>
                <p className="mb-4">
                  Plan projects and visualize team's capacity.
                </p>
                <p>
                  <span>
                    <a href="#">MORE DETAILS</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    {/* <img
                      alt=""
                      src={require("../../assets/img/coming-soon.jpg")}
                      className="mt-3 pb-5"
                    /> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Timer</h5>
                      <p className="mt-2 ">
                        Add kiosk users by name (no email required).
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Add time</h5>
                      <p className="mt-2 ">Enter and edit hours manually.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Continue</h5>
                      <p className="mt-2 ">Continue tracking with one click</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Billable</h5>
                      <p className="mt-2 ">
                        Add kiosk users by name (no email required).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="py-5 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Leave Management</h2>
                <p className="mb-4">
                  Track your team's holidays and leave requests.
                </p>
                <p>
                  <span>
                    <a href="#">More Deatails</a> <FaRegArrowAltCircleRight />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    {/* <img
                      alt=""
                      src={require("../../assets/img/coming-soon.jpg")}
                      className="mt-3 pb-5"
                    /> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Guidelines</h5>
                      <p className="mt-2 ">Establish time-off guidelines.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Vacation</h5>
                      <p className="mt-2 ">
                        Set vacation periods for paid leaves.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Applications</h5>
                      <p className="mt-2 ">
                        Submit and manage leave applications.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Accrual</h5>
                      <p className="mt-2 ">Monitor available days/hours.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Authorization</h2>
                <p className="mb-4">
                  Review and authorize your team's timesheets and expenses.
                </p>
                <p>
                  <span>
                    <a href="#">MORE DETAILS</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    {/* <img
                      alt=""
                      src={require("../../assets/img/coming-soon.jpg")}
                      className="mt-3 pb-5"
                    /> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Request Submission</h5>
                      <p className="mt-2 ">
                        Effortlessly submit timesheets with a single click.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Approval Period</h5>
                      <p className="mt-2 ">
                        Choose between monthly or weekly time approval cycles.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Notifications</h5>
                      <p className="mt-2 ">
                        Receive reminders for late timesheet submissions.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Activity Log</h5>
                      <p className="mt-2 ">
                        Access a comprehensive history of all requests.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="py-5 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Expenses</h2>
                <p className="mb-4">
                  Track and record fixed-fees and other project-related expenses
                  to accurately monitor project costs and budget utilization.
                </p>
                <p>
                  <span>
                    <a href="#">More Deatails</a> <FaRegArrowAltCircleRight />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    {/* <img
                      alt=""
                      src={require("../../assets/img/coming-soon.jpg")}
                      className="mt-3 pb-5"
                    /> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Track</h5>
                      <p className="mt-2 ">
                        Record expenses based on total amount or quantity.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Categories</h5>
                      <p className="mt-2 ">
                        Define different types of expenses for better
                        organization.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Receipt Attachments</h5>
                      <p className="mt-2 ">
                        Upload receipts to accompany expense entries.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Analysis</h5>
                      <p className="mt-2 ">
                        Review and export detailed expense reports for financial
                        tracking.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllBelowSeePage;
