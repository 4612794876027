import React from "react";
//  Import Img
import img1 from "../../assets/img/home5/banner.png";
import img3 from "../../assets/img/coming-soon.jpg";
import img2 from "../../assets/img/coming-soon.jpg";
import img4 from "../../assets/img/testing_image/image11.png";

import { Link } from "react-router-dom";

const ReviewLocation = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="pt-5">
        <div className="container  pb-5">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
            <div className="two-columns-holder text-center  pb-3">
              <h2 className="mt-2 paragraph-color fw600"> Location Overview</h2>
              <p>View all visited work locations and replay travel routes.</p>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600"> Location Mapping</h5>
                <p>
                  Visualize the current whereabouts of team members on a map.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4 ">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color fw600"> Route Playback</h5>
                <p>Review the daily routes of your mobile workforce.</p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder text-center ">
                <h5 className="mt-2 hover-color fw600"> Current Presence</h5>
                <p>
                  View the current presence of individuals at a specific
                  location.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center py-5">
            <div className="two-columns-holder text-center  pb-3">
              <h2 className="mt-2  fw600"> Universal Applicability</h2>
              <p>
                Ideal for monitoring diverse mobile workforces across
                industries.
              </p>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
            <div className="two-columns-holder">
              {/* <img alt="" src={img1} /> */}
            </div>
          </div>
          <div className="row justify-content-center py-2">
            <div className="col-md-4 text-center">
              <Link to="/#" className="btn btn-theme mt-3">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReviewLocation;
