import React from "react";
//  Import Img
import img2 from "../../assets/img/main/2.png";
import img3 from "../../assets/img/main/3.png";
import img4 from "../../assets/img/main/4.png";
import img5 from "../../assets/img/main/5.png";
import img6 from "../../assets/img/main/6.png";
import img7 from "../../assets/img/main/7.png";
import img8 from "../../assets/img/main/8.png";
import img9 from "../../assets/img/main/9.png";
import AboutImg from "../../assets/img/about/1.png";
import AboutImgTow from "../../assets/img/about/2.png";

import { Link } from "react-router-dom";

const TwocolumnsImage = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Limited users</h5>
                  <p className="mt-2">
                  Add kiosk users using their names without requiring email addresses.
                  </p>
                  <p className="mt-2 paragraph-color">
                    See pricing for kiosk users
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">eClock.Work in</h5>
                  <p className="mt-2">Input your PIN to begin your shift.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image10.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Limited users</h5>
                  <p className="mt-2">
                  Enroll kiosk users using their names exclusively, without needing an email.
                  </p>
                  <p className="mt-2 paragraph-color">
                    See pricing for kiosk users
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image03.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">eClock.Work in</h5>
                  <p className="mt-2">Enter your PIN and start your shift.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image11.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Limited users</h5>
                  <p className="mt-2">
                    Add kiosk users by name (no email required).
                  </p>
                  <p className="mt-2 paragraph-color">
                    See pricing for kiosk users
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">eClock.Work in</h5>
                  <p className="mt-2">Enter your PIN and start your shift.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image03.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Limited users</h5>
                  <p className="mt-2">
                    Add kiosk users by name (no email required).
                  </p>
                  <p className="mt-2 paragraph-color">
                    See pricing for kiosk users
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">eClock.Work in</h5>
                  <p className="mt-2">Enter your PIN and start your shift.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="two-columns-holder bg-color">
                  <div className="p-5">
                    <div className="service-dec-img animation-img-two">
                      <img
                        alt=""
                        src={require("../../assets/img/testing_image/image07.jpg")}
                        className="mt-3 pb-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
      {/* Two Columns Area End */}
    </>
  );
};

export default TwocolumnsImage;
