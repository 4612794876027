import React, { useState } from "react";
// Modal
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.min.css";
// Icon
import { AiOutlinePlayCircle } from "react-icons/ai";

// Import Banner img
import img from '../../assets/img/about/1.png'
import img1 from "../../../src/assets/img/about/3.png";


// Import React Dom
import {Link} from 'react-router-dom';

const TimekeeperBanner = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section id="banner-four">
        <div className="bg-shape"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="home-one-banner-content">
                <h1>Free Timekeeping Software</h1>
                <p>
                eClock.Work is the only FREE online timekeeping software for tracking and managing work hours. Easily monitor work hours and generate detailed reports to see comprehensive time breakdowns.
                </p>
                <div className="banner-one-btn pt30">
                  {/* <a href="/login" className="btn btn-theme">
                    Start tracking time — It's Free!
                  </a> */}
                  <Link to="https://eclockdashboard.vercel.app/login" target="blank" className="btn btn-theme">Start tracking time — It's Free!</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="banner-img ">
                <img src={img} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TimekeeperBanner;
