import React from "react";
//  Import Img
import img29 from "../../../assets/img/coming-soon.jpg";
import img30 from "../../../assets/img/coming-soon.jpg";
import img31 from "../../../assets/img/coming-soon.jpg";
import img32 from "../../../assets/img/coming-soon.jpg";
import img18 from "../../../assets/img/coming-soon.jpg";
import img28 from "../../../assets/img/coming-soon.jpg";
import img27 from "../../../assets/img/coming-soon.jpg";

import { Link } from "react-router-dom";

const TwoColumns = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img27} /> */}
                <h5 className="mt-2">Billable Time Selection</h5>
                <p>
                  Specify which time entries should be designated as billable.
                </p>
                <div className="btn-holder"></div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img28} /> */}
                <h5 className="mt-2">Client Billing Rates</h5>
                <p>
                  Utilize specified rates for billable time entries to determine
                  client invoices accurately.
                </p>
                <div className="btn-holder"></div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img29} /> */}
                <h5 className="mt-2">Expense Rates</h5>
                <p>
                  Apply cost rates to every time entry to determine team
                  expenditure accurately.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img30} /> */}
                <h5 className="mt-2">Retained Historical Rates</h5>
                <p>
                  Maintain previous rates and apply new rates exclusively to
                  future entries.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img31} /> */}
                <h5 className="mt-2">Currency Settings</h5>
                <p>
                  Configure your preferred currency and export reports
                  accordingly.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img32} /> */}
                <h5 className="mt-2">Profit Analysis</h5>
                <p>
                  Contrast earnings with labor costs to assess profitability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}
    </>
  );
};

export default TwoColumns;
