import React from 'react'
import {GoogleMap, LoadScript} from '@react-google-maps/api';

const ContactMap = () => {

    const mapStyles = {
        height: "50vh",
        width: "100%",
        margin: "0 0 0 0"
    };
    const defaultCenter = {
        lat: 32.949660, lng: -96.770670
    }
    return (
        <>
            <div className="google-map-code">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.049898479813!2d-96.77313772380234!3d32.94969197514061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c21c828c00001%3A0xa53ca3637251dc70!2s7920%20Belt%20Line%20Rd%20Suite%20720%2C%20Dallas%2C%20TX%2075254%2C%20USA!5e0!3m2!1sen!2sin!4v1717059844395!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                />
            </div>
        </>
    )
}

export default ContactMap
