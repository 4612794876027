import React from "react";
// import Banner from "../component/Home/Banner"; // Corrected import path
import Header from "../layout/Header"; // Corrected import path
import Footer from "../layout/Footer"; // Corrected import path
import CtaNew from "../layout/CtaNew"; // Corrected import path
import CopyRight from "../layout/CopyRight"; // Corrected import path
import ReportsHead from '../component/Reports/ReportsHead';
import ReportsMid from "../component/Reports/ReportsMid"
import ReportsLast from "../component/Reports/ReportsLast"




const ReportsPage = () => {
  return (
    <>
      <Header />
      <ReportsHead/>
      <ReportsMid/>
      <ReportsLast/>
      <CtaNew />
      <Footer />
      <CopyRight />
    </>
  );
};

export default ReportsPage;
