import React from "react";
import { Link } from "react-router-dom";
import { FaPeopleGroup } from "react-icons/fa6";
import img from "../../assets/img/testing_image/star.svg";

const RatingSoftware = () => {
  return (
    <>
      <section id="rating-area" className="py50 bg-color">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="news-letter-text pl40 pr40 responsive-mt-30">
                <div className="cta-text">
                  <h2 className="black-color heading-section-color fsSize">
                    Best rated timekeeping software
                  </h2>
                  <span className="d-inline-block my-2 mx-auto rating-color">
                    4.7 RATING&nbsp;&nbsp;•&nbsp;&nbsp;4,000+
                    REVIEWS&nbsp;&nbsp;•&nbsp;&nbsp;95% CUSTOMER SATISFACTION
                  </span>
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image06.jpg")}
                      className="mt-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RatingSoftware;
