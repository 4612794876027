import React from 'react'
import SectionHeading from '../../Home/SectionHeading/SectionHeading' 
//  Import Img
import img2 from '../../../assets/img/main/2.png'
import img3 from '../../../assets/img/testing_image/image03.png'
import img4 from '../../../assets/img/testing_image/image10.png'
import img5 from '../../../assets/img/testing_image/image01.png'
import img6 from '../../../assets/img/main/6.png'
import img7 from '../../../assets/img/testing_image/1.png'
import img16 from '../../../assets/img/coming-soon.jpg'


import { Link } from 'react-router-dom';

const TwoColumns=()=> {
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="about" className="py100">
       <div className="container">
            <SectionHeading title="Forgetting about timer?" heading={"eClock.Work enables precise time tracking.."}/>
            <div className="row mb-4">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Auto start & stop</h5>
                        <p>The timer starts automatically when you power on your computer and stops when you shut it down.</p>
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img3}  />
                   </div>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img4}  />
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Idle detection</h5>
                        <p>Receive alerts if you become inactive while your timer is running.</p>
                   </div>
               </div>
            </div>
            <div className="row mb-2">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Reminders</h5>
                        <p>Receive notifications when you're working without using a timer.</p>
                        
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img7}  />
                   </div>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <img alt="" src={img5}  />
                   </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="two-columns-holder">
                        <h5 className="mt-2">Auto tracker</h5>
                        <p>Automatically log the apps and websites you use, ensuring you can recall your work even if you forget to track time manually.</p>
                   </div>
               </div>
            </div>
       </div>
   </section>
      {/* Two Columns Area End */}

      {/* Offline mode */}
    <section id="about" className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <div className="logo-area-heading pb-3">
              <h3>Offline mode</h3>
              <h4>Monitor time on both your computer and phone, even without an internet connection. Data synchronization occurs automatically once you reconnect online.</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Two Columns Area End */}
    
    {/* Testimonial Area */}
    <section id="testimonial-area" className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6 text-center">
            <div className="">
              {/* <img src={img16} alt="" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="" className="product-section">
        <div className="container">
            <div className="feature-area-bg">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="section-title-center text-center pb30">
							<h3 className="pb10">More features</h3>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Hourly Rates</h3>
									<p>Set hourly rates for tasks and projects, and view earnings based on those rates.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Projects</h3>
									<p>Divide projects into individual tasks and categorize them by client for organization.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Reports</h3>
									<p>View a detailed breakdown of all tracked time based on custom dates and specific dimensions.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Export</h3>
									<p>Tailor and export report data to Excel, PDF, or CSV formats based on your preferences.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Invoicing</h3>
									<p>Generate invoices automatically using tracked billable hours.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Rounding</h3>
									<p>Adjust time rounding preferences to round up, down, or to the nearest X minutes as needed.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="row mb-4">
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Costs & Profit</h3>
									<p>Compare billing amounts to client with team compensation costs.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Estimates</h3>
									<p>Establish time estimates for projects and tasks, and monitor their progress accordingly.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Expenses</h3>
									<p>Record fixed-fee expenses for projects and team members, and generate invoices accordingly.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
        </div>
    </section>

    <section id="" className="product-section">
        <div className="container">
            <div className="feature-area-bg">
				<div className="row justify-content-center">
					<div className="col-lg-8 col-md-12 col-sm-12 col-12">
						<div className="section-title-center text-center pb30">
							<h3 className="pb10">Related</h3>
						</div>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-6">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Kiosk</h3>
									<p>Specify hourly rates for tasks and projects, and track earnings based on these rates..</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="single-feature">
							<div className="media">
								<div className="media-body pl30">
									<h3>Reporting</h3>
									<p>Segment projects into tasks and categorize them according to clients for organization and clarity..</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
        </div>
    </section>
  </>

 )
}

export default TwoColumns
