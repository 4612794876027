import React from 'react'
import HeaderHelp from '../layout/HeaderHelp'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import Index from '../component/Help/Forum/Index'

const Forum = () => {
    return (
        <>
            <HeaderHelp/>
            <Index />
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default Forum
