import React from "react";
//  Import Img


import { Link } from "react-router-dom";

const ViewScheduling = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Perspectives</h2>
                <p className="mb-4">
                  View all assignments chronologically from various angles.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Schedule Project </h5>
                  <p className="mt-2">
                    Organize projects and tasks within a designated timeline.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2"> Utilization Team</h5>
                  <p className="mt-2">
                    Visualize team capacity and workload over a timeline.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image07.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Task Allocation</h5>
                  <p className="mt-2">
                    Plan assignments, scheduling who works on tasks, their
                    durations, and timings.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image11.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Drag-and-Drop Editing</h5>
                  <p className="mt-2">
                    Modify content easily with intuitive drag-and-drop
                    functionality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Weekly Recurrence</h5>
                  <p className="mt-2">
                    Establish weekly recurring assignments.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image03.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Schedule Visibility</h5>
                  <p className="mt-2">
                    Enable team members to view their assigned schedules.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Project Milestones</h5>
                  <p className="mt-2">
                    Establish significant project events and goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image07.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Assignment Filtering</h5>
                  <p className="mt-2">Select whose tasks you want to view.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}
    </>
  );
};

export default ViewScheduling;
