import React from "react";
// import Banner from "../component/Home/Banner"; // Corrected import path
import HeaderHelp from "../layout/HeaderHelp"; // Corrected import path
import Footer from "../layout/Footer"; // Corrected import path
import CopyRight from "../layout/CopyRight"; // Corrected import path

import ProductHelpStatus from '../component/ProductContactHelpStatus/ProductHelpStatus'

const ProductHelpStatusPage = () => {
  return (
    <>
      <HeaderHelp />
      <ProductHelpStatus/>
      <Footer />
      <CopyRight />
    </>
  );
};

export default ProductHelpStatusPage;
