import React from "react";
import SectionHeading from "../Home/SectionHeading/SectionHeading";
//  Import Img
import img3 from "../../assets/img/coming-soon.jpg";
import img2  from "../../assets/img/coming-soon.jpg";


import { Link } from "react-router-dom";

const TwoColumns = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100 ">
        <div className="container border-bottom">
          <SectionHeading
            title="Works on any device"
            heading={"Launch kiosk on any internet-connected device."}
          />
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color">1. Create kiosk and assign users</h5>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color">2. Copy kiosk link</h5>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img2} /> */}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <div className="two-columns-holder">
                <h5 className="mt-2 hover-color">3. Open kiosk link on a device</h5>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder">
                {/* <img alt="" src={img3} /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TwoColumns;
