import React from 'react'
import CommonBanner from '../component/Common/Banner'
import SingleServiceDetails from '../component/ServiceDetails/SingleServiceDeatils'
import RelatedServices from '../component/ServiceDetails/RelatedServices'
import ServiceGraph from '../component/ServiceDetails/ServiceGraph'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CopyRight from '../layout/CopyRight'

const ServiceDetails = () => {
    return (
        <> 
            <Header/>
            <CommonBanner heading="Service Details" menu1="Home" menu2="Service Details"/>
            <SingleServiceDetails/>
            <RelatedServices/>
            <ServiceGraph/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ServiceDetails
