import React,{useState} from 'react'
//  Import Img
// import check from '../../../assets/img/about/tick.png'
import AboutImg from '../../assets/img/about/1.png'
import AboutImgTow from '../../assets/img/about/2.png'
import { Link } from 'react-router-dom';

import img from "../../assets/img/home-two-testimonial/veido-test.png";
import { AiOutlinePlayCircle } from 'react-icons/ai';
// Modal 
import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.min.css"
import img1 from "../../../src/assets/img/about/5.png";


const FirstVideo=()=> {
    const [isOpen, setOpen] = useState(false)
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="about" className="py100">
      <div className="container">
          <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="logo-area-heading text-center pb30">
                        <h3>Employee attendance tracking solutions.</h3>
                        <h4>With eClock.Work, effortlessly monitor work time and employee attendance. Easily access information on who was present, when they were present, their tasks, and the duration of their work.</h4>
                    </div>
                </div>
          </div>
          <div className="row">  
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                  <Link to="https://eclockdashboard.vercel.app/login" target="blank" className="btn btn-theme mt30">START TRACKING TIME - IT'S FREE</Link>
              </div>
          </div>
      </div>
    </section>
      {/* Two Columns Area End */}
      <section id="testimonial-area" className="py100 bg-color testimonial_padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="testmonial-veido text-center">
                <img src={img1} alt="img" />
                <div className="veido-play">
                  <ModalVideo channel='youtube' api={false} autoplay isOpen={isOpen} videoId="MLpWrANjFbI" onClose={() => setOpen(false)}/>
                  <a href="#!" onClick={() => setOpen(true)}><i><AiOutlinePlayCircle /></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  </>

 )
}

export default FirstVideo
