import React from 'react'
import BannerReporting from '../component/HomeProductReporting/BannerReporting'
import ReportingVideo from '../component/HomeProductReporting/ReportingVideo'
import ReportingStep from '../component/HomeProductReporting/ReportingStep'
import ProductReporting from '../component/HomeProductReporting/ProductReporting'
import Partner from '../component/Home/Partner/Partner'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import HappyCustomersReport from '../component/HomeProductReporting/HappyCustomersReport'





const HomeProductReportingPage = () => {
    return (
        <>
            <Header/>
            <BannerReporting/>
            {/* <Partner/>  */}
            <ReportingVideo/>
            <ProductReporting/>
            <ReportingStep/>
            <HappyCustomersReport tilt={"tilt"}/>
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default HomeProductReportingPage
