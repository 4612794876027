import React from 'react'
import { Link } from 'react-router-dom';
import { FaPeopleGroup } from "react-icons/fa6";
import img from "../../assets/img/testing_image/star.svg"

const RatingArea = () => {
    return (
        <>  
            <section id="rating-area" className="py50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                            <div className="news-letter-text pl40 pr40 responsive-mt-30">
                                <div>
                                    <h2 className="white-color"></h2>
                                    <Link to="/#" className="btn btn-theme mt30 btn-cta">Button 1</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                            <div className="news-letter-text pl40 pr40 responsive-mt-30">
                                <div>
                                    <h2 className="white-color"></h2>
                                    <Link to="/#" className="btn btn-theme mt30 btn-cta">Button 2</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                            <div className="news-letter-text pl40 pr40 responsive-mt-30">
                                <div>
                                    <h2 className="white-color"></h2>
                                    <Link to="/#" className="btn btn-theme mt30 btn-cta">Button 3</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RatingArea