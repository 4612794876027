import React from "react";
import SectionHeading from "../Home/SectionHeading/SectionHeading";

const Codeofconductcontent = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
        <SectionHeading title={<h2 className="heading-section-color mt-5">Code Of Conduct</h2>} />

          <div className="row mb-4">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">Code Of Conduct</h4>
              <p>
                Eclock a Computerlog, LLC Company (hereinafter called,
                Computerlog), seeks to provide a friendly, safe environment
                where all participants can engage in productive dialogue,
                sharing, and learning in an atmosphere of mutual respect. We are
                committed to providing a harassment-free environment for all,
                regardless of gender, gender identity, and expression, age,
                sexual orientation, disability, physical appearance, body size,
                race, ethnicity, religion (or lack thereof), or technology
                choices.
              </p>
              <p className="mt-2">
                We require all participants to adhere to the following code of
                conduct to promote such an environment. This code of conduct
                outlines our expectations for behavior within all Computerlog
                copy Business Directory interactions and events (whether online
                or in-person), what to do if you witness or are subject to
                unacceptable behavior, and how organizers will respond.
              </p>
              <p className="mt-2">
                This code applies to all Computerlog listings, job posting,
                business profiles, individual profile interactions, and events
                (whether online or in-person). This website is managed by
                Computerlog, LLC.
              </p>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">Expected Behavior</h4>
              <p>
                • Computerlog does not tolerate disparaging, intimidating,
                harassing, abusive, discriminatory, derogatory or demeaning
                conduct by any participants in associated communities in our
                platform and events.
              </p>
              <p>
                • Examples of unacceptable behavior include but are not limited
                to:
              </p>
              <ul className="custom-list margin-custom">
                <li className="pb-2">
                  offensive verbal comments related to gender, gender identity
                  and expression, age, sexual orientation, disability, physical
                  appearance, body size, race, ethnicity, religion (or lack
                  thereof), or technology choices
                </li>

                <li className="pb-2">
                  inappropriate use of nudity and/or sexual images, activities,
                  or other sexual material
                </li>
                <li className="pb-2">
                  sexualized clothing/uniforms/costumes, or otherwise creating a
                  sexualized environment
                </li>
                <li className="pb-2">
                  deliberate intimidation, stalking or following
                </li>
                <li className="pb-2">harassing photography or recording</li>
                <li className="pb-2">
                  sustained disruption of talks, meetings, or other events
                </li>
                <li className="pb-2">inappropriate physical contact</li>
                <li className="pb-2">unwelcome sexual attention</li>
                <li className="pb-2">
                  advocating for, or encouraging, any of the above behavior
                </li>
              </ul>
              <p>
                To clarify, critical examination of beliefs and viewpoints does
                not, by itself, constitute hostile conduct or harassment.
                Similarly, use of sexual imagery or language in the context of a
                professional discussion might not constitute hostile conduct or
                harassment.
              </p>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12">
              <h4 className="heading-section-color mt-5">
                What to do if you witness or are subject to unacceptable
                behavior
              </h4>
              <p>
                Please contact us at support@eclock.me to describe the
                occurrence and its participants.
              </p>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12">
              <h4 className="heading-section-color mt-5">
                Consequences of unacceptable behavior
              </h4>
              <p>
                Those found to be engaging in unacceptable behavior will be
                asked to stop immediately, and are expected to comply. Depending
                on the severity of the behavior, the need to protect
                participants’ safety, and prevent business interference, those
                who violate this policy may be expelled from all Computerlog
                communities. This includes but is not limited to the following
                online properties:
              </p>
              <ul className="custom-list margin-custom">
                <p>
                  Computerlog website and any associated services including but
                  not limited to:
                </p>
                <li className="pb-2">Computerlog facebook</li>
                <li className="pb-2">Computerlog Twitter</li>
                <li className="pb-2">Computerlog Instagram</li>
                <li className="pb-2">Computerlog Linkedin Account</li>
              </ul>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">Looking For Help?</h4>
              <p>
                Browse our Documentation, Join our Facebook group or contact
                Support to get your questions answered. We also maintain online
                support that can provide managed support, customization, and
                setup assistance. To report a bug, please post to our GitHub
                Issue Tracker.
              </p>
              <p className="mt-2">
                For information on when and why we collect personal data, please
                read our Privacy Policy. See our Terms & Conditions for
                licensing and refund details.
              </p>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">
                Subscribe & Discount
              </h4>
              <p>
                Stay up to date and never miss a promotion, freebie, or update!
                Get A 5% OFF DISCOUNT for any premium plan for your first
                subscription or services year.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}
    </>
  );
};

export default Codeofconductcontent;
