import React from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import TutorailStepCard2 from "../ProductTutorials/TutorailStepCard2"; // Importing once
import { TutorailStepData3 } from "./TutorailStepData3";

const TutorailStep1 = (props) => {
  return (
    <>
      {/* Shop Area Start */}
      <section id="shop-area" className="py100">
        <div className="container">
          <h2 className="text-center mb-4">In-depth tutorials</h2>
          <p className="text-center mb-4">
            Learn how everything in eClock.Work works.
          </p>
          <p className="text-center mb-4">
            <a rel="" href="#" className="span-color">
            WATCH ALL (playlist) →


            </a>
          </p>
          <div className="row">
            {TutorailStepData3.map((product, index) => (
              <TutorailStepCard2 product={product} key={index} />
            ))}
          </div>

          {/* 2nd row */}
          <h4 className="text-center mb-4">TIME TRACKING</h4>
          <div className="row">
            {TutorailStepData3.map((product, index) => (
              <TutorailStepCard2 product={product} key={index} />
            ))}
          </div>

          {/* 3rd row */}
          <h4 className="text-center mb-4">REPORTING</h4>
          <div className="row">
            {TutorailStepData3.map((product, index) => (
              <TutorailStepCard2 product={product} key={index} />
            ))}
          </div>

          {/* 4th row */}
          <h4 className="text-center mb-4">ADMINISTRATION</h4>
          <div className="row">
            {TutorailStepData3.map((product, index) => (
              <TutorailStepCard2 product={product} key={index} />
            ))}
          </div>
        </div>
      </section>
      {/* Shop Area End */}
    </>
  );
};

export default TutorailStep1;
