import React from "react";
import SectionHeading from "../Home/SectionHeading/SectionHeading";

const Codeofconductcontent = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
        <SectionHeading title={<h2 className="heading-section-color mt-5">Disclaimer</h2>} />
          <div className="row mb-4">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">
                Interpretation and Definitions
              </h4>
              <h4 className="heading-section-color mt-5">Interpretation</h4>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>

              <h4 className="heading-section-color mt-5">Definitions</h4>
              <p>For the purposes of this Disclaimer:</p>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
              <ul className="custom-list margin-custom">
                <li className="pb-2">
                  Company (referred to as either "the Company", "We", "Us" or
                  "Our" in this Disclaimer) refers to computerlog, LLC, 7920
                  Belt Line Road, Suite 245.
                </li>
                <li className="pb-2">Service refers to the Website.</li>
                <li className="pb-2">
                  You means the individual accessing the Service, or the
                  company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </li>
                <li className="pb-2">
                  Website refers to eclock accessible from
                  http://www.eclock..work
                </li>
              </ul>
            </div>

            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">Disclaimer</h4>
              <p>
                The information contained on the Service is for general
                information purposes only.
              </p>
              <p>
                The Company assumes no responsibility for errors or omissions in
                the contents of the Service.
              </p>

              <p>
                In no event shall the Company be liable for any special, direct,
                indirect, consequential, or incidental damages or any damages
                whatsoever, whether in an action of contract, negligence or
                other tort, arising out of or in connection with the use of the
                Service or the contents of the Service. The Company reserves the
                right to make additions, deletions, or modifications to the
                contents on the Service at any time without prior notice.
              </p>
              <p>
                The Company does not warrant that the Service is free of viruses
                or other harmful components.
              </p>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12">
              <h4 className="heading-section-color mt-5">
                Medical Information Disclaimer
              </h4>
              <p>
                The information about health provided by the Service is not
                intended to diagnose, treat, cure or prevent disease. Products,
                services, information and other content provided by the Service,
                including information linking to third-party websites are
                provided for informational purposes only.
              </p>
              <p>
                Information offered by the Service is not comprehensive and does
                not cover all diseases, ailments, physical conditions or their
                treatment.
              </p>
              <p>
                Individuals are different and may react differently to different
                products. Comments made on the Service by employees or other
                users are strictly their own personal views made in their own
                personal capacity and are not claims made by the Company nor do
                they represent the position or view of the Company.
              </p>
              <p>
                The Company is not liable for any information provided by the
                Service with regard to recommendations regarding supplements for
                any health purposes.
              </p>
              <p>
                The Company makes no guarantee or warranty with respect to any
                products or services sold. The Company is not responsible for
                any damages for information or services provided even if the
                Company has been advised of the possibility of damages.
              </p>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12">
              <h4 className="heading-section-color mt-5">
                Fitness & Nutritional Information Disclaimer
              </h4>
              <p>
                The Service can offer health, fitness and nutritional
                information including, without limitation, advice and
                recommendation, that is provided solely as general education and
                informational purposes.
              </p>
              <p>
                Use of the advice and information contained herein is at your
                sole choice and risk. You should not rely on this information as
                a substitute for, nor does it replace, professional medical
                advice, diagnosis, or treatment. Always consult your physician
                or healthcare provider before beginning any nutrition or
                exercise program.
              </p>
              <p>
                If You choose to use this information without prior consent of
                your physician, You are agreeing to accept full responsibility
                for your decisions and agreeing to hold harmless the Company.
              </p>
            </div>

            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">
                External Links Disclaimer
              </h4>
              <p>
                The Service may contain links to external websites that are not
                provided or maintained by or in any way affiliated with the
                Company.
              </p>
              <p>
                Please note that the Company does not guarantee the accuracy,
                relevance, timeliness, or completeness of any information on
                these external websites.
              </p>
            </div>

            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">
                Advertising Disclaimer
              </h4>
              <p>
                Third party advertisements and links to third party websites may
                also appear while using the Service. The Company does not make
                any representation as to the accuracy or suitability of any of
                the information contained in those advertisements or websites
                and does not accept any responsibility or liability for the
                conduct or content of those advertisements and websites and the
                offerings made by the third-parties.
              </p>
              <p>
                Third party advertisements and links to other websites where
                goods or services are advertised are not endorsements or
                recommendations by the Company of the third party sites, goods
                or services. The Company takes no responsibility for the content
                of the ads, promises made, or the quality/reliability of the
                products or services offered in all advertisements.
              </p>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">
                Product Reviews Disclaimer
              </h4>
              <p>
                In order to monetize and pay for the operation of the Service,
                the Company may receive compensation (either monetarily or
                through free products) for reviews, or endorsement of a
                particular company, product, or service.
              </p>
              <p>
                The Company remains independent and reviews are done based on
                the Company's own opinions regardless the compensation that can
                be received.
              </p>
              <p>
                In accordance with the Federal Trade Commission's policy of the
                United States, articles which contain a company, product or
                service review for which a compensation has been received, will
                include a disclaimer.
              </p>
            </div>

            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">
                Errors and Omissions Disclaimer
              </h4>
              <p>
                The information given by the Service is for general guidance on
                matters of interest only. Even if the Company takes every
                precaution to insure that the content of the Service is both
                current and accurate, errors can occur. Plus, given the changing
                nature of laws, rules and regulations, there may be delays,
                omissions or inaccuracies in the information contained on the
                Service.
              </p>
              <p>
                The Company is not responsible for any errors or omissions, or
                for the results obtained from the use of this information.
              </p>
            </div>

            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">
                Fair Use Disclaimer
              </h4>
              <p>
                The Company may use copyrighted material which has not always
                been specifically authorized by the copyright owner. The Company
                is making such material available for criticism, comment, news
                reporting, teaching, scholarship, or research.
              </p>
              <p>
                The Company believes this constitutes a "fair use" of any such
                copyrighted material as provided for in section 107 of the
                United States Copyright law.
              </p>
              <p>
                If You wish to use copyrighted material from the Service for
                your own purposes that go beyond fair use, You must obtain
                permission from the copyright owner.
              </p>
            </div>

            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">
                Views Expressed Disclaimer
              </h4>
              <p>
                The Service may contain views and opinions which are those of
                the authors and do not necessarily reflect the official policy
                or position of any other author, agency, organization, employer
                or company, including the Company.
              </p>
              <p>
                Comments published by users are their sole responsibility and
                the users will take full responsibility, liability and blame for
                any libel or litigation that results from something written in
                or as a direct result of something written in a comment. The
                Company is not liable for any comment published by users and
                reserves the right to delete any comment for any reason
                whatsoever.
              </p>
            </div>

            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">
                No Responsibility Disclaimer
              </h4>
              <p>
                The information on the Service is provided with the
                understanding that the Company is not herein engaged in
                rendering legal, accounting, tax, or other professional advice
                and services. As such, it should not be used as a substitute for
                consultation with professional accounting, tax, legal or other
                competent advisers.
              </p>
              <p>
                In no event shall the Company or its suppliers be liable for any
                special, incidental, indirect, or consequential damages
                whatsoever arising out of or in connection with your access or
                use or inability to access or use the Service.
              </p>
            </div>

            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">
                "Use at Your Own Risk" Disclaimer
              </h4>
              <p>
                All information in the Service is provided "as is", with no
                guarantee of completeness, accuracy, timeliness or of the
                results obtained from the use of this information, and without
                warranty of any kind, express or implied, including, but not
                limited to warranties of performance, merchantability and
                fitness for a particular purpose.
              </p>
              <p>
                The Company will not be liable to You or anyone else for any
                decision made or action taken in reliance on the information
                given by the Service or for any consequential, special or
                similar damages, even if advised of the possibility of such
                damages.
              </p>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4 className="heading-section-color mt-5">Contact Us</h4>
              <p>
                If you have any questions about this Disclaimer, You can contact
                Us:
              </p>
              <p>
                By email: <a href="#">suppor@eclock.work</a>
              </p>
              <p>
                By visiting this page on our website:{" "}
                <a href="https://www.eclock.work">www.eclock.work</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Two Columns Area End */}
    </>
  );
};

export default Codeofconductcontent;
