import React from "react";
import { Link } from "react-router-dom";

const AppsSeeFeatures = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Applications</h2>
                <p className="mb-4">
                  Monitor time across desktop and mobile applications.
                </p>
                <p>
                  <p class="paragraph-color-a">
                    <a href="">Chrome</a> • <a href="#">Firefox</a> •{" "}
                    <a href="">Windows</a> • <a href="#">Mac</a> •{" "}
                    <a href="#">Linux</a> • <a href="#">iOS</a> •{" "}
                    <a href="#">Android</a>
                  </p>
                </p>
                <p>
                  <p class="paragraph-color">
                    <a
                      href=""
                      className="btn btn-outline-primary paragraph-color m-1"
                    >
                      Auto trackr
                    </a>{" "}
                    <a
                      href="#"
                      className="btn btn-outline-primary paragraph-color m-1"
                    >
                      Firefox
                    </a>{" "}
                    <a
                      href=""
                      className="btn btn-outline-primary paragraph-color m-1"
                    >
                      Ideal detection
                    </a>{" "}
                    <a
                      href="#"
                      className="btn btn-outline-primary paragraph-color m-1"
                    >
                      Mac
                    </a>{" "}
                    <a
                      href="#"
                      className="btn btn-outline-primary paragraph-color m-1"
                    >
                      Reminder
                    </a>{" "}
                    <a
                      href="#"
                      className="btn btn-outline-primary paragraph-color m-1"
                    >
                      iOS
                    </a>{" "}
                    <a
                      href="#"
                      className="btn btn-outline-primary paragraph-color m-1"
                    >
                      Promo Timer
                    </a>
                  </p>
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4 pb-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image07.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppsSeeFeatures;
