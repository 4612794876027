import React,{useState} from 'react'
import { Link } from 'react-router-dom';

import img from "../../../assets/img/home-two-testimonial/veido-test.png";
import { AiOutlinePlayCircle } from 'react-icons/ai';
// Modal 
import ModalVideo from 'react-modal-video'
import "../../../../node_modules/react-modal-video/css/modal-video.min.css"

const FirstVideo=()=> {
    const [isOpen, setOpen] = useState(false)
 return (
  <>
  {/* Two Columns Area Start */}
    <section id="about" className="py100">
      <div className="container">
          <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="logo-area-heading text-center pb30">
                        <h2>Time Tracking Tutorials</h2>
                        <h5>See all you can do in eClock.Work.</h5>
                    </div>
                </div>
          </div>
      </div>
    </section>
      {/* Two Columns Area End */}
      <section id="testimonial-area" className="py100 bg-color testimonial_padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="testmonial-veido text-center">
                <img src={img} alt="img" />
                <div className="veido-play">
                  <ModalVideo channel='youtube' api={false} autoplay isOpen={isOpen} videoId="MLpWrANjFbI" onClose={() => setOpen(false)}/>
                  <a href="#!" onClick={() => setOpen(true)}><i><AiOutlinePlayCircle /></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  </>

 )
}

export default FirstVideo
