import React from 'react'
import Tilt from 'react-parallax-tilt';
import img from '../../../assets/img/about/1.png'
import feature1 from '../../../assets/img/svg/feature1.svg'
import feature2 from '../../../assets/img/svg/feature2.svg'
import feature3 from '../../../assets/img/svg/feature3.svg'
import { Link } from 'react-router-dom';
const CustomerSupport = () => {
    return (
        <>
         <section id="core-area-home4"  className="py100 bg-color">
    <div className="container">
        <div className="row justify-content-center pb30">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="section-center-two text-center pb30">
                    <h2>World-class customer support</h2>
                    <p>
                        We are available 24/7, including holidays.
                    </p>
                </div>
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="core-img">
                    <Tilt>
                    <img src={img} alt="img" />
                    </Tilt>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="core-area-wrapper">
                    <div className="core-area-item">
                        <div className="core-icons">
                            <img src={feature1} alt="icon" />
                        </div>
                        <div className="core-items-text">
                            <h3><a href="#!">24/7</a></h3>
                            <p>support anytime, anywhere</p>
                        </div>
                    </div>
                    <div className="core-area-item">
                        <div className="core-icons">
                            <img src={feature2} alt="icon" />
                        </div>
                        <div className="core-items-text">
                            <h3><a href="#!">1h</a></h3>
                            <p>average response time</p>
                        </div>
                    </div>
                    <div className="core-area-item">
                        <div className="core-icons">
                            <img src={feature3} alt="icon" />
                        </div>
                        <div className="core-items-text">
                            <h3><a href="#!">95%</a></h3>
                            <p>satisfaction score (16k+ ratings)</p>
                        </div>
                    </div>
                    <div className="core-area-item">
                        <div className="core-icons">
                            <img src={feature3} alt="icon" />
                        </div>
                        <div className="core-items-text">
                            <h3><a href="#!">99.99%</a></h3>
                            <p>highest uptime levels</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-center pb30">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                <Link to="/Contact" className="btn btn-theme mt30">Contact us</Link>
            </div>
        </div>
    </div>
 </section>
        </>
    )
}

export default CustomerSupport