import React from "react";
import { Link } from "react-router-dom";

const CalendarSeeFeatures = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py-5 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="logo-area-heading pb-3">
                <h2 className="pb-2 ">Schedule</h2>
                <p className="mb-4">
                  Visualize your tasks and allocate time slots.
                </p>
                <p>
                  <span>
                    <a href="#">MORE DETAILS</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color ">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    {/* <img
                      alt=""
                      src={require("../../assets/img/coming-soon.jpg")}
                      className="mt-3 pb-5"
                    /> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Visualize</h5>
                      <p className="mt-2 ">
                        Observe your daily schedule at a glance.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Add</h5>
                      <p className="mt-2 ">
                        Swiftly allocate hours with a single click.
                      </p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Modify</h5>
                      <p className="mt-2 ">Adjust blocks by resizing them.</p>
                    </div>
                    <div className="col-lg-3 py-3">
                      {" "}
                      <h5 className="mt-2">Sync</h5>
                      <p className="mt-2 ">
                        Integrate events from Outlook or Google Calendar.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CalendarSeeFeatures;
