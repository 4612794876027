import React from 'react';
// Import Components
import SectionHeading from '../Home/SectionHeading/SectionHeading'; 

const Resources = () => {
  return (
    <>
      {/* Service Area Start */}
      <section id="service" className="bg-color">
        <div className="container">
          <SectionHeading title="eClock.Work Resources" heading="Guides on how to get the most out of eClock.Work in various business scenarios and teams." />
        </div>
      </section>
      {/* Service Area End */}
    </>
  );
};

export default Resources;
