import React from "react";
import { Link } from "react-router-dom";
import { MdDrafts } from "react-icons/md";

const ProductFeedback = () => {
 
  return (
    <>
      <section id="" className="py100 bg-color">
        <div className="container">
          <div className="row">
            <div className="order_review  box-shadow bg-white col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="check-heading">
                <h2>Feedback</h2>
              </div>
              <div>
                <h5 className="pt-3">Feature Request Form - eClock.Work</h5>
                <p className="border-bottom pb-4">Help us improve eClock.Work!</p>
              </div>
              <div className="border-bottom ">
                <div className="">
                  <h5 className="pt-3 ">choose your account</h5>
                  <p className=" pb-4">Help us improve eClock.Work!</p>
                </div>
              </div>
              <div className="payment_method">
                <div className="payment_option">
                  <h5 className="pb-3">
                    Select which platform your request/feedback refers to: *
                  </h5>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      required=""
                      type="radio"
                      name="payment_option"
                      id="exampleRadios3"
                      value="option3"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios3"
                    >
                      Direct Bank Transfer
                    </label>
                    <p data-method="option3" className="payment-text">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios4"
                      value="option4"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios4"
                    >
                      Check Payment
                    </label>
                    <p data-method="option4" className="payment-text">
                      Please send your cheque to Store Name, Store Street, Store
                      Town, Store State / County, Store Postcode.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      Paypal
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      QuickBooks
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      Android app
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      Browser Extension
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      Android app
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      Browser Extension
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                  <div class="custom-radio">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label class="form-check-label" for="exampleRadios5">
                      other
                    </label>
                    <p data-method="option5" class="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                </div>
              </div>
              <div className="payment_method">
                <div className="payment_option">
                  <h5 className="pb-3">Category: *</h5>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      required=""
                      type="radio"
                      name="payment_option"
                      id="exampleRadios3"
                      value="option3"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios3"
                    >
                      Direct Bank Transfer
                    </label>
                    <p data-method="option3" className="payment-text">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios4"
                      value="option4"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios4"
                    >
                      Check Payment
                    </label>
                    <p data-method="option4" className="payment-text">
                      Please send your cheque to Store Name, Store Street, Store
                      Town, Store State / County, Store Postcode.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      Paypal
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      QuickBooks
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      Android app
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      Browser Extension
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      Android app
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                  <div className="custom-radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_option"
                      id="exampleRadios5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios5"
                    >
                      Browser Extension
                    </label>
                    <p data-method="option5" className="payment-text">
                      Pay via PayPal; you can pay with your credit card if you
                      don't have a PayPal account.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="order_review  box-shadow bg-white col-lg-12 col-md-12 col-sm-12 col-12">
                  {" "}
                  <div className="check-heading">
                    <h5>
                      Your feature request or feedback (how would it work and
                      why you need it) *
                    </h5>{" "}
                    <div class="form-group">
                      <label for="exampleInputUsername"></label>
                      <input
                        type="text"
                        class="form-control border-0"
                        id="exampleInputUsername"
                        placeholder="Your Answer"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="order_review  box-shadow bg-white col-lg-12 col-md-12 col-sm-12 col-12">
                  {" "}
                  <div className="check-heading">
                    <h5>
                      Your email (optional, in case we need to contact you)
                    </h5>{" "}
                    <div class="form-group">
                      <label for="exampleInputUsername"></label>
                      <input
                        type="text"
                        class="form-control border-0 "
                        id="exampleInputUsername"
                        placeholder="Your Answer"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-6 col-lg-6">
              <Link to="/#" className="btn btn-theme">
                Submit
              </Link>
            </div>
            <div className="col-6 col-lg-6 d-flex align-content-center justify-content-end">
              <a className="span-color" href="#">Clear form</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductFeedback;
