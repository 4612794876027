import React from 'react'
// Import Input
import FormInput from "../From/index";
// Import Icons
import {AiOutlineGooglePlus} from "react-icons/ai";
import {FaFacebookF} from "react-icons/fa";
import {AiOutlineTwitter} from "react-icons/ai";
import {Link} from 'react-router-dom';
import img1 from '../../assets/img/main/clock20.jpg'
import {BsSearch} from "react-icons/bs"


const LoginForm = () => {
    return (
        <>
            <section id="login-area" className="py20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <form action="#">
                                <div className="blog-search-option">
                                    <input type="text" placeholder="Search..."/>
                                    <button className="button" type="submit"><i><BsSearch/></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-4">
                            <div className="other-option">
                                <Link className="btn btn-theme" to="/Contact">
                                    Start
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="login-area" className="py100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="login-area">
                                <div className="login-form-arae">
                                    <div className="form-area-heading text-center">
                                        <h3>Lets's Start tracking!</h3>
                                        {/* <p>With your social network.</p> */}
                                    </div>
                                    
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="login-button">
                                            <img alt="" src={img1}  className="mt-3"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="login-desc text-center">
                                        <p>Install Clokify and track time anywhere</p>

                                            <p><Link to="/register"> Enable TimeSheet mode</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginForm
