import React from "react";
import { Link } from "react-router-dom";
import { FcGraduationCap } from "react-icons/fc";
import img1 from "../../assets/img/project-details/2.png";

const FreeTimeTracker = () => {
  return (
    <>
      <section id="about">
        <div className="container bg-color">
          <div className="row mb-4 ">
            <div className=" col-lg-6 col-md-12 col-sm-12 col-12  text-left p-4">
              <div class="container pt-4 pb-4 mb-1">
                <h2 class="text-left heading-section-color ">
                  Free time tracker{" "}
                </h2>
                <div class=" pb-1">
                  <p>
                    Time tracking software used by millions. eClock.Work is a time
                    tracker and timesheet app that lets you track work hours
                    across projects.
                  </p>
                  <p class="text-12-px mb-2">FREE FOREVER • UNLIMITED USERS</p>
                </div>
                <div className="pt-3">
                  <Link to="/#" className="btn btn-theme">
                    Learn More
                  </Link>
                </div>
                <div className="pt-4  fw-600">
                  <Link to="/#" className=" span-color">
                    Download
                  </Link>
                </div>
              </div>
            </div>
            <div className=" col-lg-6 col-md-12 col-sm-12 col-12  text-left p-4">
              <div class="container pt-4 pb-4 mb-1">
                <div className="service-dec-img animation-img-two">
                  <img
                    alt=""
                    src={require("../../assets/img/testing_image/image01.png")}
                    className="mt-3 pb-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FreeTimeTracker;
