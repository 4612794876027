import React from "react";
import { Link } from "react-router-dom";
import { FcGraduationCap } from "react-icons/fc";
import img1 from "../../assets/img/project-details/2.png";

const ProductReporting = () => {
  return (
    <>
      <section id="about" className="py100">
        <div className="container">
          <div className="row mb-4">
            <div className=" col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h2 className="heading-section-color">
              How does reporting work ?
              </h2>
              <p className="py-3">
              Reporting in eClock.Work enables you to optimize time allocation and productivity in several ways:
              </p>
              <ul className="custom-list  margin-custom">
                <li className="pb-2 ">
                  Maximize time spent on high-value clients and projects:
                  <p>By analyzing time reports, you can identify which clients or projects yield the most value. This allows you to allocate resources more efficiently, ensuring that your team spends more time on high-value tasks and less on low-priority ones.</p>
                </li>
                <li className="pb-2">
                  Minimize time spent on non-billable work:
                  With detailed reports, you can track how much time is spent on non-billable activities such as administrative tasks or internal meetings. This insight enables you to minimize non-billable hours, increasing overall profitability.
                </li>
                <li className="pb-2">Schedule who works on what and when:
                Through comprehensive reporting, you can identify patterns in workload distribution and resource allocation. This allows you to schedule tasks more effectively, ensuring that the right team members are assigned to the right projects at the right time.
                </li>
              </ul>
            </div>
            <div className="service-dec-img animation-img-two">
              <img
                alt=""
                src={require("../../assets/img/testing_image/image02.jpg")}
                className="mt-3"
              />
            </div>
          </div>
        </div>
     
      </section>
    </>
  );
};

export default ProductReporting;
