import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import CtaNew from "../layout/CtaNew";
import CopyRight from "../layout/CopyRight";
import Firstsect from "../component/Feature/AutoTracker/Firstsect";
import TwoColumns from "../component/Feature/AutoTracker/TwoColumns";
import MobileApp from "../component/Feature/AutoTracker/MobileApp";
import About from "../component/Feature/AutoTracker/About";
import AboutTwo from "../component/Feature/AutoTracker/AboutTwo";

const AutoTracker = () => {
  return (
    <>
      <Header />
      <Firstsect />
      <TwoColumns />
      <AboutTwo />
      <CtaNew />
      <Footer />
      <CopyRight />
    </>
  );
};

export default AutoTracker;
