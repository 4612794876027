import React from "react";
// Import Components
// import SectionHeading from '../SectionHeading/SectionHeading'
import BlogProcessCard from "../BlogProcess/BlogProcessCard";
import { BlogProcessData } from "../BlogProcess/BlogProcessData";

const BlogProcess = ({ className = "" }) => {
  return (
    <>
      {/* Work Process Area Start */}
      <section id="work-process" className={`py100 ${className}`}>
        <div className="container">
          <div>
            <span class="label label-purple h-100">COLLECTION</span>
          </div>
          {/* <SectionHeading title="Our Working Process" heading="Data Science Solutions For Startup"/> */}
          <div className="row">
            {BlogProcessData.map((data, index) => (
              <BlogProcessCard data={data} key={index} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogProcess;
