import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import CtaNew from '../layout/CtaNew'
import CopyRight from '../layout/CopyRight'
import Firstcomponent from '../component/Products/FirstComponent'
import VideoComponent from '../component/Products/VideoComponent'
import AboutOne from '../component/Products/Aboutone'

const ProductPayroll = () => {
    return (
        <>
            <Header/>
            <Firstcomponent />
            <VideoComponent />
            <AboutOne />
            <CtaNew/>
            <Footer/>
            <CopyRight/>
        </>
    )
}

export default ProductPayroll
