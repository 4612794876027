import React from "react";

import { Link } from "react-router-dom";

const TwoColumnImage = () => {
  return (
    <>
      {/* Two Columns Area Start */}
      <section id="about" className="py100">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image01.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Time Allocation Dashboard</h5>
                  <p className="mt-2">
                    Visualize time distribution by day and project for better
                    insights.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Time Tracking Calendar</h5>
                  <p className="mt-2">
                    Identify periods of untracked time within your team's
                    schedule.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image10.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Automated Activity Tracking</h5>
                  <p className="mt-2">
                    Monitor activities seamlessly across various apps and
                    websites.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image09.png")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Idle Time Management</h5>
                  <p className="mt-2">
                    Exclude periods of inactivity from recorded time to
                    accurately track productive hours.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="two-columns-holder bg-color">
                <div className="p-5">
                  <div className="service-dec-img animation-img-two">
                    <img
                      alt=""
                      src={require("../../assets/img/testing_image/image07.jpg")}
                      className="mt-3 pb-5"
                    />
                  </div>
                  <h5 className="mt-2">Reporting Options</h5>
                  <p className="mt-2">
                    View summarized breakdowns per person or detailed activity
                    logs for comprehensive insights.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Two Columns Area End */}
    </>
  );
};

export default TwoColumnImage;
